// This file was autogenerated. Please do not change.
export interface IInviteSubuserBody {
    email?: string;
}

export default class InviteSubuserBody {
    readonly _email: string | undefined;

    /** Description: Email of a person to be invoted */
    get email(): string | undefined {
        return this._email;
    }

    constructor(props: IInviteSubuserBody) {
        if (typeof props.email === 'string') {
            this._email = props.email.trim();
        }
    }

    serialize(): IInviteSubuserBody {
        const data: IInviteSubuserBody = {
        };
        if (typeof this._email !== 'undefined') {
            data.email = this._email;
        }
        return data;
    }

    toJSON(): IInviteSubuserBody {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            email: !this._email ? true : typeof this._email === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}

import ApiError from 'Entities/ApiError';
import ApiScopeRequest from 'Entities/publicPresenter/ApiScopeRequest';
import Asset from 'Entities/publicPresenter/Asset';
import AssetQuotation from 'Entities/publicPresenter/AssetQuotation';
import AssetQuotationRequest from 'Entities/publicPresenter/AssetQuotationRequest';
import AssetQuotationsRequest from 'Entities/publicPresenter/AssetQuotationsRequest';
import AssetRequest from 'Entities/publicPresenter/AssetRequest';
import AssetsRequest from 'Entities/publicPresenter/AssetsRequest';
import BankRequisites from 'Entities/publicPresenter/BankRequisites';
import BankRequisitesRequest from 'Entities/publicPresenter/BankRequisitesRequest';
import Blockchain from 'Entities/publicPresenter/Blockchain';
import BlockchainRequest from 'Entities/publicPresenter/BlockchainRequest';
import BlockchainsRequest from 'Entities/publicPresenter/BlockchainsRequest';
import DexData from 'Entities/publicPresenter/DexData';
import DexPoolInfo from 'Entities/publicPresenter/DexPoolInfo';
import DexPoolsRequest from 'Entities/publicPresenter/DexPoolsRequest';
import GetDexDataRequest from 'Entities/publicPresenter/GetDexDataRequest';
import MaintenanceStatus from 'Entities/publicPresenter/MaintenanceStatus';
import Placement from 'Entities/publicPresenter/Placement';
import PlacementRequest from 'Entities/publicPresenter/PlacementRequest';
import PlacementsRequest from 'Entities/publicPresenter/PlacementsRequest';
import QuoteAssetsRequest from 'Entities/publicPresenter/QuoteAssetsRequest';
import SpotAssetPair from 'Entities/publicPresenter/SpotAssetPair';
import SpotAssetPairRequest from 'Entities/publicPresenter/SpotAssetPairRequest';
import SpotAssetPairsRequest from 'Entities/publicPresenter/SpotAssetPairsRequest';
import StakingProduct from 'Entities/publicPresenter/StakingProduct';
import StakingProductsRequest from 'Entities/publicPresenter/StakingProductsRequest';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class PublicDataApi {
    static async publicGetApiScopes(apiscoperequest: ApiScopeRequest, withHeaders?: boolean): Promise<{ data: string[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...apiscoperequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('publicGetApiScopes', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/public/v1/meta/getApiScopes`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(apiscoperequest.serialize()),
        });
        if (res.status === 200) {
            return { data: await res.json(), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('PublicData/publicGetApiScopes', { response: res, data: result, fields: [JSON.stringify({ apiscoperequest })] });
    }

    static async publicGetAsset(assetrequest: AssetRequest, withHeaders?: boolean): Promise<{ data: Asset; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...assetrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('publicGetAsset', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/public/v1/meta/getAsset`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(assetrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new Asset(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('PublicData/publicGetAsset', { response: res, data: result, fields: [JSON.stringify({ assetrequest })] });
    }

    static async publicGetAssetQuotation(assetquotationrequest: AssetQuotationRequest, withHeaders?: boolean): Promise<{ data: AssetQuotation; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...assetquotationrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('publicGetAssetQuotation', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/public/v1/meta/getAssetQuotation`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(assetquotationrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new AssetQuotation(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('PublicData/publicGetAssetQuotation', { response: res, data: result, fields: [JSON.stringify({ assetquotationrequest })] });
    }

    static async publicGetAssetQuotations(assetquotationsrequest: AssetQuotationsRequest, withHeaders?: boolean): Promise<{ data: AssetQuotation[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...assetquotationsrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('publicGetAssetQuotations', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/public/v1/meta/getAssetQuotations`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(assetquotationsrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new AssetQuotation(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('PublicData/publicGetAssetQuotations', { response: res, data: result, fields: [JSON.stringify({ assetquotationsrequest })] });
    }

    static async publicGetAssets(assetsrequest: AssetsRequest, withHeaders?: boolean): Promise<{ data: Asset[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...assetsrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('publicGetAssets', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/public/v1/meta/getAssets`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(assetsrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new Asset(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('PublicData/publicGetAssets', { response: res, data: result, fields: [JSON.stringify({ assetsrequest })] });
    }

    static async publicGetBankRequisites(bankrequisitesrequest: BankRequisitesRequest, withHeaders?: boolean): Promise<{ data: BankRequisites; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...bankrequisitesrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('publicGetBankRequisites', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/public/v1/meta/getBankRequisites`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(bankrequisitesrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new BankRequisites(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('PublicData/publicGetBankRequisites', { response: res, data: result, fields: [JSON.stringify({ bankrequisitesrequest })] });
    }

    static async publicGetBlockchain(blockchainrequest: BlockchainRequest, withHeaders?: boolean): Promise<{ data: Blockchain; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...blockchainrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('publicGetBlockchain', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/public/v1/meta/getBlockchain`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(blockchainrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new Blockchain(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('PublicData/publicGetBlockchain', { response: res, data: result, fields: [JSON.stringify({ blockchainrequest })] });
    }

    static async publicGetBlockchains(blockchainsrequest: BlockchainsRequest, withHeaders?: boolean): Promise<{ data: Blockchain[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...blockchainsrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('publicGetBlockchains', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/public/v1/meta/getBlockchains`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(blockchainsrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new Blockchain(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('PublicData/publicGetBlockchains', { response: res, data: result, fields: [JSON.stringify({ blockchainsrequest })] });
    }

    static async publicGetDexExchange(getdexdatarequest: GetDexDataRequest, withHeaders?: boolean): Promise<{ data: DexData; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...getdexdatarequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('publicGetDexExchange', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/public/v1/meta/getDexExchange`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(getdexdatarequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new DexData(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('PublicData/publicGetDexExchange', { response: res, data: result, fields: [JSON.stringify({ getdexdatarequest })] });
    }

    static async publicGetDexExchanges(withHeaders?: boolean): Promise<{ data: DexData[]; headers?: Headers }> {
        const res = await fetch(`${BACKEND_BASE_URI}/public/v1/meta/getDexExchanges`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`},
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new DexData(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('PublicData/publicGetDexExchanges', { response: res, data: result, fields: [] });
    }

    static async publicGetDexPools(dexpoolsrequest: DexPoolsRequest, withHeaders?: boolean): Promise<{ data: DexPoolInfo[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...dexpoolsrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('publicGetDexPools', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/public/v1/meta/getDexPools`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(dexpoolsrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new DexPoolInfo(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('PublicData/publicGetDexPools', { response: res, data: result, fields: [JSON.stringify({ dexpoolsrequest })] });
    }

    static async publicGetMaintenanceStatuses(withHeaders?: boolean): Promise<{ data: MaintenanceStatus[]; headers?: Headers }> {
        const res = await fetch(`${BACKEND_BASE_URI}/public/v1/meta/getMaintenanceStatuses`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`},
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new MaintenanceStatus(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('PublicData/publicGetMaintenanceStatuses', { response: res, data: result, fields: [] });
    }

    static async publicGetPlacement(placementrequest: PlacementRequest, withHeaders?: boolean): Promise<{ data: Placement; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...placementrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('publicGetPlacement', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/public/v1/meta/getPlacement`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(placementrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new Placement(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('PublicData/publicGetPlacement', { response: res, data: result, fields: [JSON.stringify({ placementrequest })] });
    }

    static async publicGetPlacements(placementsrequest: PlacementsRequest, withHeaders?: boolean): Promise<{ data: Placement[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...placementsrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('publicGetPlacements', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/public/v1/meta/getPlacements`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(placementsrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new Placement(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('PublicData/publicGetPlacements', { response: res, data: result, fields: [JSON.stringify({ placementsrequest })] });
    }

    static async publicGetQuoteAssets(quoteassetsrequest: QuoteAssetsRequest, withHeaders?: boolean): Promise<{ data: string[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...quoteassetsrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('publicGetQuoteAssets', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/public/v1/meta/getQuoteAssets`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(quoteassetsrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: await res.json(), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('PublicData/publicGetQuoteAssets', { response: res, data: result, fields: [JSON.stringify({ quoteassetsrequest })] });
    }

    static async publicGetSpotAssetPair(spotassetpairrequest: SpotAssetPairRequest, withHeaders?: boolean): Promise<{ data: SpotAssetPair; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...spotassetpairrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('publicGetSpotAssetPair', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/public/v1/meta/getSpotAssetPair`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(spotassetpairrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new SpotAssetPair(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('PublicData/publicGetSpotAssetPair', { response: res, data: result, fields: [JSON.stringify({ spotassetpairrequest })] });
    }

    static async publicGetSpotAssetPairs(spotassetpairsrequest: SpotAssetPairsRequest, withHeaders?: boolean): Promise<{ data: SpotAssetPair[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...spotassetpairsrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('publicGetSpotAssetPairs', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/public/v1/meta/getSpotAssetPairs`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(spotassetpairsrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new SpotAssetPair(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('PublicData/publicGetSpotAssetPairs', { response: res, data: result, fields: [JSON.stringify({ spotassetpairsrequest })] });
    }

    static async publicGetStakingProducts(stakingproductsrequest: StakingProductsRequest, withHeaders?: boolean): Promise<{ data: StakingProduct[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...stakingproductsrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('publicGetStakingProducts', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/public/v1/meta/getStakingProducts`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(stakingproductsrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new StakingProduct(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('PublicData/publicGetStakingProducts', { response: res, data: result, fields: [JSON.stringify({ stakingproductsrequest })] });
    }
}

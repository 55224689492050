import { Action } from 'vuex';

import { actionCreator, mutationCreator } from 'Store/utils';
import WalletsApi from 'Apis/Wallets';
import UserTransferAddressesParams from 'Entities/privatePresenter/UserTransferAddressesParams';
import UserTransferAddress from 'Entities/privatePresenter/UserTransferAddress';
import WhitelistingManagementFrontendApi from 'Apis/WhitelistingManagementFrontend';
import DeleteAddressRequest from 'Entities/addressWhitelistingManagement/DeleteAddressRequest';
import { MFA_ENROLL_FACTOR_TYPES } from 'Config/auth';
import ApiError from 'Entities/ApiError';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import router from '@/router';
import UpdateAddressRequest from 'Entities/addressWhitelistingManagement/UpdateAddressRequest';
import { parsePaginationHeaders } from 'Lib/utils/PaginationParser';

const state = {
    addresses: [] as UserTransferAddress[],
    pendingAddresses: [] as UserTransferAddress[],
    lastRoute: undefined as undefined | string,
};

export type AddressesState = typeof state;

export enum AddressesMutations {
    SET_ADDRESSES = 'SET_ADDRESSES',
    SET_PENDING_ADDRESSES = 'SET_PENDING_ADDRESSES',
    SET_LAST_ROUTE = 'SET_LAST_ROUTE',
}

export const SET_ADDRESSES = mutationCreator<UserTransferAddress[]>('Addresses', AddressesMutations.SET_ADDRESSES);
export const SET_PENDING_ADDRESSES = mutationCreator<UserTransferAddress[]>('Addresses', AddressesMutations.SET_PENDING_ADDRESSES);
export const SET_LAST_ROUTE = mutationCreator<string>('Addresses', AddressesMutations.SET_LAST_ROUTE);

const mutations: Record<AddressesMutations, (state: AddressesState, ...args: any) => void> = {
    SET_ADDRESSES(state, { payload: addresses }) {
        state.addresses = addresses;
    },
    SET_PENDING_ADDRESSES(state, { payload: addresses }) {
        state.pendingAddresses = addresses;
    },
    SET_LAST_ROUTE(state, { payload: lastRoute }) {
        state.lastRoute = lastRoute;
    },
};

export enum AddressesActions {
    getAddresses = 'getAddresses',
    getPendingAddresses = 'getPendingAddresses',
    deleteAddress = 'deleteAddress',
    editAddress = 'editAddress',
}

export const getAddresses = actionCreator<undefined>('Addresses', AddressesActions.getAddresses);
export const getPendingAddresses = actionCreator<undefined>('Addresses', AddressesActions.getPendingAddresses);
export const deleteAddress = actionCreator<{ alias: string; address: string; blockchainName: string; }>('Addresses', AddressesActions.deleteAddress);
export const editAddress = actionCreator<{ alias: string; address: string; blockchainName: string; newAlias: string; }>('Addresses', AddressesActions.editAddress);

const actions: Record<AddressesActions, (Action<AddressesState, any>)> = {
    async getAddresses({ commit, rootState }) {
        const accountId = rootState.Accounts.activeAccountID;
        if (!accountId) {
            return;
        }
        const allAddresses: UserTransferAddress[] = [];
        const { data: addresses, headers } = await WalletsApi.privateGetUserTransferAddresses(new UserTransferAddressesParams({
            status: 'APPROVED',
            includeTotal: true,
            page: 1,
            perPage: 30,
            accountId,
        }), true);
        allAddresses.push(...addresses);

        if (headers) {
            const { totalPage } = parsePaginationHeaders(headers);
            if (totalPage && totalPage > 1) {
                for (let i = 2; i <= totalPage; i += 1) {
                    // eslint-disable-next-line no-await-in-loop
                    const { data: extraAddresses } = await WalletsApi.privateGetUserTransferAddresses(new UserTransferAddressesParams({
                        status: 'APPROVED',
                        page: i,
                        perPage: 30,
                        accountId,
                    }));
                    allAddresses.push(...extraAddresses);
                }
            }
        }

        commit(SET_ADDRESSES(allAddresses, true));
    },
    async getPendingAddresses({ commit, rootState }) {
        const accountId = rootState.Accounts.activeAccountID;
        if (!accountId) {
            return;
        }
        const allAddresses: UserTransferAddress[] = [];
        const { data: complianceApprovalAddresses, headers } = await WalletsApi.privateGetUserTransferAddresses(new UserTransferAddressesParams({
            status: 'PENDING_COMPLIANCE_APPROVAL',
            includeTotal: true,
            page: 1,
            perPage: 30,
            accountId,
        }), true);
        allAddresses.push(...complianceApprovalAddresses);

        if (headers) {
            const { totalPage } = parsePaginationHeaders(headers);
            if (totalPage && totalPage > 1) {
                for (let i = 2; i <= totalPage; i += 1) {
                    // eslint-disable-next-line no-await-in-loop
                    const { data: extraComplianceApprovalAddresses } = await WalletsApi.privateGetUserTransferAddresses(new UserTransferAddressesParams({
                        status: 'PENDING_COMPLIANCE_APPROVAL',
                        includeTotal: true,
                        page: i,
                        perPage: 30,
                        accountId,
                    }));
                    allAddresses.push(...extraComplianceApprovalAddresses);
                }
            }
        }

        const { data: userApprovalAddresses, headers: extraHeaders } = await WalletsApi.privateGetUserTransferAddresses(new UserTransferAddressesParams({
            status: 'PENDING_USER_APPROVAL',
            includeTotal: true,
            page: 1,
            perPage: 30,
            accountId,
        }), true);
        allAddresses.push(...userApprovalAddresses);

        if (extraHeaders) {
            const { totalPage } = parsePaginationHeaders(extraHeaders);
            if (totalPage && totalPage > 1) {
                for (let i = 2; i <= totalPage; i += 1) {
                    // eslint-disable-next-line no-await-in-loop
                    const { data: extraUserApprovalAddresses } = await WalletsApi.privateGetUserTransferAddresses(new UserTransferAddressesParams({
                        status: 'PENDING_USER_APPROVAL',
                        includeTotal: true,
                        page: i,
                        perPage: 30,
                        accountId,
                    }));
                    allAddresses.push(...extraUserApprovalAddresses);
                }
            }
        }

        commit(SET_PENDING_ADDRESSES(allAddresses, true));
    },
    async deleteAddress({ dispatch, commit }, { payload: { alias, address, blockchainName } }) {
        const totp = await dispatch('Auth/getMFAToken', { type: MFA_ENROLL_FACTOR_TYPES.TOTP }, { root: true });
        try {
            commit(SET_LOADING_ON(undefined), { root: true });
            await WhitelistingManagementFrontendApi.fWalletWhitelistDeleteAddress(new DeleteAddressRequest({
                totp,
                alias,
                address,
                blockchainName,
            }));
            await dispatch(getAddresses(undefined, true));
            await dispatch(getPendingAddresses(undefined, true));
            await dispatch('Notificator/showSuccessNotification', 'Address was successfully deleted', { root: true });
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error deleting address', { root: true });
            }
        } finally {
            await router.push('/profile/addresses-management').catch(() => { /* navigation error */ });
            commit(SET_LOADING_OFF(undefined), { root: true });
        }
    },
    async editAddress({ dispatch, commit }, { payload: { alias, address, blockchainName, newAlias } }) {
        try {
            commit(SET_LOADING_ON(undefined), { root: true });
            await WhitelistingManagementFrontendApi.fWalletWhitelistUpdateAddress(new UpdateAddressRequest({
                alias,
                address,
                blockchainName,
                newAlias,
            }));
            await dispatch(getAddresses(undefined, true));
            await dispatch(getPendingAddresses(undefined, true));
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error updating address', { root: true });
            }
        } finally {
            commit(SET_LOADING_OFF(undefined), { root: true });
        }
    },
};

export default {
    state,
    mutations,
    actions,
    namespaced: true,
};

import {
    gridPages,
    colorThemes,
} from 'Models/interface';
import SettingsApi from 'Apis/Settings';
import ApiError from 'Entities/ApiError';
import UserPlatformConfig from 'Entities/userSettings/UserPlatformConfig';

interface IConfig {
    theme: string;
    ownedAccountsOrder: any[],
    managedAccountsOrder: any[],
}

const state = {
    theme: colorThemes.LIGHT,
    config: {
        theme: colorThemes.LIGHT,
        ownedAccountsOrder: [],
        managedAccountsOrder: [],
    } as IConfig,

    defaultPresetsPagesGrid: {
        [gridPages.TERMINAL]: {
            lg: [
                {
                    x: 0, y: 1, w: 2, h: 6, i: 2, name: 'PriceChart',
                }, // price chart
                {
                    x: 2, y: 1, w: 1, h: 6, i: 3, name: 'PriceLadder',
                }, // price ladder
                {
                    x: 0, y: 7, w: 2, h: 3, i: 4, name: 'AccountPortfolio',
                }, // account portfolio
                {
                    x: 3, y: 1, w: 1, h: 6, i: 5, name: 'TradeHistory',
                }, // trade history
                {
                    x: 0, y: 10, w: 4, h: 6, i: 6, name: 'Orders',
                }, // orders
                {
                    x: 2, y: 7, w: 2, h: 3, i: 7, name: 'Trading',
                }, // trading
            ],
            md: [
                {
                    x: 0, y: 1, w: 2, h: 6, i: 2, name: 'PriceChart',
                }, // price chart
                {
                    x: 0, y: 7, w: 1, h: 6, i: 3, name: 'PriceLadder',
                }, // price ladder
                {
                    x: 0, y: 13, w: 2, h: 3, i: 4, name: 'AccountPortfolio',
                }, // account portfolio
                {
                    x: 1, y: 7, w: 1, h: 6, i: 5, name: 'TradeHistory',
                }, // trade history
                {
                    x: 0, y: 19, w: 2, h: 6, i: 6, name: 'Orders',
                }, // orders
                {
                    x: 0, y: 16, w: 2, h: 3, i: 7, name: 'Trading',
                }, // trading
            ],
        },
        [gridPages.PORTFOLIOS]: {
            lg: [
                {
                    x: 0, y: 0, w: 1, h: 3, i: 2, name: 'BalanceByCurrency',
                }, // balance by currency
                {
                    x: 1, y: 0, w: 1, h: 3, i: 3, name: 'BalanceByExchange',
                }, // balance by exchange
                {
                    x: 2, y: 0, w: 1, h: 3, i: 4, name: 'TradesPerAssetPair',
                }, // trades per currency
                {
                    x: 3, y: 0, w: 1, h: 3, i: 5, name: 'TradesPerExchange',
                }, // trades per exchange
                // other charts
                {
                    x: 0, y: 3, w: 2, h: 6, i: 6, name: 'BalanceByPeriod',
                }, // balance by period
                {
                    x: 2, y: 3, w: 2, h: 6, i: 7, name: 'PNLChart',
                }, // currencies value change
                {
                    x: 0, y: 9, w: 4, h: 6, i: 8, name: 'AggregatedBalance',
                }, // aggregated balance
            ],
            md: [
                {
                    x: 0, y: 0, w: 1, h: 3, i: 2, name: 'BalanceByCurrency',
                },
                {
                    x: 1, y: 0, w: 1, h: 3, i: 3, name: 'BalanceByExchange',
                },
                {
                    x: 0, y: 3, w: 1, h: 3, i: 4, name: 'TradesPerAssetPair',
                },
                {
                    x: 1, y: 3, w: 1, h: 3, i: 5, name: 'TradesPerExchange',
                },
                {
                    x: 0, y: 6, w: 2, h: 6, i: 6, name: 'BalanceByPeriod',
                },
                {
                    x: 0, y: 12, w: 2, h: 6, i: 7, name: 'PNLChart',
                },
                {
                    x: 0, y: 18, w: 2, h: 6, i: 8, name: 'AggregatedBalance',
                },
            ],
        },
        [gridPages.WALLETS]: {
            lg: [
                {
                    x: 0, y: 0, w: 2, h: 3, i: 2, name: 'Transfer',
                },
                {
                    x: 2, y: 0, w: 2, h: 6, i: 3, name: 'TransferHistory',
                },
                {
                    x: 0, y: 5, w: 2, h: 3, i: 4, name: 'Payment',
                },
                {
                    x: 0, y: 8, w: 4, h: 6, i: 5, name: 'AggregatedBalance',
                },
            ],
        },
        [gridPages.WORKSPACE]: {
            lg: [],
        },
        [gridPages.OTC]: {
            lg: [
                {
                    x: 0, y: 10, w: 4, h: 6, i: 6, name: 'OrdersHistory',
                }, // orders
            ],
            md: [
                {
                    x: 0, y: 19, w: 2, h: 6, i: 6, name: 'OrdersHistory',
                }, // orders
            ],
        },
    },

    pageGrids: {
        [gridPages.TERMINAL]: {
            lg: [
                {
                    x: 0, y: 1, w: 2, h: 6, i: 2, name: 'PriceChart',
                }, // price chart
                {
                    x: 2, y: 1, w: 1, h: 6, i: 3, name: 'PriceLadder',
                }, // price ladder
                {
                    x: 0, y: 7, w: 2, h: 3, i: 4, name: 'AccountPortfolio',
                }, // account portfolio
                {
                    x: 3, y: 1, w: 1, h: 6, i: 5, name: 'TradeHistory',
                }, // trade history
                {
                    x: 0, y: 10, w: 4, h: 6, i: 6, name: 'Orders',
                }, // orders
                {
                    x: 2, y: 7, w: 2, h: 3, i: 7, name: 'Trading',
                }, // trading
            ],
            md: [
                {
                    x: 0, y: 1, w: 2, h: 6, i: 2, name: 'PriceChart',
                }, // price chart
                {
                    x: 0, y: 7, w: 1, h: 6, i: 3, name: 'PriceLadder',
                }, // price ladder
                {
                    x: 0, y: 13, w: 2, h: 3, i: 4, name: 'AccountPortfolio',
                }, // account portfolio
                {
                    x: 1, y: 7, w: 1, h: 6, i: 5, name: 'TradeHistory',
                }, // trade history
                {
                    x: 0, y: 19, w: 2, h: 6, i: 6, name: 'Orders',
                }, // orders
                {
                    x: 0, y: 16, w: 2, h: 3, i: 7, name: 'Trading',
                }, // trading
            ],
        },
        [gridPages.PORTFOLIOS]: {
            lg: [
                {
                    x: 0, y: 0, w: 1, h: 3, i: 2, name: 'BalanceByCurrency',
                }, // balance by currency
                {
                    x: 1, y: 0, w: 1, h: 3, i: 3, name: 'BalanceByExchange',
                }, // balance by exchange
                {
                    x: 2, y: 0, w: 1, h: 3, i: 4, name: 'TradesPerAssetPair',
                }, // trades per currency
                {
                    x: 3, y: 0, w: 1, h: 3, i: 5, name: 'TradesPerExchange',
                }, // trades per exchange
                // other charts
                {
                    x: 0, y: 3, w: 2, h: 6, i: 6, name: 'BalanceByPeriod',
                }, // balance by period
                {
                    x: 2, y: 3, w: 2, h: 6, i: 7, name: 'PNLChart',
                }, // currencies value change
                {
                    x: 0, y: 9, w: 4, h: 6, i: 8, name: 'AggregatedBalance',
                }, // aggregated balance
            ],
            md: [
                {
                    x: 0, y: 0, w: 1, h: 3, i: 2, name: 'BalanceByCurrency',
                },
                {
                    x: 1, y: 0, w: 1, h: 3, i: 3, name: 'BalanceByExchange',
                },
                {
                    x: 0, y: 3, w: 1, h: 3, i: 4, name: 'TradesPerAssetPair',
                },
                {
                    x: 1, y: 3, w: 1, h: 3, i: 5, name: 'TradesPerExchange',
                },
                {
                    x: 0, y: 6, w: 2, h: 6, i: 6, name: 'BalanceByPeriod',
                },
                {
                    x: 0, y: 12, w: 2, h: 6, i: 7, name: 'PNLChart',
                },
                {
                    x: 0, y: 18, w: 2, h: 6, i: 8, name: 'AggregatedBalance',
                },
            ],
        },
        [gridPages.WALLETS]: {
            lg: [
                {
                    x: 0, y: 0, w: 2, h: 3, i: 2, name: 'Transfer',
                },
                {
                    x: 2, y: 0, w: 2, h: 6, i: 3, name: 'TransferHistory',
                },
                {
                    x: 0, y: 5, w: 2, h: 3, i: 4, name: 'Payment',
                },
                {
                    x: 0, y: 8, w: 4, h: 6, i: 5, name: 'AggregatedBalance',
                },
            ],
        },
        [gridPages.PROFILE]: {
            lg: [
                {
                    x: 0, y: 0, w: 3, h: 0.58, i: 1, name: 'Settings',
                },
                {
                    x: 0, y: 0.58, w: 3, h: 1, i: 2, name: 'ProfileRow',
                },
                {
                    x: 0, y: 2.58, w: 1, h: 4.5, i: 3, name: 'AuthInfo',
                },
                {
                    x: 1, y: 2.58, w: 1, h: 4.5, i: 4, name: 'Packages',
                },
                {
                    x: 2, y: 2.58, w: 1, h: 4.5, i: 5, name: 'Rebates',
                },
                {
                    x: 0, y: 10.58, w: 3, h: 6, i: 6, name: 'History',
                },
            ],
        },
        [gridPages.DEFI]: {
            lg: [
                {
                    x: 0, y: 0, w: 1, h: 6, i: 2, name: 'DeFiInputForm',
                },
                {
                    x: 1, y: 0, w: 3, h: 6, i: 3, name: 'DeFiTable',
                },
                {
                    x: 0, y: 2, w: 4, h: 6, i: 4, name: 'LiquidityPoolsTable',
                },
                {
                    x: 0, y: 1, w: 2, h: 3, i: 5, name: 'DeFiQuickBalances',
                },
                {
                    x: 2, y: 1, w: 2, h: 3, i: 6, name: 'DeFiPoolsPositions',
                },
            ],
            md: [
                {
                    x: 0, y: 0, w: 1, h: 7, i: 2, name: 'DeFiInputForm',
                },
                {
                    x: 1, y: 0, w: 3, h: 7, i: 3, name: 'DeFiTable',
                },
                {
                    x: 0, y: 1, w: 4, h: 7, i: 4, name: 'LiquidityPoolsTable',
                },
                {
                    x: 0, y: 2, w: 2, h: 3, i: 5, name: 'DeFiQuickBalances',
                },
                {
                    x: 2, y: 2, w: 2, h: 3, i: 6, name: 'DeFiPoolsPositions',
                },
            ],
        },
        [gridPages.STATEMENTS]: {
            lg: [
                {
                    x: 0, y: 0, w: 1, h: 2, i: 2, name: 'Balance',
                },
                {
                    x: 1, y: 0, w: 2, h: 2, i: 4, name: 'TradingPerformance',
                },
                {
                    x: 3, y: 0, w: 1, h: 2, i: 5, name: 'PaidFees',
                },
                {
                    x: 0, y: 2, w: 4, h: 6, i: 6, name: 'StatementsTable',
                },
            ],
            md: [
                {
                    x: 0, y: 0, w: 1, h: 1.6, i: 2, name: 'AssetUnderManagement',
                },
                {
                    x: 1, y: 0, w: 1, h: 1.6, i: 3, name: 'AccountFunds',
                },
                {
                    x: 2, y: 0, w: 1, h: 1.6, i: 4, name: 'TradingPerformance',
                },
                {
                    x: 3, y: 0, w: 1, h: 1.6, i: 5, name: 'PaidFees',
                },
                {
                    x: 0, y: 1, w: 4, h: 7, i: 6, name: 'StatementsTable',
                },
            ],
        },
        [gridPages.WORKSPACE]: {
            lg: [],
        },
        [gridPages.EARN]: {
            lg: [
                {
                    x: 0, y: 0, w: 4, h: 2, i: 1, name: 'EarnHeader',
                },
                {
                    x: 0, y: 2, w: 4, h: 6, i: 2, name: 'EarnPositions',
                },
                {
                    x: 0, y: 6, w: 4, h: 6, i: 3, name: 'EarnProducts',
                },
            ],
            md: [
                {
                    x: 0, y: 0, w: 4, h: 2, i: 1, name: 'EarnHeader',
                },
                {
                    x: 0, y: 2, w: 4, h: 6, i: 2, name: 'EarnPositions',
                },
                {
                    x: 0, y: 4, w: 4, h: 6, i: 3, name: 'EarnProducts',
                },
            ],
        },
        [gridPages.OTC]: {
            lg: [
                {
                    x: 0, y: 0, w: 4, h: 3, i: 1, name: 'AccountPortfolio',
                },
                {
                    x: 0, y: 3, w: 4, h: 1, i: 2, name: 'Assets',
                },
                {
                    x: 0, y: 4, w: 4, h: 2.46, i: 3, name: 'OrderCards',
                },
                {
                    x: 0, y: 10, w: 4, h: 6, i: 6, name: 'OrdersHistory',
                }, // orders
            ],
            md: [
                {
                    x: 0, y: 0, w: 4, h: 3, i: 1, name: 'AccountPortfolio',
                },
                {
                    x: 0, y: 0, w: 4, h: 1, i: 2, name: 'Assets',
                },
                {
                    x: 0, y: 0, w: 4, h: 2, i: 3, name: 'OrderCards',
                },
                {
                    x: 0, y: 19, w: 2, h: 6, i: 6, name: 'OrdersHistory',
                }, // orders
            ],
        },
    },

    activeLayoutBreakpoints: {
        [gridPages.TERMINAL]: 'lg',
        [gridPages.PORTFOLIOS]: 'lg',
        [gridPages.DEFI]: 'lg',
    },

    vueGridLayoutBreakpoints: {
        lg: 1190, md: 768, sm: 480, xs: 0, xxs: 0,
    },
    vueGridLayoutCols: {
        lg: 4, md: 2, sm: 1, xs: 1, xxs: 1,
    },
};

const getters = {
    theme: (state) => state.theme,
    isThemeDark: (state, getters) => getters.theme === colorThemes.DARK,

    pageGrids: (state) => state.pageGrids,
    defaultPageGrids: (state) => state.defaultPresetsPagesGrid,
    pageGrid: (state, getters) => (page, breakpoint) => (getters.pageGrids[page][breakpoint] ? getters.pageGrids[page][breakpoint] : getters.pageGrids[page].lg),
    defaultPageGrid: (state, getters) => (page, breakpoint) => (getters.defaultPageGrids[page][breakpoint] ? getters.defaultPageGrids[page][breakpoint] : getters.defaultPageGrids[page].lg),

    vueGridLayoutBreakpoints: (state) => state.vueGridLayoutBreakpoints,
    vueGridLayoutCols: (state) => state.vueGridLayoutCols,

    isTerminalTradingViewFullscreen: (state, getters) => getters.pageGrid(gridPages.TERMINAL)[1].w === 4 && getters.pageGrids(gridPages.TERMINAL)[1].h === 8,
};

const mutations = {
    SET_THEME(state, theme) {
        state.theme = theme;

        document.getElementsByTagName('html')[0].dataset.theme = theme;
        document.body.className = theme;
    },
    CHANGE_PAGE_GRID(state, { page, grid, breakpoint }) {
        state.pageGrids[page][breakpoint] = grid;
    },
    CHANGE_DEFAULT_PAGE_GRID(state, { page, grid, breakpoint }) {
        state.defaultPresetsPagesGrid[page][breakpoint] = grid;
    },
    SET_TRADING_VIEW_FULLSCREEN(state) {
        state.pageGrids[gridPages.TERMINAL][1].w = 4;
        state.pageGrids[gridPages.TERMINAL][1].h = 8;
    },
    REMOVE_TRADING_VIEW_FULLSCREEN(state) {
        state.pageGrids[gridPages.TERMINAL][1].w = 2;
        state.pageGrids[gridPages.TERMINAL][1].h = 6;
    },
    SET_CONFIG(state, config) {
        state.config = config;
    },
    SET_PAGE_ACTIVE_BREAKPOINT(state, { page, breakpoint }) {
        state.activeLayoutBreakpoints[page] = breakpoint;
    },
};

const actions = {
    async initInterface({ dispatch }) {
        await dispatch('loadInterfaceData');
    },
    async loadInterfaceData({ commit, state }) {
        try {
            const { data: { config } } = await SettingsApi.getPlatformConfig();
            if (!config && Object.keys(config as any).length === 0) {
                commit('SET_CONFIG', {
                    theme: state.theme,
                    ownedAccountsOrder: [],
                    managedAccountsOrder: [],
                });
                await SettingsApi.savePlatformConfig(new UserPlatformConfig({
                    config: state.config,
                }));
                return;
            }
            commit('SET_CONFIG', config);
            if ((config as any).theme && (config as any).theme === colorThemes.LIGHT) {
                commit('SET_THEME', colorThemes.LIGHT);
            } else if ((config as any).theme && (config as any).theme === colorThemes.DARK) {
                commit('SET_THEME', colorThemes.DARK);
            }
        } catch {
            // api error
        }
    },
    async toggleTheme({ getters, commit, dispatch, state, rootState }) {
        try {
            commit('SET_THEME', getters.isThemeDark ? colorThemes.LIGHT : colorThemes.DARK);
            commit('SET_CONFIG', {
                theme: state.theme,
                ownedAccountsOrder: state.config.ownedAccountsOrder,
                managedAccountsOrder: state.config.managedAccountsOrder,
            });
            if (!rootState.User.currentUser) {
                return;
            }
            await SettingsApi.savePlatformConfig(new UserPlatformConfig({
                config: state.config,
            }));
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during saving theme config', { root: true });
            }
        }
    },
    async setLightTheme({ commit, dispatch, state, rootState }) {
        try {
            commit('SET_THEME', colorThemes.LIGHT);
            commit('SET_CONFIG', {
                theme: state.theme,
                ownedAccountsOrder: state.config.ownedAccountsOrder,
                managedAccountsOrder: state.config.managedAccountsOrder,
            });
            if (!rootState.User.currentUser) {
                return;
            }
            await SettingsApi.savePlatformConfig(new UserPlatformConfig({
                config: state.config,
            }));
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during saving theme config', { root: true });
            }
        }
    },
    async setDarkTheme({ commit, dispatch, state, rootState }) {
        try {
            commit('SET_THEME', colorThemes.DARK);
            commit('SET_CONFIG', {
                theme: state.theme,
                ownedAccountsOrder: state.config.ownedAccountsOrder,
                managedAccountsOrder: state.config.managedAccountsOrder,
            });
            if (!rootState.User.currentUser) {
                return;
            }
            await SettingsApi.savePlatformConfig(new UserPlatformConfig({
                config: state.config,
            }));
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during saving theme config', { root: true });
            }
        }
    },
    async setOwnedAccountsOrder({ commit, state, dispatch }, accountsOrder) {
        try {
            commit('SET_CONFIG', {
                theme: state.theme,
                ownedAccountsOrder: accountsOrder,
                managedAccountsOrder: state.config.managedAccountsOrder,
            });
            await SettingsApi.savePlatformConfig(new UserPlatformConfig({
                config: state.config,
            }));
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during saving theme config', { root: true });
            }
        }
    },
    async setManagedAccountsOrder({ commit, state, dispatch }, accountsOrder) {
        try {
            commit('SET_CONFIG', {
                theme: state.theme,
                ownedAccountsOrder: state.config.ownedAccountsOrder,
                managedAccountsOrder: accountsOrder,
            });
            await SettingsApi.savePlatformConfig(new UserPlatformConfig({
                config: state.config,
            }));
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during saving theme config', { root: true });
            }
        }
    },
    changePageGrid({ commit }, { page, grid, breakpoint }) {
        commit('CHANGE_PAGE_GRID', { page, grid, breakpoint });
    },
    changeDefaultPageGrid({ commit }, { page, grid, breakpoint }) {
        commit('CHANGE_DEFAULT_PAGE_GRID', { page, grid, breakpoint });
    },
    toggleChartFullscreen({ commit, getters }) {
        if (getters.isTerminalTradingViewFullscreen) {
            commit('REMOVE_TRADING_VIEW_FULLSCREEN');
        } else {
            commit('SET_TRADING_VIEW_FULLSCREEN');
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};

import Vue from 'vue';
import Vuex from 'vuex';

import OrderBookLadder, { OrderBookLadderGetters, OrderBookLadderState, OrderBookLadderMutations, OrderBookLadderActions } from 'Store/v2/OrderBookLadder';
import TradingData, { TradingDataGetters, TradingDataState, TradingDataMutations, TradingDataActions } from 'Store/v2/TradingData';
import Candles, { CandlesGetters, CandlesState, CandlesMutations, CandlesActions } from 'Store/v2/Candles';
import Withdraw, { WithdrawGetters, WithdrawState, WithdrawMutations, WithdrawActions } from 'Store/v2/Withdraw';
import Deposit, { DepositGetters, DepositState, DepositMutations, DepositActions } from 'Store/v2/Deposit';
import KYC, { KYCGetters, KYCState, KYCMutations, KYCActions } from 'Store/v2/KYC';
import Presets, { PresetsState, PresetsMutations, PresetsActions } from 'Store/v2/Presets';
import Notifications, { NotificationsState, NotificationsMutations, NotificationsActions, NotificationsGetters } from 'Store/v2/Notifications';
import Preloader, { PreloaderState, PreloaderMutations, PreloaderGetters } from 'Store/v2/Preloader';
import DefiLiquidityPools, { DefiLiquidityPoolsState, DefiLiquidityPoolsMutations, DefiLiquidityPoolsActions } from 'Store/v2/DefiLiquidityPools';
import Modal, { ModalState, ModalMutations } from 'Store/v2/Modal';
import WorkspaceChooseTool, { WorkspaceChooseToolState, WorkspaceChooseToolActions, WorkspaceChooseToolMutations } from 'Store/v2/WorkspaceChooseTool';
import Futures, { FuturesState, FuturesMutations, FuturesActions } from 'Store/v2/Futures';
import Balances, { BalancesState, BalancesMutations, BalancesActions, BalancesGetters } from 'Store/v2/Balances';
import AssetPairs, { AssetPairsState, AssetPairsMutations, AssetPairsActions, AssetPairsGetters } from 'Store/v2/AssetPairs';
import Assets, { AssetsState, AssetsMutations, AssetsActions, AssetsGetters } from 'Store/v2/Assets';
import Earn, { EarnState, EarnMutations, EarnActions } from 'Store/v2/Earn';
import UiActions, { UiActionsActions } from 'Store/v2/UiActions';
import Groups, { GroupsState, GroupsMutations, GroupsActions } from 'Store/v2/Groups';
import Addresses, { AddressesState, AddressesMutations, AddressesActions } from 'Store/v2/Addresses';
import TakeProfitStopLossModal, { TakeProfitStopLossModalActions, TakeProfitStopLossModalMutations, TakeProfitStopLossModalState } from 'Store/v2/TakeProfitStopLossModal';
import Rebates, { RebatesState, RebatesMutations, RebatesActions } from 'Store/v2/Rebates';
import Intro, { IntroActions, IntroState } from 'Store/v2/Intro';
import LinkPlacement, { LinkPlacementState, LinkPlacementMutations } from 'Store/v2/LinkPlacement';
import PlacementApiKeysManagement, { PlacementApiKeysManagementState, PlacementApiKeysManagementMutations, PlacementApiKeysManagementActions } from 'Store/v2/PlacementApiKeysManagement';
import Margin, { MarginState, MarginMutations, MarginActions } from 'Store/v2/Margin';

import actions from './actions';
import getters from './getters';
import Accounts from './modules/Accounts';
import Auth from './modules/Auth';
import Interface from './modules/interface';
import localization from './modules/localization';
import Notificator from './modules/Notificator';
import OrderBook from './modules/OrderBook';
import Orders from './modules/Orders';
import Placements from './modules/Placements';
import Portfolio from './modules/Portfolio';
import TradeHistory from './modules/TradeHistory';
import Transactions from './modules/Transactions';
import VuexEventListener from './modules/VuexEventListener';
import ApiManagment, { ApiManagmentGetters, ApiManagmentState, ApiManagmentMutations, ApiManagmentActions } from './v2/ApiManagment';
import User, { UserGetters, UserState, UserMutations, UserActions } from './v2/Users';
import Transfer, { TransferGetters, TransferState, TransferMutations, TransferActions } from './v2/Transfer';
import TransferHistory, { TransferHistoryGetters, TransferHistoryState, TransferHistoryMutations, TransferHistoryActions } from './v2/TransferHistory';
import PrivateSocketData, { PrivateSocketDataGetters, PrivateSocketDataState, PrivateSocketDataMutations, PrivateSocketDataActions } from './v2/PrivateSocketData';
import PublicSocketData, { PublicSocketDataGetters, PublicSocketDataState, PublicSocketDataMutations, PublicSocketDataActions } from './v2/PublicSocketData';
import Defi, { DefiState, DefiMutations, DefiActions } from './v2/Defi';
import Statements, { StatementsState, StatementsMutations, StatementsActions } from './v2/Statements';
import MultiaccountOrder, { MultiaccountOrderState, MultiaccountOrderMutations } from './v2/MultiaccountOrder';
import MultiTransfers, { MultiTransfersMutations, MultiTransfersState } from './v2/MultiTransfers';
import OTC, { OTCState, OTCMutations, OTCActions } from './v2/OTC';
import CorporateTeamMembers, { CorporateTeamMembersState, CorporateTeamMembersActions, CorporateTeamMembersMutations } from './v2/CorporateTeamMembers';

export type ModuleNames =
    'Accounts' |
    'AssetPairs' |
    'Assets' |
    'Auth' |
    'Balances' |
    'Interface' |
    'localization' |
    'Notificator' |
    'OrderBook' |
    'Orders' |
    'Placements' |
    'Portfolio' |
    'TradeHistory' |
    'Transactions' |
    'VuexEventListener' |
    'ApiManagment' |
    'User' |
    'Transfer' |
    'TransferHistory' |
    'PrivateSocketData' |
    'PublicSocketData' |
    'OrderBookLadder' |
    'TradingData' |
    'Candles' |
    'Defi' |
    'Withdraw' |
    'Deposit' |
    'KYC' |
    'Statements' |
    'Presets' |
    'Notifications' |
    'Preloader' |
    'DefiLiquidityPools' |
    'Modal' |
    'MultiaccountOrder' |
    'MultiTransfers' |
    'WorkspaceChooseTool' |
    'Futures' |
    'Earn' |
    'UiActions' |
    'Groups' |
    'Addresses' |
    'TakeProfitStopLossModal' |
    'Intro' |
    'Rebates' |
    'LinkPlacement' |
    'PlacementApiKeysManagement' |
    'Margin' |
    'CorporateTeamMembers' |
    'OTC';

type AllGetters = ApiManagmentGetters | UserGetters | TransferGetters | PrivateSocketDataGetters | PublicSocketDataGetters | OrderBookLadderGetters | TradingDataGetters | TransferHistoryGetters | CandlesGetters | WithdrawGetters | DepositGetters | KYCGetters | NotificationsGetters | PreloaderGetters | BalancesGetters | AssetPairsGetters | AssetsGetters;
export type AllMutations = ApiManagmentMutations | UserMutations | TransferMutations | PrivateSocketDataMutations | PublicSocketDataMutations | OrderBookLadderMutations | TradingDataMutations | DefiMutations | TransferHistoryMutations | CandlesMutations | WithdrawMutations | DepositMutations | KYCMutations | StatementsMutations | PresetsMutations | NotificationsMutations | PreloaderMutations | DefiLiquidityPoolsMutations | ModalMutations | MultiaccountOrderMutations | FuturesMutations | WorkspaceChooseToolMutations | BalancesMutations | AssetPairsMutations | AssetsMutations | EarnMutations | GroupsMutations | AddressesMutations | TakeProfitStopLossModalMutations | OTCMutations | RebatesMutations | LinkPlacementMutations | PlacementApiKeysManagementMutations | MultiTransfersMutations | CorporateTeamMembersMutations | MarginMutations;
export type AllActions = ApiManagmentActions | UserActions | TransferActions | PrivateSocketDataActions | PublicSocketDataActions | OrderBookLadderActions | TradingDataActions | DefiActions | TransferHistoryActions | CandlesActions | WithdrawActions | DepositActions | KYCActions | StatementsActions | PresetsActions | NotificationsActions | DefiLiquidityPoolsActions | FuturesActions | WorkspaceChooseToolActions | BalancesActions | AssetPairsActions | AssetsActions | EarnActions | UiActionsActions | GroupsActions | AddressesActions | TakeProfitStopLossModalActions | IntroActions | OTCActions | RebatesActions | PlacementApiKeysManagementActions | CorporateTeamMembersActions | MarginActions;

export const useGetter = (type: ModuleNames, getter: AllGetters) => `${type}/${getter}`;
export const useMutation = (type: ModuleNames, mutation: AllMutations) => `${type}/${mutation}`;
export const useAction = (type: ModuleNames, action: AllActions) => `${type}/${action}`;
export interface StoreState {
    Accounts: any,
    Auth: any,
    Interface: any,
    localization: any,
    Notificator: any,
    OrderBook: any,
    Orders: any,
    Placements: any,
    Portfolio: any,
    TradeHistory: any,
    Transactions: any,
    Workspace: any,
    VuexEventListener: any,

    // v2
    ApiManagment: ApiManagmentState;
    AssetPairs: AssetPairsState;
    Assets: AssetsState;
    User: UserState;
    Transfer: TransferState;
    TransferHistory: TransferHistoryState,
    PrivateSocketData: PrivateSocketDataState;
    PublicSocketData: PublicSocketDataState;
    OrderBookLadder: OrderBookLadderState;
    // SpotTrades: SpotTradesState;
    TradingData: TradingDataState;
    Candles: CandlesState;
    Defi: DefiState;
    Withdraw: WithdrawState;
    Deposit: DepositState;
    KYC: KYCState;
    Statements: StatementsState;
    Presets: PresetsState;
    Notifications: NotificationsState;
    Preloader: PreloaderState;
    DefiLiquidityPools: DefiLiquidityPoolsState;
    Modal: ModalState;
    MultiaccountOrder: MultiaccountOrderState;
    WorkspaceChooseTool: WorkspaceChooseToolState;
    Futures: FuturesState;
    Balances: BalancesState;
    Earn: EarnState;
    Groups: GroupsState;
    Addresses: AddressesState;
    TakeProfitStopLossModal: TakeProfitStopLossModalState;
    Rebates: RebatesState;
    Intro: IntroState;
    OTC: OTCState;
    LinkPlacement: LinkPlacementState;
    PlacementApiKeysManagement: PlacementApiKeysManagementState;
    MultiTransfers: MultiTransfersState;
    CorporateTeamMembers: CorporateTeamMembersState;
    Margin: MarginState;
}

Vue.use<StoreState>(Vuex);
const Store = new Vuex.Store<StoreState>({
    modules: {
        Accounts,
        AssetPairs,
        Assets,
        Auth,
        Balances,
        Interface,
        localization,
        Notifications,
        Notificator,
        OrderBook,
        Orders,
        Placements,
        Portfolio,
        TradeHistory,
        Transactions,
        ApiManagment,
        User,
        Transfer,
        TransferHistory,
        PrivateSocketData,
        PublicSocketData,
        OrderBookLadder,
        // SpotTrades,
        TradingData,
        VuexEventListener,
        Defi,
        Candles,
        Withdraw,
        Deposit,
        KYC,
        Statements,
        Presets,
        Preloader,
        DefiLiquidityPools,
        Modal,
        MultiaccountOrder,
        WorkspaceChooseTool,
        Futures,
        Earn,
        UiActions,
        Groups,
        Addresses,
        TakeProfitStopLossModal,
        Rebates,
        Intro,
        OTC,
        LinkPlacement,
        PlacementApiKeysManagement,
        MultiTransfers,
        CorporateTeamMembers,
        Margin,
    } as Record<ModuleNames, any>,

    getters,

    actions,
});

Store.subscribe((mutation, state) => {
    if (mutation.type.split('/')[0] !== 'VuexEventListener') { Store.dispatch('VuexEventListener/mutationHandler', { mutation, state }); }
});

Store.subscribeAction({
    after: (action, state) => {
        if (action.type.split('/')[0] !== 'VuexEventListener') { Store.dispatch('VuexEventListener/actionHandler', { action, state }); }
    },
});

export default Store;


import Vue from 'vue';
import 'chart.js/auto';
import { Line as LineChartComponent } from 'vue-chartjs';
import 'chartjs-adapter-date-fns';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

export default Vue.extend<any, any, any, any>({
    components: { LineChartComponent },
    props: {
        chartData: {
            type: Object,
            required: true,
        },
        tooltipItemSortCallback: {
            type: Function,
            default: null,
        },
        tooltipTitleCallback: {
            type: Function,
            default: null,
        },
        tooltipLabelCallback: {
            type: Function,
            default: null,
        },
        tooltipFooterCallback: {
            type: Function,
            default: null,
        },
        xAxeLabelCallback: {
            type: Function,
            default: null,
        },
        yAxeLabelCallback: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            showTooltip: false,
            customTooltipHeader: '',
            customTooltipFooter: '',
            customTooltipBody: [],
            customTooltipBodyColors: [],
            customTooltipBodyBorderColors: [],
        };
    },
    computed: {
        darkTheme() {
            return this.$store.getters.isThemeDark;
        },
        fontColor() {
            return this.darkTheme ? 'rgba(255, 255, 255, 0.5)' : 'rgba(35, 36, 77, 0.5)';
        },
        gridLinesColor() {
            return this.darkTheme ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)';
        },
        data() {
            let { datasets } = this.chartData;
            const { labels } = this.chartData;

            datasets = datasets.map((dataset) => (dataset.datalabels
                ? dataset
                : {
                    ...dataset,
                    datalabels: {
                        labels: {
                            title: null,
                        },
                    },
                }));

            return {
                labels,
                datasets,
            };
        },
        options() {
            return {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            fontSize: 13,
                            fontColor: this.fontColor,
                            fontFamily: 'Nunito Sans, sans-serif',
                            fontWeight: 500,
                            padding: 5,
                            callback: this.yAxeLabelCallback ? this.yAxeLabelCallback : (value) => value,
                        },
                        grid: {
                            drawBorder: false,
                            zeroLineBorderDashOffset: 1,
                            zeroLineColor: this.gridLinesColor,
                            color: this.gridLinesColor,
                        },
                    },
                    x: {
                        type: 'time',
                        time: {
                            unit: 'day',
                            stepSize: 2,
                        },
                        ticks: {
                            major: {
                                enabled: false,
                                fontSize: 11,
                                fontStyle: 'bold',
                            },
                            maxRotation: 0,
                            minRotation: 0,
                            fontSize: 10,
                            fontColor: this.fontColor,
                            lineHeight: 3,
                            fontFamily: 'Nunito Sans, sans-serif',
                            callback: this.xAxeLabelCallback ? this.xAxeLabelCallback : (value) => value,
                            autoSkip: true,
                            maxTicksLimit: 10,
                        },
                        grid: {
                            display: false,
                        },
                    },
                },
                hover: {
                    mode: 'x',
                    intersect: false,
                },
                interaction: {
                    mode: 'index',
                    intersect: false,
                },
                plugins: {
                    tooltip: {
                        enabled: false,
                        // mode: 'index',
                        external: (ctx) => {
                            this.handleCustomTooltip(ctx);
                        },
                        // intersect: false,
                        // titleFontFamily: 'Nunito Sans, sans-serif',
                        // bodyFontFamily: 'Nunito Sans, sans-serif',
                        // titleFontSize: 13,
                        // bodyFontSize: 13,
                        // footerFontSize: 13,
                        callbacks: {
                            title: this.tooltipTitleCallback,
                            label: this.tooltipLabelCallback,
                            footer: this.tooltipFooterCallback,
                        },
                        itemSort: this.tooltipItemSortCallback ? this.tooltipItemSortCallback : () => 0,
                    },
                    datalabels: {
                        display: false,
                    },
                    legend: {
                        display: false,
                    },
                },
            };
        },
    },
    methods: {
        handleCustomTooltip(ctx) {
            const { tooltip } = ctx;

            if (tooltip.opacity === 0) {
                return;
            }

            const tooltipEl = document.getElementById('CustomTooltip');
            if (tooltipEl) {
                tooltipEl.style.opacity = '1';
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.left = `${tooltip.caretX}px`;
                tooltipEl.style.top = `${tooltip.caretY}px`;
                tooltipEl.style.transform = 'translate(-50%, -100%)';
            }
            if (tooltip.title && tooltip.title.length) {
                [this.customTooltipHeader] = tooltip.title;
            }
            if (tooltip.footer && tooltip.footer.length) {
                [this.customTooltipFooter] = tooltip.footer;
            }
            if (tooltip.body && tooltip.body.length) {
                this.customTooltipBody = tooltip.body.reduce((accum, { lines }) => {
                    accum.push(lines[0]);
                    return accum;
                }, []);
            }
            if (tooltip.body && tooltip.labelColors && tooltip.labelColors.length) {
                const tempColors: string[] = [];
                const tempBorderColors: string[] = [];
                tooltip.labelColors.forEach(({ backgroundColor, borderColor }) => {
                    tempColors.push(backgroundColor);
                    tempBorderColors.push(borderColor);
                });
                this.customTooltipBodyColors = tempColors;
                this.customTooltipBodyBorderColors = tempBorderColors;
            }
        },
    },
});

import SubuserResponse, { ISubuserResponse } from './SubuserResponse';

// This file was autogenerated. Please do not change.
export interface IListSubusersResponse {
    subusers?: ISubuserResponse[];
}

export default class ListSubusersResponse {
    readonly _subusers: SubuserResponse[] | undefined;

    /** Description: List of subusers */
    get subusers(): SubuserResponse[] | undefined {
        return this._subusers;
    }

    constructor(props: IListSubusersResponse) {
        if (props.subusers) {
            this._subusers = props.subusers.map((p) => new SubuserResponse(p));
        }
    }

    serialize(): IListSubusersResponse {
        const data: IListSubusersResponse = {
        };
        if (typeof this._subusers !== 'undefined') {
            data.subusers = this._subusers.map((p) => p.serialize());
        }
        return data;
    }

    toJSON(): IListSubusersResponse {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            subusers: !this._subusers ? true : this._subusers.reduce<boolean>((result, p) => result && p.validate().length === 0, true),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}

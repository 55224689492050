import { Action } from 'vuex';

import router from '@/router';
import ApiManagementApi from 'Apis/ApiManagement';
import KeyResponse from 'Entities/apiManagement/KeyResponse';
import CreateKeyPayload, { ICreateKeyPayload } from 'Entities/apiManagement/CreateKeyPayload';
import UpdateKeyPayload, { IUpdateKeyPayload } from 'Entities/apiManagement/UpdateKeyPayload';
import DeleteKeyPayload, { IDeleteKeyPayload } from 'Entities/apiManagement/DeleteKeyPayload';
import CreateKeyResponse from 'Entities/apiManagement/CreateKeyResponse';
import { MFA_ENROLL_FACTOR_TYPES } from 'Config/auth';
import { actionCreator, mutationCreator } from 'Store/utils';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import ApiError from 'Entities/ApiError';

const state = {
    apiKeys: [] as KeyResponse[],
    currentKey: undefined as CreateKeyResponse | undefined,
};

export type ApiManagmentState = typeof state;

export enum ApiManagmentGetters {}

const getters = {};

export enum ApiManagmentMutations {
    SET_API_KEYS = 'SET_API_KEYS',
    SET_CURRENT_KEY = 'SET_CURRENT_KEY',
}

export const SET_API_KEYS = mutationCreator<KeyResponse[]>('ApiManagment', ApiManagmentMutations.SET_API_KEYS);
export const SET_CURRENT_KEY = mutationCreator<CreateKeyResponse>('ApiManagment', ApiManagmentMutations.SET_CURRENT_KEY);

const mutations: Record<ApiManagmentMutations, (state: ApiManagmentState, ...args: any) => void> = {
    SET_API_KEYS(state, { payload: apiKeys }: ReturnType<typeof SET_API_KEYS>) {
        state.apiKeys = apiKeys;
    },
    SET_CURRENT_KEY(state, { payload: key }: ReturnType<typeof SET_CURRENT_KEY>) {
        state.currentKey = key;
    },
};

export enum ApiManagmentActions {
    getApiKeys = 'getApiKeys',
    createApiKey = 'createApiKey',
    deleteApiKey = 'deleteApiKey',
    updateApiKey = 'updateApiKey',
}

export const getApiKeys = actionCreator<undefined>('ApiManagment', ApiManagmentActions.getApiKeys);
export const createApiKey = actionCreator<ICreateKeyPayload>('ApiManagment', ApiManagmentActions.createApiKey);
export const deleteApiKey = actionCreator<IDeleteKeyPayload>('ApiManagment', ApiManagmentActions.deleteApiKey);
export const updateApiKey = actionCreator<IUpdateKeyPayload>('ApiManagment', ApiManagmentActions.updateApiKey);

const actions: Record<ApiManagmentActions, Action<ApiManagmentState, any>> = {
    async getApiKeys({ commit }) {
        const { data: keys } = await ApiManagementApi.getApiKeys();
        commit(SET_API_KEYS(keys, true));
    },
    async createApiKey({ dispatch, commit }, { payload: data }: ReturnType<typeof createApiKey>) {
        data.totp = await dispatch('Auth/getMFAToken', { type: MFA_ENROLL_FACTOR_TYPES.TOTP }, { root: true });
        try {
            commit(SET_LOADING_ON(undefined), { root: true });
            let key;
            try {
                key = await ApiManagementApi.createApiKey(new CreateKeyPayload(data));
            } catch (error) {
                if (error instanceof ApiError) {
                    await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during creating API-Key, please try again later', { root: true });
                }
                await router.push('/profile/manageApiKeys').catch(() => { /* navigation error */ });
                return;
            }
            commit(SET_CURRENT_KEY(key.data, true));
            await router.push({ path: '/profile/viewKey', query: { newKey: 'true' } }).catch(() => { /* navigation error */ });
            await dispatch(getApiKeys(undefined, true));
            await dispatch('Notificator/showSuccessNotification', 'API-Key was created', { root: true });
        } finally {
            commit(SET_LOADING_OFF(undefined), { root: true });
        }
    },
    async deleteApiKey({ dispatch, commit }, { payload: data }: ReturnType<typeof deleteApiKey>) {
        data.totp = await dispatch('Auth/getMFAToken', { type: MFA_ENROLL_FACTOR_TYPES.TOTP, previousRoute: '/profile/manageApiKeys' }, { root: true });

        try {
            commit(SET_LOADING_ON(undefined), { root: true });
            await ApiManagementApi.deleteApiKey(new DeleteKeyPayload(data));
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during removing API-Key, please try again later', { root: true });
            }
            await router.push('/profile/manageApiKeys').catch(() => { /* navigation error */ });
            return;
        } finally {
            commit(SET_LOADING_OFF(undefined), { root: true });
        }
        await router.replace('/profile/manageApiKeys').catch(() => { /* navigation error */ });
        await dispatch(getApiKeys(undefined, true));
        await dispatch('Notificator/showSuccessNotification', 'Key was deleted', { root: true });
    },
    async updateApiKey({ dispatch }, { payload: data }: ReturnType<typeof updateApiKey>) {
        const startChallengePath = (router as any).history.current.path;
        data.totp = await dispatch('Auth/getMFAToken', { type: MFA_ENROLL_FACTOR_TYPES.TOTP }, { root: true });
        await router.replace(startChallengePath).catch(() => { /* navigation error */ });

        await ApiManagementApi.updateApiKey(new UpdateKeyPayload(data));
        await dispatch(getApiKeys(undefined, true));
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

import { Action } from 'vuex';
import { customAlphabet } from 'nanoid';

import { actionCreator, mutationCreator } from 'Store/utils';
import GroupsApi from 'Apis/Groups';
import GroupsParams from 'Entities/privatePresenter/GroupsParams';
import Group from 'Entities/privatePresenter/Group';
import { parsePaginationHeaders } from 'Lib/utils/PaginationParser';
import CreateGroupPayload from 'Entities/groupManagement/CreateGroupPayload';
import GroupParams from 'Entities/privatePresenter/GroupParams';
import router from '@/router';
import UpdateMemberPayload, { IUpdateMemberPayload } from 'Entities/groupManagement/UpdateMemberPayload';
import InviteGroupMemberPayload, { IInviteGroupMemberPayload } from 'Entities/groupManagement/InviteGroupMemberPayload';
import MFAGroupPayload from 'Entities/groupManagement/MFAGroupPayload';
import { MFA_ENROLL_FACTOR_TYPES } from 'Config/auth';
import GroupMemberPayload, { IGroupMemberPayload } from 'Entities/groupManagement/GroupMemberPayload';
import UpdateGroupPayload, { IUpdateGroupPayload } from 'Entities/groupManagement/UpdateGroupPayload';
import ApiError from 'Entities/ApiError';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import GroupRequestsParams from 'Entities/privatePresenter/GroupRequestsParams';
import GroupRequest from 'Entities/privatePresenter/GroupRequest';
import BaseGroupRequestPayload from 'Entities/groupManagement/BaseGroupRequestPayload';
import BaseGroupPayload from 'Entities/groupManagement/BaseGroupPayload';
import GroupMemberResponse from 'Entities/groupManagement/GroupMemberResponse';
import AssignAccountPayload, { IAssignAccountPayload } from 'Entities/groupManagement/AssignAccountPayload';
import RevokeAccountPayload, { IRevokeAccountPayload } from 'Entities/groupManagement/RevokeAccountPayload';

export enum MEMBERS_ROLES {
    creator = 'creator',
    member = 'member',
    admin = 'admin'
}

const state = {
    groups: [] as Group[],
    managedGroup: null as null | Group,
    pendingMembers: [] as GroupRequest[],
    managedAccountGroups: [] as Group[],
    managedAccountSelectedGroup: null as null | GroupMemberResponse,
};

export type GroupsState = typeof state;

export enum GroupsMutations {
    SET_GROUPS = 'SET_GROUPS',
    SET_MANAGED_GROUP = 'SET_MANAGED_GROUP',
    SET_PENDING_MEMBERS = 'SET_PENDING_MEMBERS',
    SET_MANAGED_ACCOUNT_GROUPS = 'SET_MANAGED_ACCOUNT_GROUPS',
    SET_MANAGED_ACCOUNT_SELECTED_GROUP = 'SET_MANAGED_ACCOUNT_SELECTED_GROUP',
}

export const SET_GROUPS = mutationCreator<Group[]>('Groups', GroupsMutations.SET_GROUPS);
export const SET_MANAGED_GROUP = mutationCreator<Group>('Groups', GroupsMutations.SET_MANAGED_GROUP);
export const SET_PENDING_MEMBERS = mutationCreator<GroupRequest[]>('Groups', GroupsMutations.SET_PENDING_MEMBERS);
export const SET_MANAGED_ACCOUNT_GROUPS = mutationCreator<Group[]>('Groups', GroupsMutations.SET_MANAGED_ACCOUNT_GROUPS);
export const SET_MANAGED_ACCOUNT_SELECTED_GROUP = mutationCreator<GroupMemberResponse | null>('Groups', GroupsMutations.SET_MANAGED_ACCOUNT_SELECTED_GROUP);

const mutations: Record<GroupsMutations, (state: GroupsState, ...args: any) => void> = {
    SET_GROUPS(state, { payload: groups }) {
        state.groups = groups;
    },
    SET_MANAGED_GROUP(state, { payload: group }) {
        state.managedGroup = group;
    },
    SET_PENDING_MEMBERS(state, { payload: members }) {
        state.pendingMembers = members;
    },
    SET_MANAGED_ACCOUNT_GROUPS(state, { payload: groups }) {
        state.managedAccountGroups = groups;
    },
    SET_MANAGED_ACCOUNT_SELECTED_GROUP(state, { payload: group }) {
        state.managedAccountSelectedGroup = group;
    },
};

export enum GroupsActions {
    getGroups = 'getGroups',
    createGroup = 'createGroup',
    getGroupInfo = 'getGroupInfo',
    updateMember = 'updateMember',
    inviteUser = 'inviteUser',
    deleteUser = 'deleteUser',
    deleteGroup = 'deleteGroup',
    updateGroup = 'updateGroup',
    getPendingMembers = 'getPendingMembers',
    cancelRequest = 'cancelRequest',
    leaveGroup = 'leaveGroup',
    getManagedAccountGroups = 'getManagedAccountGroups',
    getManagedAccountSelectedGroup = 'getManagedAccountSelectedGroup',
    assignMember = 'assignMember',
    unAssignMember = 'unAssignMember',
}

export const getGroups = actionCreator<undefined>('Groups', GroupsActions.getGroups);
export const createGroup = actionCreator<undefined>('Groups', GroupsActions.createGroup);
export const getGroupInfo = actionCreator<string>('Groups', GroupsActions.getGroupInfo);
export const updateMember = actionCreator<IUpdateMemberPayload>('Groups', GroupsActions.updateMember);
export const inviteUser = actionCreator<IInviteGroupMemberPayload>('Groups', GroupsActions.inviteUser);
export const deleteUser = actionCreator<IGroupMemberPayload>('Groups', GroupsActions.deleteUser);
export const deleteGroup = actionCreator<string>('Groups', GroupsActions.deleteGroup);
export const updateGroup = actionCreator<IUpdateGroupPayload>('Groups', GroupsActions.updateGroup);
export const getPendingMembers = actionCreator<undefined>('Groups', GroupsActions.getPendingMembers);
export const cancelRequest = actionCreator<string>('Groups', GroupsActions.cancelRequest);
export const leaveGroup = actionCreator<string>('Groups', GroupsActions.leaveGroup);
export const getManagedAccountGroups = actionCreator<undefined>('Groups', GroupsActions.getManagedAccountGroups);
export const getManagedAccountSelectedGroup = actionCreator<string | null>('Groups', GroupsActions.getManagedAccountSelectedGroup);
export const assignMember = actionCreator<IAssignAccountPayload>('Groups', GroupsActions.assignMember);
export const unAssignMember = actionCreator<IRevokeAccountPayload>('Groups', GroupsActions.unAssignMember);

const actions: Record<GroupsActions, (Action<GroupsState, any>)> = {
    async getGroups({ commit }) {
        let allGroups: Group[] = [];
        const { data: groups, headers } = await GroupsApi.privateGetGroups(new GroupsParams({
            includeTotal: true,
            page: 1,
            perPage: 100,
        }), true);
        allGroups = groups;
        if (headers) {
            const { totalPage } = parsePaginationHeaders(headers);
            if (totalPage && totalPage > 1) {
                for (let i = 2; i <= totalPage; i += 1) {
                    // eslint-disable-next-line no-await-in-loop
                    const { data: extraGroups } = await GroupsApi.privateGetGroups(new GroupsParams({
                        page: i,
                        perPage: 100,
                    }));
                    allGroups = [...allGroups, ...extraGroups];
                }
            }
        }
        commit(SET_GROUPS(allGroups, true));
    },
    async createGroup({ dispatch }) {
        const nanoid = customAlphabet('0123456789', 4);
        const { data: group } = await GroupsApi.createGroup(new CreateGroupPayload({
            name: `GROUP_${nanoid()}`,
        }));
        if (!group.id) {
            await dispatch('Notificator/showErrorNotification', 'Group id is undefined');
            return;
        }
        await dispatch(getGroupInfo(group.id, true));
    },
    async getGroupInfo({ commit, dispatch }, { payload: id }) {
        if (!id) {
            await dispatch('Notificator/showErrorNotification', 'Invalid group id');
            return;
        }
        const { data: groupInfo } = await GroupsApi.privateGetGroup(new GroupParams({
            id,
        }));
        commit(SET_MANAGED_GROUP(groupInfo, true));
        await dispatch(getPendingMembers(undefined, true));
        if (router.currentRoute.path !== '/profile/group-manage') {
            await router.push('/profile/group-manage').catch(() => { /* navigation error */ });
        }
    },
    async updateMember({ dispatch }, { payload: { groupId, userId, role } }) {
        if (!groupId || !userId || !role) {
            await dispatch('Notificator/showErrorNotification', 'Parameters are invalid');
            return;
        }
        await GroupsApi.updateGroupMember(new UpdateMemberPayload({
            groupId,
            userId,
            role,
        }));
        await dispatch(getGroupInfo(groupId, true));
    },
    async inviteUser(_, { payload: { email, groupId, role } }) {
        let payload: IInviteGroupMemberPayload;
        if (email.indexOf('@') === -1) {
            payload = {
                groupId,
                role,
                userId: email.toUpperCase(),
            };
        } else {
            payload = {
                email,
                groupId,
                role,
            };
        }
        await GroupsApi.createGroupRequest(new InviteGroupMemberPayload(payload));
    },
    async deleteUser({ dispatch }, { payload: { userId, groupId } }) {
        await GroupsApi.removeGroupMember(new GroupMemberPayload({
            userId,
            groupId,
        }));
        await dispatch(getGroupInfo(groupId, true));
    },
    async deleteGroup({ commit, dispatch }, { payload: groupId }) {
        const { emailCode, totpCode } = await dispatch('Auth/getMFAToken', {
            type: MFA_ENROLL_FACTOR_TYPES.EMAIL_TOTP,
            action: 'GroupDelete',
        }, { root: true });
        try {
            commit(SET_LOADING_ON(undefined), { root: true });
            await GroupsApi.deleteGroup(new MFAGroupPayload({
                totp: totpCode,
                emailCode,
                groupId,
            }));
            await dispatch('Notificator/showSuccessNotification', 'Group has been successfully deleted', { root: true });
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error deleting a group', { root: true });
            }
        } finally {
            await router.push('/profile/groups-list').catch(() => { /* navigation error */ });
            commit(SET_LOADING_OFF(undefined), { root: true });
        }
    },
    async updateGroup({ dispatch }, { payload: { groupId, name, description } }) {
        let payload: IUpdateGroupPayload;
        if (!description) {
            payload = {
                groupId,
                name,
            };
        } else {
            payload = {
                groupId,
                name,
                description,
            };
        }
        await GroupsApi.updateGroup(new UpdateGroupPayload(payload));
        await dispatch(getGroupInfo(groupId, true));
    },
    async getPendingMembers({ state, commit }) {
        if (!state.managedGroup) {
            return;
        }
        let allRequests: GroupRequest[];
        const { data: members, headers } = await GroupsApi.privateGetGroupRequests(new GroupRequestsParams({
            groupId: state.managedGroup!.id,
            includeTotal: true,
            page: 1,
            perPage: 100,
            status: 'processing',
        }));
        allRequests = members;
        if (headers) {
            const { totalPage } = parsePaginationHeaders(headers);
            if (totalPage && totalPage > 1) {
                for (let i = 2; i <= totalPage; i += 1) {
                    // eslint-disable-next-line no-await-in-loop
                    const { data: extraMembers } = await GroupsApi.privateGetGroupRequests(new GroupRequestsParams({
                        groupId: state.managedGroup!.id,
                        page: i,
                        perPage: 100,
                        status: 'processing',
                    }));
                    allRequests = [...allRequests, ...extraMembers];
                }
            }
        }
        commit(SET_PENDING_MEMBERS(allRequests, true));
    },
    async cancelRequest(_, { payload: requestId }) {
        await GroupsApi.cancelGroupRequest(new BaseGroupRequestPayload({
            requestId,
        }));
    },
    async leaveGroup(_, { payload: groupId }) {
        await GroupsApi.leaveGroup(new BaseGroupPayload({
            groupId,
        }));
        await router.push('/profile/groups-list').catch(() => { /* navigation error */ });
    },
    async getManagedAccountGroups({ commit, rootState }) {
        let allGroups: Group[];
        const { data: groups, headers } = await GroupsApi.privateGetGroups(new GroupsParams({
            includeTotal: true,
            page: 1,
            perPage: 100,
        }));
        allGroups = groups;
        if (headers) {
            const { totalPage } = parsePaginationHeaders(headers);
            if (totalPage && totalPage > 1) {
                for (let i = 2; i <= totalPage; i += 1) {
                    // eslint-disable-next-line no-await-in-loop
                    const { data: extraGroups } = await GroupsApi.privateGetGroups(new GroupsParams({
                        page: i,
                        perPage: 100,
                    }));
                    allGroups = [...allGroups, ...extraGroups];
                }
            }
        }
        commit(SET_MANAGED_ACCOUNT_GROUPS(allGroups.filter((group) => {
            const member = group.members?.find((member) => member.uid === rootState.User.currentUser.id);
            if (!member) {
                return false;
            }
            return member.roleName === MEMBERS_ROLES.creator;
        }), true));
    },
    async getManagedAccountSelectedGroup({ commit }, { payload: groupId }) {
        if (groupId === null) {
            commit(SET_MANAGED_ACCOUNT_SELECTED_GROUP(null, true));
            return;
        }
        const { data: groupInfo } = await GroupsApi.getGroupInformation(new BaseGroupPayload({
            groupId,
        }));
        commit(SET_MANAGED_ACCOUNT_SELECTED_GROUP(groupInfo, true));
    },
    async assignMember(_, { payload }) {
        await GroupsApi.assignAccount(new AssignAccountPayload(payload));
    },
    async unAssignMember(_, { payload }) {
        await GroupsApi.revokeAccount(new RevokeAccountPayload(payload));
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};

import snsWebSdk from '@sumsub/websdk';

import { actionCreator, mutationCreator } from 'Store/utils';
import VerificationApi from 'Apis/Verification';
import router from '@/router';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';

export const GET_KYC_TOKEN_TIMEOUT = 5000;

const state = {
    isKycInProgress: false as boolean,
    previousRoute: '' as string,
    sdkInstance: null as any,
    getTokenTimeout: null as null | NodeJS.Timeout,
};

export type KYCState = typeof state;

export enum KYCGetters {}

const getters: Record<KYCGetters, (state: KYCState, ...args: any) => void> = {};

export enum KYCMutations {
    SET_KYC_STATUS = 'SET_KYC_STATUS',
}

export const SET_KYC_STATUS = mutationCreator<boolean>('KYC', KYCMutations.SET_KYC_STATUS);

const mutations: Record<KYCMutations, (state: KYCState, ...args: any) => void> = {
    SET_KYC_STATUS(state, { payload: status }: ReturnType<typeof SET_KYC_STATUS>) {
        state.isKycInProgress = status;
    },
};

export enum KYCActions {
    initSDK = 'initSDK',
    closeSDK = 'closeSDK',
}

export const initSDK = actionCreator<boolean | undefined>('KYC', KYCActions.initSDK);
export const closeSDK = actionCreator<void>('KYC', KYCActions.closeSDK);

const actions = {
    async initSDK({ state, commit, dispatch, rootState }, { payload: force }: ReturnType<typeof initSDK>) {
        commit(SET_LOADING_ON(undefined), { root: true });
        if (router.currentRoute.name !== 'KYC') {
            state.previousRoute = router.currentRoute.name;
            await router.push('/KYC').catch(() => { /* navigation error */ });
        }

        let token = '';
        try {
            const { data: { token: res } } = await VerificationApi.getToken();
            token = res;
        } catch (error) {
            if (force) {
                state.getTokenTimeout = setTimeout(
                    () => dispatch(initSDK(true, true)),
                    GET_KYC_TOKEN_TIMEOUT,
                );
            } else {
                commit(SET_LOADING_OFF(undefined), { root: true });
                await router.push('/terminal').catch(() => { /* navigation error */ });
            }
        }

        const { theme } = rootState.Interface;
        const sdkInstance = snsWebSdk.init(token, () => new Promise((resolve) => resolve(token))).withConf({
            lang: 'en',
            theme,
            uiConf: {
                customCssStr: '@charset "UTF-8";@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap);html{margin-top:5%!important}button.submit,button[type=submit]{color:#fff!important;background:#291980!important}button.submit.disabled,button.submit.disabled:active{background-color:#b9b0eb!important}.step .line{border-color:#291980!important;background-color:#291980!important}.step .bullet{border-color:#291980!important;background-color:#291980!important}.content{background-color:unset!important;box-shadow:unset!important}.content-wrapper{background-color:#fff!important;margin:0 20%;border-radius:15px;padding:5px 0;box-shadow:0 0 30px 0 rgba(0,0,0,.08)}',
            },
        })
            .on('idCheck.onApplicantStatusChanged', async (payload: any) => {
                if (
                    payload.reviewResult.reviewAnswer === 'GREEN'
                    && payload.reviewStatus === 'completed'
                ) {
                    sdkInstance.destroy();
                    commit(SET_KYC_STATUS(false, true));
                    if (state.previousRoute) {
                        await router.push(state.previousRoute).catch(() => { /* navigation error */ });
                    } else {
                        await router.push('/terminal').catch(() => { /* navigation error */ });
                    }
                    await dispatch('User/getCurrentUser', undefined, { root: true });
                    await dispatch('Notificator/showSuccessNotification', 'KYC was successfully verified');
                }
            })
            .on('idCheck.onError', () => {
                // sdk internal error
                commit(SET_LOADING_OFF(undefined), { root: true });
            })
            .on('idCheck.onReady', async () => {
                commit(SET_LOADING_OFF(undefined), { root: true });
            })
            .build();

        state.sdkInstance = sdkInstance;

        sdkInstance.launch('#sumsub-websdk-container');
        commit(SET_KYC_STATUS(true, true));
    },
    async closeSDK({ state, commit }) {
        if (state.getTokenTimeout) {
            clearTimeout(state.getTokenTimeout);
        }
        if (state.sdkInstance) {
            state.sdkInstance.destroy();
        }
        commit(SET_KYC_STATUS(false, true));
        if (state.previousRoute) {
            await router.push(state.previousRoute).catch(() => { /* navigation error */ });
        } else {
            await router.push('/terminal').catch(() => { /* navigation error */ });
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

import { Action } from 'vuex';

import { actionCreator, mutationCreator } from 'Store/utils';
import SubuserApi from 'Apis/Subuser';
import ListSubusersBody from 'Entities/userLoginHistory/ListSubusersBody';
import { parsePaginationHeaders } from 'Lib/utils/PaginationParser';
import SubuserResponse from 'Entities/userLoginHistory/SubuserResponse';
import InviteSubuserBody, { IInviteSubuserBody } from 'Entities/userLoginHistory/InviteSubuserBody';
import ApiError from 'Entities/ApiError';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import RemoveSubuserAccountBody, { IRemoveSubuserAccountBody } from 'Entities/userLoginHistory/RemoveSubuserAccountBody';
import AssignSubuserAccountBody, { IAssignSubuserAccountBody } from 'Entities/userLoginHistory/AssignSubuserAccountBody';
import SuspendSubuserBody, { ISuspendSubuserBody } from 'Entities/userLoginHistory/SuspendSubuserBody';

const state = {
    membersList: [] as SubuserResponse[],
    editiongUserEmail: '',
    initialized: false,
};

export type CorporateTeamMembersState = typeof state;

export enum CorporateTeamMembersMutations {
    SET_MEMBERS_LIST = 'SET_MEMBERS_LIST',
    SET_INITIALIZE = 'SET_INITIALIZE',
    SET_EDITING_USER_EMAIL = 'SET_EDITING_USER_EMAIL',
}

export const SET_MEMBERS_LIST = mutationCreator<SubuserResponse[]>('CorporateTeamMembers', CorporateTeamMembersMutations.SET_MEMBERS_LIST);
export const SET_INITIALIZE = mutationCreator<boolean>('CorporateTeamMembers', CorporateTeamMembersMutations.SET_INITIALIZE);
export const SET_EDITING_USER_EMAIL = mutationCreator<string>('CorporateTeamMembers', CorporateTeamMembersMutations.SET_EDITING_USER_EMAIL);

const mutations: Record<CorporateTeamMembersMutations, (state: CorporateTeamMembersState, ...args: any) => void> = {
    SET_MEMBERS_LIST(state, { payload: list }: ReturnType<typeof SET_MEMBERS_LIST>) {
        state.membersList = list;
    },
    SET_INITIALIZE(state, { payload: value }: ReturnType<typeof SET_INITIALIZE>) {
        state.initialized = value;
    },
    SET_EDITING_USER_EMAIL(state, { payload: email }: ReturnType<typeof SET_EDITING_USER_EMAIL>) {
        state.editiongUserEmail = email;
    },
};

export enum CorporateTeamMembersActions {
    getMembersList = 'getMembersList',
    inviteMember = 'inviteMember',
    removeAccount = 'removeAccount',
    assignAccount = 'assignAccount',
    suspendUser = 'suspendUser',
    activateUser = 'activateUser',
    cancelUser = 'cancelUser',
}

export const getMembersList = actionCreator<undefined>('CorporateTeamMembers', CorporateTeamMembersActions.getMembersList);
export const inviteMember = actionCreator<string>('CorporateTeamMembers', CorporateTeamMembersActions.inviteMember);
export const removeAccount = actionCreator<IRemoveSubuserAccountBody>('CorporateTeamMembers', CorporateTeamMembersActions.removeAccount);
export const assignAccount = actionCreator<IAssignSubuserAccountBody>('CorporateTeamMembers', CorporateTeamMembersActions.assignAccount);
export const suspendUser = actionCreator<ISuspendSubuserBody>('CorporateTeamMembers', CorporateTeamMembersActions.suspendUser);
export const activateUser = actionCreator<ISuspendSubuserBody>('CorporateTeamMembers', CorporateTeamMembersActions.activateUser);
export const cancelUser = actionCreator<IInviteSubuserBody>('CorporateTeamMembers', CorporateTeamMembersActions.cancelUser);

const actions: Record<CorporateTeamMembersActions, Action<CorporateTeamMembersState, any>> = {
    async getMembersList({ commit }) {
        try {
            commit(SET_INITIALIZE(true, true));
            const fullList: SubuserResponse[] = [];
            const { data: list, headers } = await SubuserApi.listSubusers(new ListSubusersBody({
                includeTotal: true,
                page: 1,
                perPage: 100,
            }), true);
            fullList.push(...(list.subusers ?? []));

            if (headers) {
                const { totalPage } = parsePaginationHeaders(headers);
                if (totalPage && totalPage > 1) {
                    for (let i = 2; i <= totalPage; i += 1) {
                        // eslint-disable-next-line no-await-in-loop
                        const { data: extraList } = await SubuserApi.listSubusers(new ListSubusersBody({
                            page: i,
                            perPage: 100,
                        }));
                        fullList.push(...(extraList.subusers ?? []));
                    }
                }
            }

            commit(SET_MEMBERS_LIST(fullList, true));
        } catch {
            commit(SET_MEMBERS_LIST([], true));
        }
    },
    async inviteMember({ commit, dispatch }, { payload: email }: ReturnType<typeof inviteMember>) {
        try {
            commit(SET_LOADING_ON(undefined), { root: true });
            await SubuserApi.inviteSubuser(new InviteSubuserBody({
                email,
            }));
            await dispatch(getMembersList(undefined, true));
            await dispatch('Notificator/showSuccessNotification', 'Invitation has been successfully sent', { root: true });
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong', { root: true });
            }
        } finally {
            commit(SET_LOADING_OFF(undefined), { root: true });
        }
    },
    async removeAccount({ dispatch }, { payload }: ReturnType<typeof removeAccount>) {
        try {
            await SubuserApi.removeSubuserAccount(new RemoveSubuserAccountBody(payload));
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong', { root: true });
            }
        }
    },
    async assignAccount({ dispatch }, { payload }: ReturnType<typeof assignAccount>) {
        try {
            if ((payload.policies ?? []).length === 0) {
                return;
            }

            await SubuserApi.assignSubuserAccount(new AssignSubuserAccountBody(payload));
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong', { root: true });
            }
        }
    },
    async suspendUser({ dispatch, commit }, { payload }: ReturnType<typeof suspendUser>) {
        try {
            commit(SET_LOADING_ON(undefined), { root: true });
            await SubuserApi.suspendSubuser(new SuspendSubuserBody(payload));
            await dispatch(getMembersList(undefined, true));
            await dispatch('Notificator/showSuccessNotification', 'SubUser has been successfully suspended', { root: true });
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong', { root: true });
            }
        } finally {
            commit(SET_LOADING_OFF(undefined), { root: true });
        }
    },
    async activateUser({ commit, dispatch }, { payload }: ReturnType<typeof activateUser>) {
        try {
            commit(SET_LOADING_ON(undefined), { root: true });
            await SubuserApi.activateSubuser(new SuspendSubuserBody(payload));
            await dispatch(getMembersList(undefined, true));
            await dispatch('Notificator/showSuccessNotification', 'SubUser has been successfully activated', { root: true });
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong', { root: true });
            }
        } finally {
            commit(SET_LOADING_OFF(undefined), { root: true });
        }
    },
    async cancelUser({ commit, dispatch }, { payload }: ReturnType<typeof cancelUser>) {
        try {
            commit(SET_LOADING_ON(undefined), { root: true });
            await SubuserApi.cancelInvite(new InviteSubuserBody(payload));
            await dispatch(getMembersList(undefined, true));
            await dispatch('Notificator/showSuccessNotification', 'Invitation has been successfully canceled', { root: true });
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong', { root: true });
            }
        } finally {
            commit(SET_LOADING_OFF(undefined), { root: true });
        }
    },
};

export default {
    namespaced: true,
    actions,
    mutations,
    state,
};

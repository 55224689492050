// This file was autogenerated. Please do not change.
export interface ISubuserAccount {
    id?: string;
    name?: string;
    policies?: string[];
}

export default class SubuserAccount {
    readonly _id: string | undefined;

    /** Description: Account id */
    get id(): string | undefined {
        return this._id;
    }

    readonly _name: string | undefined;

    /** Description: Account name */
    get name(): string | undefined {
        return this._name;
    }

    readonly _policies: string[] | undefined;

    /** Description: Allowed actions */
    get policies(): string[] | undefined {
        return this._policies;
    }

    constructor(props: ISubuserAccount) {
        if (typeof props.id === 'string') {
            this._id = props.id.trim();
        }
        if (typeof props.name === 'string') {
            this._name = props.name.trim();
        }
        if (props.policies) {
            this._policies = props.policies;
        }
    }

    serialize(): ISubuserAccount {
        const data: ISubuserAccount = {
        };
        if (typeof this._id !== 'undefined') {
            data.id = this._id;
        }
        if (typeof this._name !== 'undefined') {
            data.name = this._name;
        }
        if (typeof this._policies !== 'undefined') {
            data.policies = this._policies;
        }
        return data;
    }

    toJSON(): ISubuserAccount {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            id: !this._id ? true : typeof this._id === 'string',
            name: !this._name ? true : typeof this._name === 'string',
            policies: !this._policies ? true : this._policies.reduce<boolean>((result, p) => result && typeof p === 'string', true),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}

import { timeConstants } from 'Config/timeConstants';
import {
    generateLabels,
    generateStatistic,
    PORTFOLIO_TYPES,
} from 'Models/portfolio';
import { SET_IS_MULTIACCOUNTS } from 'Store/v2/Balances';
import PortfolioApi from 'Apis/Portfolio';
import PNLPayload from 'Lib/entities/portfolioBalanceHistory/PNLPayload';
import ChartPayload from 'Lib/entities/portfolioBalanceHistory/ChartPayload';

const state = {
    range: {
        start: new Date(Date.now() - timeConstants.WEEK),
        end: new Date(Date.now()),
    },

    labels: [],
    balancesStatistics: [],

    summaryPeriodPnl: 0,

    toggleAccountListenerID: null,
    updateAccountBalancesListenerID: null,

    maxDoughnutSectorsCount: 3,

    portfolioType: PORTFOLIO_TYPES.CURRENT_ACCOUNT,

    unrealizedPnl: {} as Record<string, any>,
};

const getters = {
    requestAccountsData: (state, getters, rootState, rootGetters) => {
        switch (state.portfolioType) {
            case PORTFOLIO_TYPES.CURRENT_ACCOUNT:
                return {
                    accountId: rootGetters['Accounts/activeAccountID'],
                };
            case PORTFOLIO_TYPES.ALL_GROUP_ACCOUNTS:
                return {
                    accountIds: (rootGetters['Accounts/isActiveAccountOwned']
                        ? rootGetters['Accounts/ownAccounts']
                        : rootGetters['Accounts/manageAccounts']
                    ).map(({ id }) => id),
                };
            default:
                return {};
        }
    },

    labels: (state) => state.labels,

    toggleAccountListenerID: (state) => state.toggleAccountListenerID,
    updateAccountBalancesListenerID: (state) => state.updateAccountBalancesListenerID,

    maxDoughnutSectorsCount: (state) => state.maxDoughnutSectorsCount,

    balancesStatistics: (state) => state.balancesStatistics,
    shortBalancesStatistics: (state, getters) => Object.fromEntries(
        getters.balancesStatistics.map((statistics) => [statistics.assetSymbol, statistics]),
    ),
    shortBalanceStatisticByAssetSymbol: (state, getters) => (assetSymbol) => getters.shortBalancesStatistics[assetSymbol],
    range: (state) => state.range,
};

const mutations = {
    SET_RANGE(state, range) {
        state.range = range;
    },
    SET_PORTFOLIO_TYPE(state, type) {
        state.portfolioType = type;
    },

    PUSH_BALANCE_STATISTIC(state, data) {
        state.balancesStatistics.push(data);
    },
    CLEAR_BALANCES_STATISTICS(state) {
        state.balancesStatistics = [];
    },

    SET_LABELS(state, labels) {
        state.labels = labels;
    },

    SET_PNL_CURRENT(state, currentPnL) {
        state.summaryPeriodPnl = currentPnL;
    },

    SET_TOGGLE_ACCOUNT_LISTENER_ID(state, listenerId) {
        state.toggleAccountListenerID = listenerId;
    },
    SET_UPDATE_ACCOUNT_BALANCES_LISTENER_ID(state, listenerId) {
        state.updateAccountBalancesListenerID = listenerId;
    },
};

const actions = {
    setRange({ commit, dispatch }, range) {
        commit('SET_RANGE', range);
        dispatch('remountData');
    },
    async setPortfolioType({ commit, dispatch }, type) {
        commit('SET_PORTFOLIO_TYPE', type);
        if (type === PORTFOLIO_TYPES.ALL_GROUP_ACCOUNTS) {
            commit(SET_IS_MULTIACCOUNTS(true), { root: true });
        } else {
            commit(SET_IS_MULTIACCOUNTS(false), { root: true });
        }
        await dispatch('remountData');
    },
    pushBalanceStatistic({ commit }, data) {
        commit('PUSH_BALANCE_STATISTIC', data);
    },
    downloadBalanceStatistic({ getters, rootGetters, dispatch, commit }, {
        balances, UTCFromTs, UTCTots, labels,
    }) {
        if (rootGetters['Accounts/activeAccount'].policies.includes('portfolio')) {
            const assetSymbolsBalances = Object.fromEntries(balances.map((balance) => [balance.assetSymbol, balance]));

            PortfolioApi.getChartPoints(new ChartPayload({
                assetSymbols: balances.map(({ assetSymbol }) => assetSymbol),
                quotationAssetSymbol: rootGetters['Assets/GET_QUOTATION_ASSET_SYMBOL'],
                ...getters.requestAccountsData,
                timeGroup: 'day',
                UTCFromTs,
                UTCTots,
            })).then(({ data: placementsStatistics }) => {
                commit('CLEAR_BALANCES_STATISTICS');
                Object.keys(placementsStatistics)
                    .forEach((assetSymbol) => {
                        dispatch(
                            'pushBalanceStatistic',
                            generateStatistic(placementsStatistics[assetSymbol], assetSymbolsBalances[assetSymbol], labels),
                        );
                    });
            }).catch(() => {
                // api error
            });
        }
    },
    updateBalancesStatistic({ getters, rootGetters, commit, dispatch }, { UTCFromTs, UTCTots }) {
        function runUpdates() {
            const withNow = UTCTots > Date.now() - timeConstants.DAY;

            const labels = generateLabels(UTCFromTs, UTCTots, { withNow, hasAdditionalLabel: !withNow });

            commit('CLEAR_BALANCES_STATISTICS');
            commit('SET_LABELS', labels);

            dispatch('downloadBalanceStatistic', {
                balances: rootGetters['Balances/GET_AGGREGATED_BALANCES'],
                UTCFromTs,
                UTCTots,
                labels,
            });
        }

        if (getters.updateAccountBalancesListenerID !== null) {
            dispatch(
                'VuexEventListener/removeActionListener',
                {
                    type: 'Balances/setBalances',
                    id: getters.updateAccountBalancesListenerID,
                },
                { root: true },
            ).then(() => {
                commit('SET_UPDATE_ACCOUNT_BALANCES_LISTENER_ID', null);
            });
        }

        if (rootGetters['Balances/GET_AGGREGATED_BALANCES'].length > 0) {
            runUpdates();
        } else {
            dispatch(
                'VuexEventListener/addActionListener',
                {
                    type: 'Balances/setBalances',
                    once: true,
                    callback: () => {
                        runUpdates();
                        commit('SET_UPDATE_ACCOUNT_BALANCES_LISTENER_ID', null);
                    },
                },
                { root: true },
            ).then((listenerId) => {
                commit('SET_UPDATE_ACCOUNT_BALANCES_LISTENER_ID', listenerId);
            });
        }
    },

    async updateAccountPnl({ getters, rootGetters, commit }, { UTCFromTs, UTCTots }) {
        if (rootGetters['Accounts/activeAccount'] && rootGetters['Accounts/activeAccount'].policies.includes('portfolio')) {
            try {
                const { data } = await PortfolioApi.getPNLHistory(new PNLPayload({
                    UTCFromTs,
                    UTCToTs: UTCTots,
                    pnlResultType: 'Summary',
                    quotationAssetSymbol: rootGetters['Assets/GET_QUOTATION_ASSET_SYMBOL'],
                    ...getters.requestAccountsData,
                }));
                commit('SET_PNL_CURRENT', data.quantityList!.reduce((accum, pnl) => accum + Number(pnl), 0));
            } catch {
                // api error
            }
        }
    },

    remountData({ state, dispatch }) {
        const UTCFromTs = new Date(state.range.start).valueOf();
        const UTCTots = new Date(state.range.end).valueOf();

        dispatch('updateAccountPnl', { UTCFromTs, UTCTots });
        dispatch('updateBalancesStatistic', { UTCFromTs, UTCTots });
    },

    mountPortfolio({ rootGetters, dispatch }) {
        dispatch('setToggleAccountListener');

        function firstDownloadStatistic() {
            dispatch('remountData');
        }

        if (rootGetters['Balances/GET_ACTIVE_ACCOUNT_BALANCES'].length === 0) {
            dispatch('VuexEventListener/addMutationListener', {
                type: 'Balances/SET_BALANCES',
                once: true,
                callback: () => {
                    firstDownloadStatistic();
                },
            }, { root: true });
        } else {
            firstDownloadStatistic();
        }
    },

    setToggleAccountListener({
        getters, rootGetters, commit, dispatch,
    }) {
        function setListener() {
            dispatch('VuexEventListener/addActionListener', {
                type: 'Accounts/setActiveAccount',
                callback: () => {
                    dispatch('remountData');
                },
            }, { root: true }).then((listenerId) => {
                commit('SET_TOGGLE_ACCOUNT_LISTENER_ID', listenerId);
            });
        }

        if (getters.toggleAccountListenerID === null) {
            if (rootGetters['Accounts/activeAccountID']) {
                setListener();
            } else {
                dispatch('VuexEventListener/addActionListener', {
                    type: 'Accounts/setActiveAccount',
                    once: true,
                    callback: () => {
                        setListener();
                    },
                }, { root: true });
            }
        }
    },

    removeToggleAccountListener({ getters, commit, dispatch }) {
        if (getters.toggleAccountListenerID) {
            dispatch('VuexEventListener/removeActionListener', {
                type: 'Accounts/setActiveAccount',
                id: getters.toggleAccountListenerID,
            }, { root: true }).then(() => {
                commit('SET_TOGGLE_ACCOUNT_LISTENER_ID', null);
            });
        }
    },

    updateUnrealizedPNL({ state }, { accountId, unrealizedPnl }) {
        const temp = { ...state.unrealizedPnl };
        temp[accountId] = unrealizedPnl;
        state.unrealizedPnl = { ...temp };
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};


import Vue from 'vue';
import 'chart.js/auto';
import { Doughnut } from 'vue-chartjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'chart.js';

Chart.register(ChartDataLabels);

export default Vue.extend<any, any, any, any>({
    components: {
        Doughnut,
    },
    props: {
        chartData: {
            type: Object,
            required: true,
        },
        tooltipLabelCallback: {
            type: Function,
            default: null,
        },
        tooltipLabelColorCallback: {
            type: Function,
            default: null,
        },
        tooltipFooterCallback: {
            type: Function,
            default: null,
        },
        layoutPaddings: {
            type: Object,
            default: null,
        },
    },
    computed: {
        tooltipsCallbacks() {
            const result: any = {};

            if (this.tooltipLabelCallback) { result.label = this.tooltipLabelCallback; }
            if (this.tooltipLabelColorCallback) { result.labelColor = this.tooltipLabelColorCallback; }
            if (this.tooltipFooterCallback) { result.footer = this.tooltipFooterCallback; }

            return result;
        },
        hasTooltips() {
            return Object.keys(this.tooltipsCallbacks).length > 0;
        },
        data() {
            const { datasets, labels } = this.chartData;

            const mappedDataset = datasets.map((dataset) => (dataset.datalabels
                ? { ...dataset }
                : {
                    ...dataset,
                    datalabels: {
                        labels: {
                            title: null,
                        },
                    },
                }));

            return {
                labels,
                datasets: mappedDataset,
            };
        },
        options() {
            const result: any = {
                responsive: true,
                rotation: 0.5 * Math.PI,
                tooltips: {
                    mode: 'index',
                    intersect: true,
                    titleFontSize: 13,
                    bodyFontSize: 13,
                    footerFontSize: 13,
                },
                hover: {
                    mode: 'index',
                    intersect: true,
                },
                plugins: {
                    datalabels: {
                        borderRadius: 3,
                        lineWidth: 1,
                        stretch: 30,
                        font(ctx) {
                            return {
                                size: ctx.hovered ? 12 : 8,
                            };
                        },
                        padding(ctx) {
                            return ctx.hovered ? 5 : 3;
                        },
                        color: '#fff',
                        textAlign: 'center',
                        anchor: 'end',
                        align: 'end',
                        listeners: {
                            enter(ctx) {
                                ctx.hovered = true;
                                return true;
                            },
                            leave(ctx) {
                                ctx.hovered = false;
                                return true;
                            },
                        },
                    },
                    legend: {
                        display: false,
                    },
                },
            };

            if (this.hasTooltips) {
                result.tooltips.callbacks = this.tooltipsCallbacks;
            }

            if (this.layoutPaddings) {
                result.layout = {
                    padding: this.layoutPaddings,
                };
            }

            return result;
        },
    },
});

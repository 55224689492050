import ApiError from 'Entities/ApiError';
import ActionSuccessResponse from 'Entities/userLoginHistory/ActionSuccessResponse';
import AssignSubuserAccountBody from 'Entities/userLoginHistory/AssignSubuserAccountBody';
import InviteSubuserBody from 'Entities/userLoginHistory/InviteSubuserBody';
import InviteSubuserResponse from 'Entities/userLoginHistory/InviteSubuserResponse';
import ListSubusersBody from 'Entities/userLoginHistory/ListSubusersBody';
import ListSubusersResponse from 'Entities/userLoginHistory/ListSubusersResponse';
import RemoveSubuserAccountBody from 'Entities/userLoginHistory/RemoveSubuserAccountBody';
import ServiceStatus from 'Entities/userLoginHistory/ServiceStatus';
import SubuserAccount from 'Entities/userLoginHistory/SubuserAccount';
import SuspendSubuserBody from 'Entities/userLoginHistory/SuspendSubuserBody';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class SubuserApi {
    static async activateSubuser(suspendsubuserbody: SuspendSubuserBody, withHeaders?: boolean): Promise<{ data: ActionSuccessResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...suspendsubuserbody.validate());
        if (haveError.length > 0) {
            throw new ApiError('activateSubuser', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/users/subusers/activate`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(suspendsubuserbody.serialize()),
        });
        if (res.status === 200) {
            return { data: new ActionSuccessResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Subuser/activateSubuser', { response: res, data: result, fields: [JSON.stringify({ suspendsubuserbody })] });
    }

    static async assignSubuserAccount(assignsubuseraccountbody: AssignSubuserAccountBody, withHeaders?: boolean): Promise<{ data: SubuserAccount; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...assignsubuseraccountbody.validate());
        if (haveError.length > 0) {
            throw new ApiError('assignSubuserAccount', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/users/subusers/assignAccount`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(assignsubuseraccountbody.serialize()),
        });
        if (res.status === 200) {
            return { data: new SubuserAccount(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Subuser/assignSubuserAccount', { response: res, data: result, fields: [JSON.stringify({ assignsubuseraccountbody })] });
    }

    static async cancelInvite(invitesubuserbody: InviteSubuserBody, withHeaders?: boolean): Promise<{ data: InviteSubuserResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...invitesubuserbody.validate());
        if (haveError.length > 0) {
            throw new ApiError('cancelInvite', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/users/subusers/cancelInvite`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(invitesubuserbody.serialize()),
        });
        if (res.status === 200) {
            return { data: new InviteSubuserResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Subuser/cancelInvite', { response: res, data: result, fields: [JSON.stringify({ invitesubuserbody })] });
    }

    static async inviteSubuser(invitesubuserbody: InviteSubuserBody, withHeaders?: boolean): Promise<{ data: InviteSubuserResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...invitesubuserbody.validate());
        if (haveError.length > 0) {
            throw new ApiError('inviteSubuser', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/users/subusers/invite`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(invitesubuserbody.serialize()),
        });
        if (res.status === 200) {
            return { data: new InviteSubuserResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Subuser/inviteSubuser', { response: res, data: result, fields: [JSON.stringify({ invitesubuserbody })] });
    }

    static async listSubusers(listsubusersbody: ListSubusersBody, withHeaders?: boolean): Promise<{ data: ListSubusersResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...listsubusersbody.validate());
        if (haveError.length > 0) {
            throw new ApiError('listSubusers', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/users/subusers/list`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(listsubusersbody.serialize()),
        });
        if (res.status === 200) {
            return { data: new ListSubusersResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Subuser/listSubusers', { response: res, data: result, fields: [JSON.stringify({ listsubusersbody })] });
    }

    static async removeSubuserAccount(removesubuseraccountbody: RemoveSubuserAccountBody, withHeaders?: boolean): Promise<{ data: ActionSuccessResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...removesubuseraccountbody.validate());
        if (haveError.length > 0) {
            throw new ApiError('removeSubuserAccount', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/users/subusers/removeAccount`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(removesubuseraccountbody.serialize()),
        });
        if (res.status === 200) {
            return { data: new ActionSuccessResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Subuser/removeSubuserAccount', { response: res, data: result, fields: [JSON.stringify({ removesubuseraccountbody })] });
    }

    static async suspendSubuser(suspendsubuserbody: SuspendSubuserBody, withHeaders?: boolean): Promise<{ data: ActionSuccessResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...suspendsubuserbody.validate());
        if (haveError.length > 0) {
            throw new ApiError('suspendSubuser', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/users/subusers/suspend`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(suspendsubuserbody.serialize()),
        });
        if (res.status === 200) {
            return { data: new ActionSuccessResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Subuser/suspendSubuser', { response: res, data: result, fields: [JSON.stringify({ suspendsubuserbody })] });
    }
}

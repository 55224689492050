// This file was autogenerated. Please do not change.
export interface IActionSuccessResponse {
    isSuccess?: boolean;
}

export default class ActionSuccessResponse {
    readonly _isSuccess: boolean | undefined;

    /** Description: Action result */
    get isSuccess(): boolean | undefined {
        return this._isSuccess;
    }

    constructor(props: IActionSuccessResponse) {
        if (typeof props.isSuccess === 'boolean') {
            this._isSuccess = props.isSuccess;
        }
    }

    serialize(): IActionSuccessResponse {
        const data: IActionSuccessResponse = {
        };
        if (typeof this._isSuccess !== 'undefined') {
            data.isSuccess = this._isSuccess;
        }
        return data;
    }

    toJSON(): IActionSuccessResponse {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            isSuccess: !this._isSuccess ? true : typeof this._isSuccess === 'boolean',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}

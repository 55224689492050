import { Action } from 'vuex';

import { actionCreator, mutationCreator } from 'Store/utils';
import AccountsApi from 'Apis/Accounts';
import GetTradeAPIKeysRequest from 'Entities/cexAccountManagement/GetTradeAPIKeysRequest';
import TradeAPIKey from 'Entities/cexAccountManagement/TradeAPIKey';
import CreateTradeAPIKeyRequest from 'Entities/cexAccountManagement/CreateTradeAPIKeyRequest';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import ApiError from 'Entities/ApiError';
import router from '@/router';
import DeleteAPIKeyRequest from 'Entities/cexAccountManagement/DeleteAPIKeyRequest';
import GetTradeAPIKeySecretsRequest from 'Entities/cexAccountManagement/GetTradeAPIKeySecretsRequest';
import { MFA_ENROLL_FACTOR_TYPES } from 'Config/auth';

const state = {
    keys: [] as TradeAPIKey[],
    newKey: {
        key: '',
        secret: '',
        status: '',
        placementTags: [] as string[],
        ips: '',
        label: '',
        isNew: false,
    },
};

export type PlacementApiKeysManagementState = typeof state;

export enum PlacementApiKeysManagementMutations {
    SET_KEYS = 'SET_KEYS',
    SET_NEW_KEY = 'SET_NEW_KEY',
}

export const SET_KEYS = mutationCreator<TradeAPIKey[]>('PlacementApiKeysManagement', PlacementApiKeysManagementMutations.SET_KEYS);
export const SET_NEW_KEY = mutationCreator<{
    key: string;
    secret: string;
    status: string;
    placementTags: string[];
    isNew: boolean;
    ips: string;
    label: string;
}>('PlacementApiKeysManagement', PlacementApiKeysManagementMutations.SET_NEW_KEY);

const mutations: Record<PlacementApiKeysManagementMutations, (state: PlacementApiKeysManagementState, ...args: any[]) => void> = {
    SET_KEYS(state, { payload }: ReturnType<typeof SET_KEYS>) {
        state.keys = payload;
    },
    SET_NEW_KEY(state, { payload }: ReturnType<typeof SET_NEW_KEY>) {
        state.newKey = payload;
    },
};

export enum PlacementApiKeysManagementActions {
    getKeys = 'getKeys',
    createKey = 'createKey',
    deleteKey = 'deleteKey',
    viewKey = 'viewKey',
}

export const getKeys = actionCreator<string>('PlacementApiKeysManagement', PlacementApiKeysManagementActions.getKeys);
export const createKey = actionCreator<CreateTradeAPIKeyRequest>('PlacementApiKeysManagement', PlacementApiKeysManagementActions.createKey);
export const deleteKey = actionCreator<{ requestPayload: DeleteAPIKeyRequest; accountId: string; }>('PlacementApiKeysManagement', PlacementApiKeysManagementActions.deleteKey);
export const viewKey = actionCreator<GetTradeAPIKeySecretsRequest>('PlacementApiKeysManagement', PlacementApiKeysManagementActions.viewKey);

const actions: Record<PlacementApiKeysManagementActions, Action<PlacementApiKeysManagementState, any>> = {
    async getKeys({ commit }, { payload }: ReturnType<typeof getKeys>) {
        const { data: keys } = await AccountsApi.privateCexAccountsGetTradeAPIKeys(new GetTradeAPIKeysRequest({
            accountId: payload,
        }));
        commit(SET_KEYS(keys ?? [], true));
    },

    async createKey({ commit, dispatch }, { payload }: ReturnType<typeof createKey>) {
        try {
            const previousRoute = router.currentRoute.path;
            const totp: string = await dispatch(
                'Auth/getMFAToken',
                {
                    type: MFA_ENROLL_FACTOR_TYPES.TOTP,
                    previousRoute,
                },
                { root: true },
            );
            commit(SET_LOADING_ON(undefined), { root: true });
            const { data: key } = await AccountsApi
                .privateCexAccountsCreateTradeAPIKey(new CreateTradeAPIKeyRequest({
                    ...payload.serialize(),
                    totp,
                }));
            if (key.status === 'active') {
                commit(SET_NEW_KEY({
                    ...key,
                    placementTags: key.placementTags.split(','),
                    isNew: true,
                    ips: payload.ips.split(',').join(', '),
                    label: payload.label,
                } as {
                    key: string;
                    secret: string;
                    status: string;
                    placementTags: string[];
                    isNew: boolean;
                    ips: string;
                    label: string;
                }, true));
                await router.push('/accounts/placement-api-key-view').catch(() => { /* navigation error */ });
            } else {
                await dispatch(getKeys(payload.accountId!, true));
                await router.push('/accounts/placement-api-keys-management').catch(() => { /* navigation error */ });
            }
            await dispatch('Notificator/showSuccessNotification', 'API-Key has been successfully created', { root: true });
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong', { root: true });
            }
            await router.push('/accounts/placement-api-keys-management').catch(() => { /* navigation error */ });
            throw new Error();
        } finally {
            commit(SET_LOADING_OFF(undefined), { root: true });
        }
    },
    async deleteKey({ commit, dispatch }, { payload }: ReturnType<typeof deleteKey>) {
        try {
            const previousRoute = router.currentRoute.path;
            const totp: string = await dispatch(
                'Auth/getMFAToken',
                {
                    type: MFA_ENROLL_FACTOR_TYPES.TOTP,
                    previousRoute,
                },
                { root: true },
            );
            commit(SET_LOADING_ON(undefined), { root: true });
            await AccountsApi.privateCexAccountsDeleteTradeAPIKey(new DeleteAPIKeyRequest({
                ...payload.requestPayload.serialize(),
                totp,
            }));
            await dispatch(getKeys(payload.accountId!, true));
            await router.push('/accounts/placement-api-keys-management').catch(() => { /* navigation error */ });
            await dispatch('Notificator/showSuccessNotification', 'API-Key has been successfully deleted', { root: true });
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong', { root: true });
            }
            await router.push('/accounts/placement-api-keys-management').catch(() => { /* navigation error */ });
        } finally {
            commit(SET_LOADING_OFF(undefined), { root: true });
        }
    },
    async viewKey({ commit, dispatch }, { payload }: ReturnType<typeof viewKey>) {
        try {
            const previousRoute = router.currentRoute.path;
            const totp: string = await dispatch(
                'Auth/getMFAToken',
                {
                    type: MFA_ENROLL_FACTOR_TYPES.TOTP,
                    previousRoute,
                },
                { root: true },
            );
            commit(SET_LOADING_ON(undefined), { root: true });
            const { data: keyData } = await AccountsApi
                .privateCexAccountsGetTradeAPIKeySecretsOnce(new GetTradeAPIKeySecretsRequest({
                    ...payload.serialize(),
                    totp,
                }));
            commit(SET_NEW_KEY({
                key: keyData.key,
                secret: keyData.secret,
                status: keyData.status,
                placementTags: keyData.placementTags.split(','),
                isNew: keyData.secret !== undefined && keyData.secret !== '',
                ips: keyData.ips,
                label: keyData.label,
            }, true));
            await router.push('/accounts/placement-api-key-view').catch(() => { /* navigation error */ });
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong', { root: true });
            }
            await router.push('/accounts/placement-api-keys-management').catch(() => { /* navigation error */ });
        } finally {
            commit(SET_LOADING_OFF(undefined), { root: true });
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};

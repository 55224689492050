/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Action } from 'vuex';
import introJs from 'intro.js';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { IntroStep } from 'intro.js/src/core/steps';

import { actionCreator } from 'Store/utils';
import '../../assets/styles/introjs.pcss';
import UserApi from 'Apis/User';
import OnboardingPayload from 'Entities/userLoginHistory/OnboardingPayload';
import ApiError from 'Entities/ApiError';
import router from '@/router';
import { gridPages } from 'Models/interface';

// @ts-ignore
import IntroWelcome from '../../assets/images/icons/IntroWelcome.svg';
// @ts-ignore
import IntroUi from '../../assets/images/icons/IntroUi.svg';
// @ts-ignore
import IntroAccounts from '../../assets/images/icons/IntroAccounts.svg';
// @ts-ignore
import IntroWallet from '../../assets/images/icons/IntroWallets.svg';
// @ts-ignore
import IntroTrading from '../../assets/images/icons/IntroTrading.svg';
// @ts-ignore
import IntroDeFi from '../../assets/images/icons/IntroDeFi.svg';
// @ts-ignore
import IntroPortfolio from '../../assets/images/icons/IntroPortfolio.svg';

export type PagesGridsType = Record<string, Record<string, { x: number; y: number; w: number; h: number; i: number; name: string; }[]>>;

export type PagesBreakpointsType = Record<string, string>;

const fixSafariView = () => {
    if (
        document.querySelector('.introjs-tooltipReferenceLayer')
        && document.querySelector('.introjs-tooltip')
        && document.querySelector('.introjs-helperLayer')
    ) {
        (document.querySelector('.introjs-tooltipReferenceLayer') as any).style.position = 'unset';
        (document.querySelector('.introjs-helperLayer') as any).style.position = 'unset';
        setTimeout(() => {
            (document.querySelector('.introjs-tooltipReferenceLayer') as any).style.position = 'absolute';
            (document.querySelector('.introjs-helperLayer') as any).style.position = 'absolute';
            document.querySelector('.introjs-tooltip')!.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
        }, 100);
    }
};

const getStepTitle = (title: string, isLeft = false): string => {
    return `
        <div class="intro-header">
            <span>
                ${title}
            </span>
            <div
                id="sideMenuTrigger"
                onclick="document.querySelector('.intro-side-menu').classList.toggle('${isLeft ? 'active-left' : 'active'}')"
                class="icon-wrapper"
            >
                <svg viewBox="0 0 16 16" fill="currentColor">
                    <path d="M4.66667 4.0002H15.3333C15.5101 4.0002 15.6797 3.92996 15.8047 3.80494C15.9298 3.67991 16 3.51035 16 3.33354C16 3.15672 15.9298 2.98716 15.8047 2.86213C15.6797 2.73711 15.5101 2.66687 15.3333 2.66687H4.66667C4.48986 2.66687 4.32029 2.73711 4.19526 2.86213C4.07024 2.98716 4 3.15672 4 3.33354C4 3.51035 4.07024 3.67991 4.19526 3.80494C4.32029 3.92996 4.48986 4.0002 4.66667 4.0002Z" />
                    <path d="M15.3333 7.33313H4.66667C4.48986 7.33313 4.32029 7.40337 4.19526 7.52839C4.07024 7.65342 4 7.82299 4 7.9998C4 8.17661 4.07024 8.34618 4.19526 8.47121C4.32029 8.59623 4.48986 8.66647 4.66667 8.66647H15.3333C15.5101 8.66647 15.6797 8.59623 15.8047 8.47121C15.9298 8.34618 16 8.17661 16 7.9998C16 7.82299 15.9298 7.65342 15.8047 7.52839C15.6797 7.40337 15.5101 7.33313 15.3333 7.33313Z" />
                    <path d="M15.3333 12H4.66667C4.48986 12 4.32029 12.0702 4.19526 12.1953C4.07024 12.3203 4 12.4899 4 12.6667C4 12.8435 4.07024 13.0131 4.19526 13.1381C4.32029 13.2631 4.48986 13.3333 4.66667 13.3333H15.3333C15.5101 13.3333 15.6797 13.2631 15.8047 13.1381C15.9298 13.0131 16 12.8435 16 12.6667C16 12.4899 15.9298 12.3203 15.8047 12.1953C15.6797 12.0702 15.5101 12 15.3333 12Z" />
                    <path d="M1.33333 4.66666C2.06971 4.66666 2.66667 4.06971 2.66667 3.33333C2.66667 2.59695 2.06971 2 1.33333 2C0.596954 2 0 2.59695 0 3.33333C0 4.06971 0.596954 4.66666 1.33333 4.66666Z" />
                    <path d="M1.33333 9.33353C2.06971 9.33353 2.66667 8.73658 2.66667 8.0002C2.66667 7.26382 2.06971 6.66687 1.33333 6.66687C0.596954 6.66687 0 7.26382 0 8.0002C0 8.73658 0.596954 9.33353 1.33333 9.33353Z" />
                    <path d="M1.33333 13.9998C2.06971 13.9998 2.66667 13.4028 2.66667 12.6664C2.66667 11.9301 2.06971 11.3331 1.33333 11.3331C0.596954 11.3331 0 11.9301 0 12.6664C0 13.4028 0.596954 13.9998 1.33333 13.9998Z" />
                </svg>
            </div>
        </div>
    `;
};

const getStepIntro = (title: string, icon: any, text: string, image: any = undefined): string => {
    if (image) {
        return `
        <div class="intro-content">
            <img src="${icon}" />
            <div>
                <p class="sub-header-text">
                    ${title}
                </p>
                <p class="content-text">
                    ${text}
                </p>
            </div>
            <img src="${image}" />
        </div>`;
    }

    return `
    <div class="intro-content">
        <img src="${icon}" />
        <div>
            <p class="sub-header-text">
                ${title}
            </p>
            <p class="content-text">
                ${text}
            </p>
        </div>
    </div>
    `;
};

const getTooltipPositionByTargetElement = (grid: { x: number; y: number; w: number; h: number; i: number; name: string; }[] | undefined, moduleName: string) => {
    const elem = grid?.find((e) => e.name === moduleName);
    if (grid === undefined || elem === undefined) {
        return 'bottom-left-aligned';
    }
    const lastElement = grid.reduce((accum, current) => {
        if (accum === undefined) {
            return current;
        }
        if (current.y + current.h > accum.y + accum.h) {
            return current;
        }
        return accum;
    }, undefined as undefined | { x: number; y: number; w: number; h: number; i: number; name: string; })!;

    let vertical = 'top';
    let horizontal = 'right';
    if (elem.y < lastElement.y + lastElement.h - elem.y - elem.h) {
        vertical = 'bottom';
    }
    if (elem.x === 0) {
        horizontal = 'left';
    }
    return `${vertical}-${horizontal}-aligned`;
};

const state = {
    intro: [
        (grids: PagesGridsType, breakpoints: PagesBreakpointsType) => ([
            {
                title: getStepTitle('Welcome'),
                intro: getStepIntro(
                    'Welcome to Single Broker',
                    IntroWelcome,
                    'Welcome to our cryptocurrency trading platform! Your journey to exploring expansive digital asset trading options on our platform begins here.',
                ),
                position: 'floating',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Welcome'),
                intro: getStepIntro(
                    'Welcome to Single Broker',
                    IntroWelcome,
                    'Which professional tools are available to you now: <br /><br /> 🔗 One-Stop Integration.<br /><br />Centralized Exchanges. We\'ve seamlessly integrated both spot and futures trading from various centralized exchanges. No more multiple registrations, KYC procedures, or juggling different API-Keys. It\'s all streamlined for you!<br /><br />Decentralized Exchanges. Dive into the decentralized world! We\'ve partnered with several key DEXs, enabling you to provide liquidity and perform swaps across diverse networks, all while working directly with our platform\'s custodial service.',
                ),
                position: 'floating',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Welcome'),
                intro: getStepIntro(
                    'Welcome to Single Broker',
                    IntroWelcome,
                    '📊 Portfolio Mastery <br /><br /> Segregated & Aggregated Analytics. Get a granular view of your trading strategies. With segregated analytics, you can test and refine your strategies, and with aggregated insights, see the holistic results of your trading endeavors.',
                ),
                position: 'floating',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Welcome'),
                intro: getStepIntro(
                    'Welcome to Single Broker',
                    IntroWelcome,
                    '🤝 Empowerment through Delegation <br /><br /> Custom Rights Management. We understand the need for flexibility. Our account management system lets you delegate rights to other platform users with precision. Tailor-make your delegation to fit your needs.<br /><br />Sub-Delegation for Teams. We have introduced a sub-delegation function for institutional investors and trading teams. Manage funds and client relationships, collaborate, share responsibilities and trade efficiently together!',
                ),
                position: 'floating',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Welcome'),
                intro: getStepIntro(
                    'Welcome to Single Broker',
                    IntroWelcome,
                    '🛠 Unified SingleBroker API <br /><br /> Simplified Integration. For our tech-savvy users and budding entrepreneurs, our SingleBroker API is a game-changer. Trade or even build your startup\'s infrastructure with just one integration. Say goodbye to the hassle of interacting with multiple exchanges.',
                ),
                position: 'floating',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Welcome'),
                intro: getStepIntro(
                    'Welcome to Single Broker',
                    IntroWelcome,
                    '🔒 Uncompromised Security Cold Wallets & Sub-Accounts <br /><br /> We\'ve designed a closed-loop system combining cold wallets and exchange sub-accounts. This not only ensures the safe movement of your funds but also adds an extra layer of security.<br /><br />Deposit Insurance. Peace of mind is crucial. That\'s why we\'ve insured your deposits, ensuring your assets are protected.',
                ),
                position: 'floating',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Welcome'),
                intro: getStepIntro(
                    'Why KYC is Essential?',
                    IntroWelcome,
                    'Before diving into the trading world, we need to ensure the safety and legitimacy of each member of our platform. This is where the Know Your Customer (KYC) procedure comes into play. The KYC process is a standard verification procedure that is essential to prevent identity theft, financial fraud, money laundering, and terrorist financing. It’s not just a regulatory requirement but a protective measure to safeguard our platform and its esteemed users, like you.',
                ),
                element: document.querySelector('#completeKYC'),
                position: 'bottom',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Welcome'),
                intro: getStepIntro(
                    'How to Pass Identity Verification',
                    IntroWelcome,
                    `
                        To complete the KYC procedure smoothly, please ensure you have the following:
                        <ul class="content-text">
                            <li>
                                Identity Document: A valid government-issued document (e.g., Passport, Driver’s License, or National ID).
                            </li>
                            <li>
                                Device with a Front Camera: A smartphone, tablet, laptop, or desktop computer with a functioning front camera for identity verification.
                            </li>
                            <li>
                                Access to Email: Ensure you have access to your registered email account for verification and communication purposes.
                            </li>
                            <li>
                                Proof of Residency: A recent document confirming your residence, such as a utility bill, bank statement, or tax refund document. Make sure the document is not older than 3 months and displays your full name and residential address.
                            </li>
                            <li>
                                About 10 Minutes of Your Time: Set aside a small window of uninterrupted time to go through the KYC process.
                            </li>                            
                        </ul>`,
                ),
                position: 'floating',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Welcome'),
                intro: getStepIntro(
                    'How to Pass Identity Verification',
                    IntroWelcome,
                    `
                            If you registered a corporate account, please be advised that additional documentation pertaining to your legal entity will be required, in addition to the standard KYC documents for individual ultimate beneficiaries.
                            <br />
                            <br />
                            Additional Documentation for Corporate Accounts:
                            <ul class="content-text">
                                <li>
                                    Certificate of Incorporation. A valid certificate proving your business's legal status and existence.
                                </li>
                                <li>
                                    Memorandum and Articles of Association. Official documents governing your company’s regulations and highlighting the company’s purpose.
                                </li>
                                <li>
                                    Ownership and Control Structure. A clear illustration or documentation that details your company’s ownership and management hierarchy.
                                </li>
                                <li>
                                    Ultimate Beneficial Owners (UBOs) Information. Full identification details of individuals who ultimately own or control over 25% of the company’s shares or voting rights, or who otherwise exercise control over the company.
                                </li>
                            </ul>`,
                ),
                position: 'floating',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Welcome'),
                intro: getStepIntro(
                    'Let\'s dive into platform',
                    IntroWelcome,
                    `We're excited to have you onboard and help you get started. Our platform offers a range of features to help you manage your funds and execute trades efficiently. In this onboarding training, we'll cover the following topics:
                            <ul class="content-text">
                                <li>
                                    Platform User Interface
                                </li>
                                <li>
                                    Trading Accounts Management
                                </li>
                                <li>
                                    Funds Management
                                </li>
                                <li>
                                    Trading and Investments
                                </li>
                                <li>
                                    Portfolio Analytics
                                </li>
                                <li>
                                    Financial Statements
                                </li>
                                <li>
                                    API Management
                                </li>
                            </ul>`,
                ),
                position: 'floating',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('User Interface'),
                intro: getStepIntro(
                    'Platform Interface',
                    IntroUi,
                    'Our trading platform is designed with modular pages that use a drag-and-drop system, providing all the essential tools for your trading needs. Let\'s explore the navigation features available to you.',
                ),
                position: 'floating',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('User Interface'),
                intro: getStepIntro(
                    'Navigation Bar',
                    IntroUi,
                    'At a glance, you\'ll find the core tabs of our trading platform including: Trading Terminal, Analytic Portfolio, Wallets and Fund Allocation, DeFi Tools, Financial Statements, and Custom Workspaces.<br /><br />Additionally, access essential system modules like Support Chat, Display Settings, Notifications, Personal Cabinet, and Logout button.',
                ),
                element: document.querySelector('#mainHeader'),
                position: 'bottom-middle-aligned',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('User Interface'),
                intro: getStepIntro(
                    'Trading Accounts Menu',
                    IntroUi,
                    'Within this module, you\'ll discover all your trading accounts, be they personal or ones you manage for others. Each trading account boasts segregated wallets, sub-accounts on centralized exchanges, and its own distinct portfolio analytics.<br /><br />While you can create as many of your own accounts as you\'d like, and oversee numerous accounts of other users, do note that these come with associated fees.',
                ),
                element: document.querySelector('#leftMenu'),
                position: 'right',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('User Interface'),
                intro: getStepIntro(
                    'Screen Workspace',
                    IntroUi,
                    'Each section of our trading platform offers the vital tools tailored for professional trading and investment, all organized intuitively on your screen.<br /><br />Specifically, this area displays the trading terminal and its associated modules, dedicated solely to trading on centralized exchanges.',
                ),
                element: document.querySelector('#terminalPage'),
                position: 'floating',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('User Interface'),
                intro: getStepIntro(
                    'User Interface Interactions',
                    IntroUi,
                    'For a personalized experience, each module on the screen can be relocated based on your preference. The majority of our trading platform\'s modules are equipped with a drag-n-drop functionality. Adjust the Terminal interface to best suit your needs.',
                ),
                element: document.querySelector('#terminalPriceChart'),
                position: getTooltipPositionByTargetElement(grids[gridPages.TERMINAL][breakpoints[gridPages.TERMINAL]], 'PriceChart'),
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('User Interface', true),
                intro: getStepIntro(
                    'Workspace Layouts',
                    IntroUi,
                    'This section allows you to select between a light or dark interface theme and save your preferred preset, encompassing your customized arrangement of screen modules. While there\'s no limit to the number of presets you can have, it\'s important to note that each screen has its own unique preset system.',
                ),
                element: document.querySelector('#themeChange'),
                position: 'bottom-right-aligned',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('User Interface', true),
                intro: getStepIntro(
                    'Quick Support',
                    IntroUi,
                    'Utilize this button for immediate assistance from our support team regarding platform functionalities.<br /><br />If you\'re unable to access your account, please reach out via email at <a href="mailto:support@singlebroker.com" target="_blank">support@singlebroker.com</a> or submit a request on our website\'s support page <a href="https://www.singlebroker.com/support" target="_blank">https://www.singlebroker.com/support</a>',
                ),
                element: document.querySelector('#chatAndSupport'),
                position: 'bottom-right-aligned',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('User Interface', true),
                intro: getStepIntro(
                    'Onboarding',
                    IntroUi,
                    'Should you wish to revisit any part of the onboarding process, simply click this button to pick up from where you left off.',
                ),
                element: document.querySelector('#openOnboarding'),
                position: 'bottom-right-aligned',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Trading Accounts'),
                intro: getStepIntro(
                    'Trading Accounts Management',
                    IntroAccounts,
                    'As you navigate our platform, you\'ll notice the Trading Account Menu consistently accessible across all sections. This isn\'t just a menu—it\'s your central hub for all trading activities.<br /><br />Here, you can engage with both centralized and decentralized exchanges, leverage powerful DeFi tools, manage your deposits and withdrawals, allocate digital assets and currencies among various trading venues, and gain insights through in-depth analytical metrics. Familiarize yourself with this menu to unlock the full potential of your crypto trading experience.',
                ),
                element: document.querySelector('#leftMenu'),
                position: 'right',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Trading Accounts'),
                intro: getStepIntro(
                    'Owned Accounts Section',
                    IntroAccounts,
                    'Here you\'ll find a comprehensive list of your personal trading accounts. For each account, customization options are available, allowing you to set a unique name, description, and color.<br /><br />Additionally, if desired, you have the capability to grant access to any of your accounts to another platform user.',
                ),
                element: document.querySelector('#ownedAccountsList'),
                position: 'right',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Trading Accounts'),
                intro: getStepIntro(
                    'Add New Account',
                    IntroAccounts,
                    'To establish a new owned account, simply input a name and description. Doing so will enable you to maintain a distinct portfolio with individualized financial statements, as well as separate wallets and sub-accounts on centralized exchanges.<br /><br />Please be aware that this feature operates on a paid basis, with monthly or annual subscription options available.',
                ),
                element: document.querySelector('#ownedAccountsList'),
                position: 'right',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Trading Accounts'),
                intro: getStepIntro(
                    'Account Settings',
                    IntroAccounts,
                    'Your default account, provided at no cost, can be found here. For personalization, you can modify the description and choose a color by selecting the gear icon associated with any of your accounts. Once you\'ve set up additional custom accounts, naming options will also be available for each.',
                ),
                element: document.querySelector('#ownedAccountsList'),
                position: 'right',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Trading Accounts'),
                intro: getStepIntro(
                    'Manage Access',
                    IntroAccounts,
                    'This feature enables you to delegate management rights to your account, either in full or selectively based on your preference, to other users on the Single Broker trading platform. By doing so, you can entrust your account\'s management to professional portfolio managers, investment funds, or expert traders, leveraging their expertise for your benefit.',
                ),
                element: document.querySelector('#ownedAccountsList'),
                position: 'right',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Trading Accounts'),
                intro: getStepIntro(
                    'Managed Accounts Section',
                    IntroAccounts,
                    'Within this section, you can view any trading accounts for which you\'ve been designated as a manager. If a Single Broker platform user has provided you with access to their personal trading account, and you\'ve accepted this management role, these accounts will be listed here for your reference.',
                ),
                element: document.querySelector('#managedAccountsList'),
                position: 'right',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Trading Accounts'),
                intro: getStepIntro(
                    'Current Trading Account',
                    IntroAccounts,
                    'It\'s crucial to note that you are always operating from a specific trading account, highlighted with a check mark. Depending on your needs, you can easily toggle between active trading accounts across various tabs of the trading platform, allowing you to trade digital assets, review portfolio analytics, handle funds, and conduct other related tasks.',
                ),
                element: document.querySelector('#ownedAccountsList'),
                position: 'right',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Wallets'),
                intro: getStepIntro(
                    'Funds Management',
                    IntroWallet,
                    'As you delve deeper into the Single Broker platform, understanding how to effectively manage your funds becomes paramount. The Wallets tab is dedicated to this very purpose, offering a consolidated view of all your assets and tools for efficient fund allocation.<br /><br />Whether you\'re depositing, withdrawing, or simply keeping an eye on your balance, this section provides a comprehensive overview of your financial landscape, ensuring you\'re always informed and in control.',
                ),
                element: document.querySelector('#headerLinkWallets'),
                position: 'bottom',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Wallets'),
                intro: getStepIntro(
                    'Module Allocation',
                    IntroWallet,
                    'The Allocation module empowers you to seamlessly transfer funds among various sites, such as shuttling assets from a custodial vault to a centralized exchange and vice versa.<br /><br />For added flexibility, we\'ve incorporated a feature allowing transfers from a single source to multiple destinations, and conversely, consolidating funds from several sources to one destination. This capability can be accessed using the small button adjacent to the "Allocate" option.',
                ),
                element: document.querySelector('#transferModule'),
                position: getTooltipPositionByTargetElement(grids[gridPages.WALLETS].lg, 'Transfer'),
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Wallets', true),
                intro: getStepIntro(
                    'Transfer History',
                    IntroWallet,
                    'The Transfer History module provides a comprehensive log of your fund activities. Here, you can track the flow of your assets between trading platforms, transfers among trading accounts, and monitor both your deposits and withdrawals to maintain a clear financial overview.',
                ),
                element: document.querySelector('#transferHistoryModule'),
                position: getTooltipPositionByTargetElement(grids[gridPages.WALLETS].lg, 'TransferHistory'),
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Wallets'),
                intro: getStepIntro(
                    'Module Funding',
                    IntroWallet,
                    'The Funding Module is tailored for handling both deposits and withdrawals. On the Single Broker trading platform, you can seamlessly transact in both digital assets and traditional currencies through bank payments.<br /><br />For compliance with regulatory standards, please note that we only facilitate transactions with client digital wallets that have been whitelisted. Ensure your wallet is approved to streamline your funding activities.',
                ),
                element: document.querySelector('#paymentModule'),
                position: getTooltipPositionByTargetElement(grids[gridPages.WALLETS].lg, 'Payment'),
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Wallets'),
                intro: getStepIntro(
                    'Wallet Whitelisting',
                    IntroWallet,
                    'To whitelist your external wallet, start by selecting the appropriate cryptocurrency and its associated network. If you haven\'t added any wallets to the whitelist yet, the Address section will display "NO WHITELISTED ADDRESS".<br /><br />To begin the addition process, expand the dropdown menu, select "Add New Wallet", and follow the subsequent steps. It\'s important to highlight that wallet whitelisting is confirmed using a Satoshi test in the native token of the selected network. While you\'ll deposit your chosen amount, our system will append a minuscule value to this transfer for verification purposes. Rest assured, the entire sum, inclusive of the Satoshi test amount, will be credited to your account.',
                ),
                element: document.querySelector('#addressesWithdraw') ?? document.querySelector('#addressesDeposit'),
                position: getTooltipPositionByTargetElement(grids[gridPages.WALLETS].lg, 'Payment'),
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Wallets'),
                intro: getStepIntro(
                    'Aggregated Balance Module',
                    IntroWallet,
                    'The Aggregated Balance Module provides a comprehensive view of all your balances for the active, selected trading account, spanning across various fiat and cryptocurrencies on different platforms.<br /><br />You can customize the display based on your preference, sorting either by currency or by exchange. This ensures that vital data about the balance and fund distribution of your chosen trading account is always within reach, further enriched by accompanying infographics.',
                ),
                element: document.querySelector('#aggregatedBalancesModule'),
                position: getTooltipPositionByTargetElement(grids[gridPages.WALLETS].lg, 'AggregatedBalance'),
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Terminal'),
                intro: getStepIntro(
                    'Trading and Investing',
                    IntroTrading,
                    'Within the Single Broker platform, the realm of Trading & Investing is broad and multifaceted. Across various dedicated tabs, such as the Terminal, DeFi, Earnings, and others, you\'ll encounter the platform\'s expansive trading and investment functionalities.<br /><br />Each tab is specialized, catering to different aspects of your trading and investing journey. Here, we\'ll provide an in-depth exploration of these pages, shedding light on their features and tools, ensuring you have a comprehensive understanding of the platform\'s capabilities. This knowledge will enable you to effectively navigate and utilize the platform to its full potential.',
                ),
                element: document.querySelector('#headerLinkTerminal'),
                position: 'bottom',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Terminal'),
                intro: getStepIntro(
                    'Terminal',
                    IntroTrading,
                    'The Terminal tab stands as the gateway to centralized exchanges, offering seamless integration and direct market access. Both spot and futures trading are facilitated here. At your disposal is a menu for choosing both the exchange and its associated trading instrument.<br /><br />Once you make a selection, the platform dynamically updates to provide detailed information related to the chosen trading instrument (pair), market data, as well as the ability to place and manage trade orders and their execution.',
                ),
                element: document.querySelector('#statusRow'),
                position: 'bottom',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Terminal'),
                intro: getStepIntro(
                    'Market Data',
                    IntroTrading,
                    'Within these modules, you\'ll find both L1 and L2 tiers of market data corresponding to your selected trading pair. The platform integrates the robust capabilities of the TradingView technical analysis module, offering you advanced charting tools and insights.<br /><br />In addition, you can delve into the depth of the order book, utilize price level accumulation features, and monitor a live list of recent trades to inform your trading decisions.',
                ),
                element: document.querySelector('#terminalPriceChart'),
                position: getTooltipPositionByTargetElement(grids[gridPages.TERMINAL][breakpoints[gridPages.TERMINAL]], 'PriceChart'),
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Terminal'),
                intro: getStepIntro(
                    'Quick Balance',
                    IntroTrading,
                    'The Quick Balances module is designed for optimal user convenience. It provides an immediate overview of where and in which currencies your funds are allocated across various sites. This ensures that you have a clear financial snapshot at a glance, eliminating the need to navigate to the wallets section for this information.',
                ),
                element: document.querySelector('#quickBalanceModule'),
                position: getTooltipPositionByTargetElement(grids[gridPages.TERMINAL][breakpoints[gridPages.TERMINAL]], 'AccountPortfolio'),
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Terminal'),
                intro: getStepIntro(
                    'Module Place Order',
                    IntroTrading,
                    'The Module Place Order is dedicated to facilitating the execution of trade orders based on your chosen trading pair. Within this module, you can determine the trade direction — whether it\'s a buy or sell for spot trading, or open/close positions (long or short) for futures trading.<br /><br />Additionally, you can specify the order type, its expiration, and any other pertinent criteria for your trade order.',
                ),
                element: document.querySelector('#tradingModule'),
                position: getTooltipPositionByTargetElement(grids[gridPages.TERMINAL][breakpoints[gridPages.TERMINAL]], 'Trading'),
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Terminal'),
                intro: getStepIntro(
                    'Orders, Trades and Positions Management',
                    IntroTrading,
                    'This module is your control center for managing active orders across all integrated platforms. It provides a comprehensive history of past orders, along with a detailed breakdown of executed trades.<br /><br />By using the provided switch, you can seamlessly toggle between views, enabling you to oversee and manage orders, trades, and positions specifically for futures trading.',
                ),
                element: document.querySelector('#ordersHistoryModule'),
                position: getTooltipPositionByTargetElement(grids[gridPages.TERMINAL][breakpoints[gridPages.TERMINAL]], 'Orders'),
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('DeFi'),
                intro: getStepIntro(
                    'DeFi Tools',
                    IntroDeFi,
                    'The DeFi Tools section is tailored for engagement with decentralized finance platforms. Here, you can execute asset swaps directly from custodial storage, bypassing the need for external wallet connections.<br /><br />Additionally, you\'re equipped to manage liquidity in the integrated dex liquidity pools. We\'ve streamlined these processes to ensure a seamless and efficient experience for you.',
                ),
                element: document.querySelector('#headerLinkDeFi'),
                position: 'bottom',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('DeFi'),
                intro: getStepIntro(
                    'Exchange Selection',
                    IntroDeFi,
                    'Here, you\'ll begin your decentralized trading journey by selecting your preferred decentralized exchange (DEX) and network. This is where your personalized trading environment starts to take shape.<br /><br />Choose from prominent DEXs and blockchain networks to find the liquidity and assets that match your trading strategies. Once selected, the Single Broker platform will dynamically update all subsequent modules to reflect the exchange\'s data, ensuring a streamlined and coherent trading experience.',
                ),
                element: document.querySelector('#defiChooseTool'),
                position: 'bottom',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('DeFi'),
                intro: getStepIntro(
                    'Swap Module',
                    IntroDeFi,
                    'The Swap module is your gateway to instantaneous asset exchanges. Utilizing the liquidity pools of your chosen DEX, this feature offers a transparent and efficient way to swap one digital asset for another.<br /><br />The Single Broker platform facilitates transparent interactions with DeFi apps, necessitating user authorization for exchange transactions from their custodial wallet. These transactions typically incur a nominal fee on the blockchain network. It is important to note that all actions on the network demand payment of transaction fees (often referred to as "gas") using a native token specific to the blockchain, such as ETH for the Ethereum network.',
                ),
                element: document.querySelector('#swap'),
                position: getTooltipPositionByTargetElement(grids[gridPages.DEFI][breakpoints[gridPages.DEFI]], 'DeFiInputForm'),
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('DeFi'),
                intro: getStepIntro(
                    'Swap History Module',
                    IntroDeFi,
                    'The Swap History module, at the upper right corner, is the record keeper of your DeFi interactions. It provides a comprehensive log of your approvals, swaps, and liquidity provisioning or withdrawals across all integrated decentralized platforms.<br /><br />Every transaction is meticulously documented, complete with status updates, ensuring you have complete oversight. Click on any transaction for an in-depth view, keeping track of your blockchain endeavors with precision.',
                ),
                element: document.querySelector('#swapsHistoryModule'),
                position: getTooltipPositionByTargetElement(grids[gridPages.DEFI][breakpoints[gridPages.DEFI]], 'DeFiTable'),
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('DeFi'),
                intro: getStepIntro(
                    'Liquidity Pools Module',
                    IntroDeFi,
                    'The Liquidity Pools module showcases a vast array of pools along with vital statistics such as pool size, volume, and potential returns. This is where you can contribute to the DeFi ecosystem by adding liquidity or retrieve your assets through withdrawals.<br /><br />Sort through the columns to pinpoint the pools that align with your goals, and utilize the "Action" column to interact directly with the liquidity of your choice.',
                ),
                element: document.querySelector('#liquidityPoolsModule'),
                position: getTooltipPositionByTargetElement(grids[gridPages.DEFI][breakpoints[gridPages.DEFI]], 'LiquidityPoolsTable'),
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('DeFi'),
                intro: getStepIntro(
                    'Quick Balances Module',
                    IntroDeFi,
                    'The Quick Balances module is an auxiliary tool designed for the efficient analysis and oversight of a user\'s digital asset holdings within the Single Broker platform.<br /><br />This feature conveniently displays current balances, negating the necessity to navigate to a separate Wallets tab for balance inquiries.',
                ),
                element: document.querySelector('#defiQuickBalancesModule'),
                position: getTooltipPositionByTargetElement(grids[gridPages.DEFI][breakpoints[gridPages.DEFI]], 'DeFiQuickBalances'),
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('DeFi'),
                intro: getStepIntro(
                    'LP Positions Module',
                    IntroDeFi,
                    'For an in-depth look at your liquidity provision endeavors, the LP Positions module is the go-to place. It displays your active liquidity positions and their performance metrics, including returns and the status of your investments.<br /><br />Keep track of how your contributions to various liquidity pools are faring in real-time and make adjustments as needed to align with your financial strategies.',
                ),
                element: document.querySelector('#defiLpPositions'),
                position: getTooltipPositionByTargetElement(grids[gridPages.DEFI][breakpoints[gridPages.DEFI]], 'DeFiPoolsPositions'),
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Portfolio'),
                intro: getStepIntro(
                    'Portfolio Analytics',
                    IntroPortfolio,
                    'To begin exploring the Portfolio Analytics section within the Portfolio tab, it’s important to understand its function as a comprehensive dashboard providing a snapshot of your trading activity and performance metrics.<br /><br />This section is designed to deliver a high-level overview of your portfolio, offering insights into various aspects of your trading behavior and the results of your investment strategies.',
                ),
                element: document.querySelector('#headerLinkPortfolio'),
                position: 'bottom',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Portfolio'),
                intro: getStepIntro(
                    'Time Frame Selection',
                    IntroPortfolio,
                    'The Portfolio tab enables the setting of custom time frames for a focused analysis of trading performance. Select date ranges to examine trends in your financial activities.<br /><br />Key indicators at the top provide a concise overview of your account, including total value, daily profit and loss (P&L), period P&L, and unrealized P&L on open positions. These metrics are essential for monitoring your portfolio\'s short-term activity and overall progress.',
                ),
                element: document.querySelector('#portfolioChooseDate'),
                position: 'bottom',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Portfolio', true),
                intro: getStepIntro(
                    'Segregated and Aggregated Analytics',
                    IntroPortfolio,
                    'The portfolio analytics initially reflect the activity of a selected individual trading account. For a consolidated view of your financial landscape, you can adjust settings to aggregate data from all owned or managed accounts, enabling a comprehensive analysis of your total trading activity.',
                ),
                element: document.querySelector('#segregatedAndAggregatedAnalyticsButtons'),
                position: 'left',
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Portfolio'),
                intro: getStepIntro(
                    'Charts',
                    IntroPortfolio,
                    'The Charts section of the Portfolio tab offers a suite of graphical representations that provide insights into various aspects of your trading activities. Through these charts, you can easily understand the composition and performance of your portfolio, track your trading patterns, and assess the distribution of your assets.',
                ),
                element: document.querySelector('#balanceByCurrencyModule'),
                position: getTooltipPositionByTargetElement(grids[gridPages.PORTFOLIOS][breakpoints[gridPages.PORTFOLIOS]], 'BalanceByCurrency'),
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Portfolio'),
                intro: getStepIntro(
                    'Charts',
                    IntroPortfolio,
                    'Current Balance Chart: Visualizes your portfolio\'s asset distribution, allowing for a quick assessment of your financial positioning.<br /><br />Current Allocation Chart: Shows how your investments are spread across different trading platforms, indicating diversification or concentration in specific venues.<br /><br />Asset Trades Chart: Details your transaction history with different trading pairs, highlighting your most traded instruments.<br /><br />Exchange Trades Chart: Maps out the trading volume and activity across different exchanges, offering a view of where your trading is most concentrated.',
                ),
                element: document.querySelector('#balanceByCurrencyModule'),
                position: getTooltipPositionByTargetElement(grids[gridPages.PORTFOLIOS][breakpoints[gridPages.PORTFOLIOS]], 'BalanceByCurrency'),
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Portfolio'),
                intro: getStepIntro(
                    'Balance History',
                    IntroPortfolio,
                    'The Balance History graph offers a visual representation of the account\'s balance variations, where you can choose to display data for a single trading account or combine data across all owned or managed accounts.<br /><br />This selection tailors the view to provide insights into either segregated or aggregated financial movements. Additionally, the graph allows for the specific selection of assets, providing a focused analysis of each asset\'s performance within the chosen time frame.',
                ),
                element: document.querySelector('#balanceHistoryModule'),
                position: getTooltipPositionByTargetElement(grids[gridPages.PORTFOLIOS][breakpoints[gridPages.PORTFOLIOS]], 'BalanceByPeriod'),
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Portfolio'),
                intro: getStepIntro(
                    'P&L History',
                    IntroPortfolio,
                    'This part of the dashboard shows both cumulative and daily profit and loss. The combination of a line graph for cumulative P&L with a bar graph for daily P&L allows for a nuanced analysis of your trading performance, offering clues on both the short-term outcomes and the long-term trajectory of your investment strategy.',
                ),
                element: document.querySelector('#pnlHistoryModule') ?? undefined,
                position: getTooltipPositionByTargetElement(grids[gridPages.PORTFOLIOS][breakpoints[gridPages.PORTFOLIOS]], 'PNLChart'),
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Portfolio'),
                intro: getStepIntro(
                    'Aggregated Balance',
                    IntroPortfolio,
                    'The Aggregated Balance feature presents a detailed table enumerating all assets, with options to organize the view by individual assets or by exchange.<br /><br />It displays current balances, available funds, holdings, and the value in a default currency, accompanied by a visual representation of asset distribution. This function aids in providing a clear and current view of the portfolio\'s structure and the performance of each asset.',
                ),
                element: document.querySelector('#portfolioAggregatedBalanceModule') ?? undefined,
                position: getTooltipPositionByTargetElement(grids[gridPages.PORTFOLIOS][breakpoints[gridPages.PORTFOLIOS]], 'AggregatedBalance'),
                scrollTo: 'tooltip',
            },
            {
                title: getStepTitle('Congratulations'),
                intro: getStepIntro(
                    'We Wish You Profitable Trading',
                    IntroWelcome,
                    'That concludes our onboarding training for our cryptocurrency trading platform. We hope you find our platform user-friendly and efficient in executing your trades.<br /><br />If you have any questions or require further assistance, our customer support team is available 24/7 to assist you. Thank you for choosing our platform!',
                ),
                position: 'floating',
                scrollTo: 'tooltip',
            },
        ]),
    ],
    hasEscListener: false,
};

export type IntroState = typeof state;

export enum IntroActions {
    startIntroByStep = 'startIntroByStep',
}

export const startIntroByStep = actionCreator<number>('Intro', IntroActions.startIntroByStep);

const actions: Record<IntroActions, (Action<IntroState, any>)> = {
    startIntroByStep({ state, dispatch, rootState }, { payload: step }: ReturnType<typeof startIntroByStep>) {
        const isKycVerified = rootState.User.currentUser?.kycStatus === 'Verified';
        const intro = introJs();

        const listenEsc = async (e) => {
            if (e.key === 'Escape') {
                await intro.exit(true);
            }
        };

        if (!state.hasEscListener) {
            document.addEventListener('keydown', listenEsc);
            state.hasEscListener = true;
        }

        intro.setOptions({
            tooltipClass: 'introTooltip',
            steps: state.intro[0](rootState.Interface.pageGrids, rootState.Interface.activeLayoutBreakpoints) as Partial<IntroStep>[],
            showButtons: false,
            exitOnOverlayClick: false,
            exitOnEsc: false,
            showBullets: false,
            keyboardNavigation: false,
            autoPosition: false,
            scrollTo: 'tooltip',
        });

        intro.onexit(async () => {
            (document.querySelector('#mainHeader') as HTMLElement).classList.remove('headerOpacity');
            (document.querySelector('html') as HTMLElement).style.height = '100%';
            (document.querySelector('body') as HTMLElement).style.height = '100%';
            (document.querySelector('main') as HTMLElement).style.height = '100%';
            (document.querySelector('#app') as HTMLElement).style.height = '100%';
            await dispatch('User/getCurrentUser', undefined, { root: true });
        });
        intro.onstart(() => {
            (document.querySelector('#mainHeader') as HTMLElement).classList.add('headerOpacity');
            (document.querySelector('html') as HTMLElement).style.height = 'unset';
            (document.querySelector('body') as HTMLElement).style.height = 'unset';
            (document.querySelector('main') as HTMLElement).style.height = 'unset';
            (document.querySelector('#app') as HTMLElement).style.height = 'unset';
        });

        intro.onchange(async () => {
            const step = intro.currentStep();
            if (step === -1) {
                return;
            }

            const handleChangeAfterTimeout = () => {
                setTimeout(async () => {
                    // Make help layer visible for header steps
                    if (
                        (step >= 15 && step <= 17)
                        || step === 6
                        || step === 11
                        || step === 25
                        || step === 31
                        || step === 37
                        || step === 44
                    ) {
                        if (document.getElementsByClassName('introjs-helperLayer').length > 0) {
                            (document.getElementsByClassName('introjs-helperLayer')[0] as any).style.zIndex = 99999999;
                            (document.querySelector('#mainHeader') as HTMLElement).classList.remove('headerOpacity');
                        }
                    } else if (document.getElementsByClassName('introjs-helperLayer').length > 0) {
                        (document.getElementsByClassName('introjs-helperLayer')![0] as any).style.zIndex = 9999997;
                        (document.querySelector('#mainHeader') as HTMLElement).classList.add('headerOpacity');
                    }

                    if (isKycVerified && step < 9 && step !== -1) {
                        await intro.exit(true);
                        await dispatch(startIntroByStep(10, true));
                        return;
                    }

                    if (document.querySelector('.intro-side-menu')) {
                        if (!rootState.User.currentUser?.onboardingCompleted) {
                            document.querySelector('.intro-side-menu')!.classList.remove('active-left');
                            document.querySelector('.intro-side-menu')!.classList.remove('active');
                        }
                    }

                    if (step >= 9 && !rootState.User.currentUser?.onboardingCompleted) {
                        if (document.querySelector('.sub-row')) {
                            document.querySelector('.sub-row')!.classList.add('visible');
                        }
                    } else if (document.querySelector('.sub-row')) {
                        document.querySelector('.sub-row')!.classList.remove('visible');
                    }

                    if (step >= 9) {
                        document.querySelector('#sideMenuTrigger')!.classList.remove('hidden');
                    } else {
                        document.querySelector('#sideMenuTrigger')!.classList.add('hidden');
                    }

                    if (!rootState.User.currentUser?.onboardingCompleted) {
                        document.querySelector('#IntroCloseButton')!.classList.add('hidden');
                    }

                    if (step === 52) {
                        const nextButton = document.querySelector('#IntroNextButton');
                        if (nextButton) {
                            nextButton.innerHTML = 'FINISH';
                        }
                    } else {
                        const nextButton = document.querySelector('#IntroNextButton');
                        if (nextButton) {
                            nextButton.innerHTML = 'NEXT';
                        }
                    }

                    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
                    if (isSafari) {
                        fixSafariView();
                    }
                }, 300);
            };

            if (rootState.User.currentUser.onboardingStatus !== undefined && rootState.User.currentUser.onboardingStatus < step && !rootState.User.currentUser.onboardingCompleted) {
                try {
                    await UserApi.saveOnboardingStatus(new OnboardingPayload({
                        newSequenceNumber: step === 52 ? 9 : step,
                        completed: step === 52,
                    }));
                } catch (error) {
                    if (error instanceof ApiError) {
                        await dispatch(
                            'Notificator/showErrorNotification',
                            error.data?.message ?? 'Error during updating onboarding status',
                            { root: true },
                        );
                    }
                } finally {
                    handleChangeAfterTimeout();
                }
            } else {
                handleChangeAfterTimeout();
            }
        });

        intro.onbeforechange(async () => {
            const step = intro.currentStep();
            if (step >= 26 && step <= 31) {
                if (router.currentRoute.name !== 'Wallets') {
                    await intro.exit(true);
                    await router.push('/wallets').catch(() => { /* navigation error */ });
                    await dispatch(startIntroByStep(step + 1, true));
                }
            } else if (step >= 32 && step <= 37) {
                if (router.currentRoute.name !== 'Terminal') {
                    await intro.exit(true);
                    await router.push('/terminal').catch(() => { /* navigation error */ });
                    await dispatch(startIntroByStep(step + 1, true));
                }
            } else if (step >= 38 && step <= 44) {
                if (router.currentRoute.name !== 'DeFi') {
                    await intro.exit(true);
                    await router.push('/defi').catch(() => { /* navigation error */ });
                    await dispatch(startIntroByStep(step + 1, true));
                }
            } else if (step >= 45) {
                if (router.currentRoute.name !== 'Portfolio') {
                    await intro.exit(true);
                    await router.push('/portfolio').catch(() => { /* navigation error */ });
                    await dispatch(startIntroByStep(step + 1, true));
                }
            }

            return true;
        });

        const managedAccountsList = document.querySelector('#managedAccountsList');
        if (managedAccountsList && !managedAccountsList.classList.contains('active')) {
            document.getElementById('showManagedAccountsButton')!.click();
        }
        const ownedAccountsList = document.querySelector('#ownedAccountsList');
        if (ownedAccountsList && !ownedAccountsList.classList.contains('active')) {
            document.getElementById('showOwnedAccountsButton')!.click();
        }
        const statusRow = document.querySelector('#statusRow');
        if (statusRow && !statusRow.classList.contains('open')) {
            document.getElementById('toggleStatusRowButton')!.click();
        }

        // скрываем модалку онбординга, на время стабилизации позиционирования, чтобы убрать пролаг
        setTimeout(() => {
            const elem = document.querySelector('.introTooltip');
            if (elem) {
                elem.classList.add('hidden');
            }
        }, 0);

        setTimeout(async () => {
            await intro.goToStepNumber(step);
            await intro.start();

            const buttonsHtml = `
            <div id="onboardingButtonsRow" class="buttons-row">
                <div class="sub-row">
                    <span id="IntroSkipLink" class="introjs-linkbutton">
                        Skip
                    </span>
                    <span id="IntroLaterLink" class="introjs-linkbutton">
                        Later
                    </span>
                </div>
                <div class="buttons-sub-row">
                    <button id="IntroCloseButton" class="introjs-closebutton">
                        Close
                    </button>
                    <button id="IntroNextButton" class="introjs-nextbutton">
                        Next
                    </button>
                </div>
            </div>
            `;
            const buttonsElement = document.createElement('div');
            buttonsElement.innerHTML = buttonsHtml;

            const sideMenuHtml = `
            <div class="side-header">
                <span>
                    Topics List
                </span>
                <svg
                    onclick="document.querySelector('.intro-side-menu').classList.remove('active'); document.querySelector('.intro-side-menu').classList.remove('active-left')"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                >
                    <path d="M18 5.99997C17.8124 5.8125 17.5581 5.70718 17.293 5.70718C17.0278 5.70718 16.7735 5.8125 16.586 5.99997L12 10.586L7.41397 5.99997C7.22644 5.8125 6.97213 5.70718 6.70697 5.70718C6.44181 5.70718 6.1875 5.8125 5.99997 5.99997C5.8125 6.1875 5.70718 6.44181 5.70718 6.70697C5.70718 6.97213 5.8125 7.22644 5.99997 7.41397L10.586 12L5.99997 16.586C5.8125 16.7735 5.70718 17.0278 5.70718 17.293C5.70718 17.5581 5.8125 17.8124 5.99997 18C6.1875 18.1874 6.44181 18.2928 6.70697 18.2928C6.97213 18.2928 7.22644 18.1874 7.41397 18L12 13.414L16.586 18C16.7735 18.1874 17.0278 18.2928 17.293 18.2928C17.5581 18.2928 17.8124 18.1874 18 18C18.1874 17.8124 18.2928 17.5581 18.2928 17.293C18.2928 17.0278 18.1874 16.7735 18 16.586L13.414 12L18 7.41397C18.1874 7.22644 18.2928 6.97213 18.2928 6.70697C18.2928 6.44181 18.1874 6.1875 18 5.99997Z" />
                </svg>
            </div>
            <div class="side-topics-list">
                <div onclick="document.getElementById('subList1').classList.toggle('shown')" class="side-list-item">
                    <span>
                        User Interface
                    </span>
                    <svg viewBox="0 0 10 6" fill="none" stroke="currentColor">
                        <path opacity="0.5" d="M9.00003 1.09998L5.05635 5.09998L1 1.09998" />
                    </svg>
                </div>
                <div id="subList1" class="side-list-item-sub-list">
                    <span data-step="10" class="side-list-item-sub-list-item">
                        Platform Interface
                    </span>
                    <span data-step="11" class="side-list-item-sub-list-item">
                        Navigation Bar
                    </span>
                    <span data-step="12" class="side-list-item-sub-list-item">
                        Trading Accounts
                    </span>
                    <span data-step="13" class="side-list-item-sub-list-item">
                        Screen Workspace
                    </span>
                    <span data-step="14" class="side-list-item-sub-list-item">
                        User Interface Interactions
                    </span>
                    <span data-step="15" class="side-list-item-sub-list-item">
                        Workspace Layouts
                    </span>
                    <span data-step="16" class="side-list-item-sub-list-item">
                        Quick Support
                    </span>
                    <span data-step="17" class="side-list-item-sub-list-item">
                        Onboarding
                    </span>
                </div>
                <div onclick="document.getElementById('subList2').classList.toggle('shown')" class="side-list-item">
                    <span>
                        Trading Accounts Management
                    </span>
                    <svg viewBox="0 0 10 6" fill="none" stroke="currentColor">
                        <path opacity="0.5" d="M9.00003 1.09998L5.05635 5.09998L1 1.09998" />
                    </svg>
                </div>
                <div id="subList2" class="side-list-item-sub-list">
                    <span data-step="18" class="side-list-item-sub-list-item">
                        Trading Accounts Management
                    </span>
                    <span data-step="19" class="side-list-item-sub-list-item">
                        Owned Accounts Section
                    </span>
                    <span data-step="20" class="side-list-item-sub-list-item">
                        Add New Account
                    </span>
                    <span data-step="21" class="side-list-item-sub-list-item">
                        Account Settings
                    </span>
                    <span data-step="22" class="side-list-item-sub-list-item">
                        Manage Access
                    </span>
                    <span data-step="23" class="side-list-item-sub-list-item">
                        Managed Accounts Section
                    </span>
                    <span data-step="24" class="side-list-item-sub-list-item">
                        Current Trading Account
                    </span>
                </div>
                <div onclick="document.getElementById('subList3').classList.toggle('shown')" class="side-list-item">
                    <span>
                        Funds Management
                    </span>
                    <svg viewBox="0 0 10 6" fill="none" stroke="currentColor">
                        <path opacity="0.5" d="M9.00003 1.09998L5.05635 5.09998L1 1.09998" />
                    </svg>
                </div>
                <div id="subList3" class="side-list-item-sub-list">
                    <span data-step="25" class="side-list-item-sub-list-item">
                        Funds Management
                    </span>
                    <span data-step="26" class="side-list-item-sub-list-item">
                        Module Allocation
                    </span>
                    <span data-step="27" class="side-list-item-sub-list-item">
                        Transfer History
                    </span>
                    <span data-step="28" class="side-list-item-sub-list-item">
                        Module Funding
                    </span>
                    <span data-step="29" class="side-list-item-sub-list-item">
                        Wallet Whitelisting
                    </span>
                    <span data-step="30" class="side-list-item-sub-list-item">
                        Aggregated Balance Module
                    </span>
                </div>
                <div onclick="document.getElementById('subList4').classList.toggle('shown')" class="side-list-item">
                    <span>
                        Trading & Investing
                    </span>
                    <svg viewBox="0 0 10 6" fill="none" stroke="currentColor">
                        <path opacity="0.5" d="M9.00003 1.09998L5.05635 5.09998L1 1.09998" />
                    </svg>
                </div>
                <div id="subList4" class="side-list-item-sub-list">
                    <span data-step="31" class="side-list-item-sub-list-item">
                        Trading and Investing
                    </span>
                    <span data-step="32" class="side-list-item-sub-list-item">
                        Terminal
                    </span>
                    <span data-step="33" class="side-list-item-sub-list-item">
                        Market Data
                    </span>
                    <span data-step="34" class="side-list-item-sub-list-item">
                        Quick Balance
                    </span>
                    <span data-step="35" class="side-list-item-sub-list-item">
                        Module Place Order
                    </span>
                    <span data-step="36" class="side-list-item-sub-list-item">
                        Orders, Trades and Positions Management
                    </span>
                </div>
                <div onclick="document.getElementById('subList5').classList.toggle('shown')" class="side-list-item">
                    <span>
                        Decentralized Finances
                    </span>
                    <svg viewBox="0 0 10 6" fill="none" stroke="currentColor">
                        <path opacity="0.5" d="M9.00003 1.09998L5.05635 5.09998L1 1.09998" />
                    </svg>
                </div>
                <div id="subList5" class="side-list-item-sub-list">
                    <span data-step="37" class="side-list-item-sub-list-item">
                        DeFi Tools
                    </span>
                    <span data-step="38" class="side-list-item-sub-list-item">
                        Exchange Selection
                    </span>
                    <span data-step="39" class="side-list-item-sub-list-item">
                        Swap Module
                    </span>
                    <span data-step="40" class="side-list-item-sub-list-item">
                        Swap History Module
                    </span>
                    <span data-step="41" class="side-list-item-sub-list-item">
                        Liquidity Pools Module
                    </span>
                    <span data-step="42" class="side-list-item-sub-list-item">
                        Quick Balances Module
                    </span>
                    <span data-step="43" class="side-list-item-sub-list-item">
                        LP Positions Module
                    </span>
                </div>
                <div onclick="document.getElementById('subList6').classList.toggle('shown')" class="side-list-item">
                    <span>
                        Portfolio Analytics
                    </span>
                    <svg viewBox="0 0 10 6" fill="none" stroke="currentColor">
                        <path opacity="0.5" d="M9.00003 1.09998L5.05635 5.09998L1 1.09998" />
                    </svg>
                </div>
                <div id="subList6" class="side-list-item-sub-list">
                    <span data-step="44" class="side-list-item-sub-list-item">
                        Portfolio Analytics
                    </span>
                    <span data-step="45" class="side-list-item-sub-list-item">
                        Time Frame Selection
                    </span>
                    <span data-step="46" class="side-list-item-sub-list-item">
                        Segregated and Aggregated Analytics
                    </span>
                    <span data-step="47" class="side-list-item-sub-list-item">
                        Charts Part 1
                    </span>
                    <span data-step="48" class="side-list-item-sub-list-item">
                        Charts Part 2
                    </span>
                    <span data-step="49" class="side-list-item-sub-list-item">
                        Balance History
                    </span>
                    <span data-step="50" class="side-list-item-sub-list-item">
                        P&L History
                    </span>
                    <span data-step="51" class="side-list-item-sub-list-item">
                        Aggregated Balance
                    </span>
                </div>
            </div>
            `;
            const sideMenu = document.createElement('div');
            sideMenu.classList.add('intro-side-menu');
            if (rootState.User.currentUser?.onboardingCompleted) {
                sideMenu.classList.add('active');
            }
            sideMenu.innerHTML = sideMenuHtml;

            if (document.querySelector('.introjs-tooltip')
                && !document.querySelector('.introjs-tooltip')?.querySelector('#onboardingButtonsRow')) {
                document.querySelector('.introjs-tooltip')!.appendChild(buttonsElement);
            }

            if (document.querySelector('.introTooltip')) {
                document.querySelector('.introTooltip')!.appendChild(sideMenu);
            }

            if (document.querySelector('#IntroNextButton')) {
                document.querySelector('#IntroNextButton')!.addEventListener('click', () => {
                    if (rootState.User.currentUser?.onboardingCompleted && intro._currentStep === 51) {
                        intro.exit(true);
                        return;
                    }
                    intro.nextStep();
                });
            }
            if (document.querySelector('#IntroLaterLink')) {
                document.querySelector('#IntroLaterLink')!.addEventListener('click', async () => {
                    await intro.exit(true);
                });
            }
            if (document.querySelector('#IntroCloseButton')) {
                document.querySelector('#IntroCloseButton')!.addEventListener('click', async () => {
                    await intro.exit(true);
                });
            }
            if (document.querySelector('#IntroSkipLink')) {
                document.querySelector('#IntroSkipLink')!.addEventListener('click', async () => {
                    try {
                        await UserApi.saveOnboardingStatus(new OnboardingPayload({
                            completed: true,
                            newSequenceNumber: 9,
                        }));
                        await intro.exit(true);
                    } catch (error) {
                        if (error instanceof ApiError) {
                            await dispatch(
                                'Notificator/showErrorNotification',
                                error.data?.message ?? 'Error during updating onboarding status',
                                { root: true },
                            );
                        }
                    }
                });
            }

            Array.from(document.querySelectorAll('.side-list-item-sub-list-item')).forEach((el) => {
                el.addEventListener('click', async () => {
                    await intro.exit(true);
                    await dispatch(startIntroByStep(Number(el.attributes['data-step'].value) + 1, true));
                });
            });
            const elem = document.querySelector('.introTooltip');
            if (elem) {
                elem.classList.remove('hidden');
            }
        }, 1000);
    },
};

export default {
    namespaced: true,
    actions,
    state,
};


import Vue from 'vue';
import { mapGetters } from 'vuex';

import AccountColorMarker from 'Common/AccountColorMarker.vue';
import { copyTextWithPermissions } from 'Lib/utils/copyTextWithPermissions';
import AccountsApi from 'Apis/Accounts';
import UpdateAccountPayload, { IUpdateAccountPayload } from 'Entities/accountManagement/UpdateAccountPayload';
import ApiError from 'Entities/ApiError';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import BaseAccountSharingPayload from 'Entities/accountManagement/BaseAccountSharingPayload';
import Icon from 'UI/Icon.vue';
import TextInput from 'Control/TextInput.vue';
import Button from 'Control/Button.vue';

export default Vue.extend<any, any, any, any>({
    name: 'AccountSettingsPopover',
    components: {
        AccountColorMarker,
        Icon,
        TextInput,
        Button,
    },
    props: {
        account: {
            type: Object,
            required: true,
        },
        hidePopover: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            isEditing: false,
            accountName: '',
            accountDescription: '',
            newActiveAccountColor: '',
            isAccessRejecting: false,
            errors: {
                accountName: false,
                accountDescription: false,
            },
            nameRegexp: /^[a-zA-Z0-9_]{3,15}$/,
            // eslint-disable-next-line no-control-regex
            descriptionRegexp: /^(?:[^\0-\u001f]){0,200}$/,
        };
    },
    computed: {
        ...mapGetters({
            isThemeDark: 'isThemeDark',
            accounts: 'Accounts/accounts',
        }),
        activeAccountColor() {
            const account = this.$store.state.Accounts.accounts.find(({ id }) => id === this.account.id);
            if (!account || !account.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return account.color;
        },
        activeAccountName() {
            const account = this.$store.state.Accounts.accounts.find(({ id }) => id === this.account.id);
            return account ? account.name : '';
        },
        isMain() {
            return this.account.isMain ?? false;
        },
        accountId() {
            return this.account.id;
        },
        generalColors() {
            return this.$store.state.Accounts.colors?.general ?? [];
        },
        allColorsArray() {
            const defaultColor = this.isThemeDark ? '#23232A' : '#f1f2f5';
            return [defaultColor, ...this.generalColors].sort();
        },
        accountIdToColorMap() {
            const { accounts } = this.$store.state.Accounts;
            const resultMap = new Map<string, string>();
            accounts.forEach((account) => {
                // eslint-disable-next-line no-nested-ternary
                resultMap.set(account.id, account.color ? account.color : this.isThemeDark ? '#23232A' : '#f1f2f5');
            });
            return resultMap;
        },
        hasInstitutionPolicy() {
            return this.account.policies.includes('institution');
        },
    },
    methods: {
        startRejectAccess() {
            this.isAccessRejecting = true;
        },
        async rejectAccess() {
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                await AccountsApi.refuse(new BaseAccountSharingPayload({
                    account: {
                        id: this.account.id,
                    },
                }));
                await this.$store.dispatch('Accounts/updateAccountsList');
                const mainAccount = this.accounts.find(({ isMain }) => isMain);
                await this.$store.dispatch('Accounts/setActiveAccount', mainAccount.id);
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during refusing the access');
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
                this.isAccessRejecting = false;
            }
        },
        currentTooltipContent(color) {
            const accountsNames: string[] = [];
            this.accountIdToColorMap.forEach((val, key) => {
                if (val === color) {
                    accountsNames.push(this.$store.getters['Accounts/getAccountById'](key).name);
                }
            });
            if (accountsNames.length === 0) {
                return '';
            }
            let result = '<div style="display: flex; column-gap: 4px; row-gap: 4px; max-width: 200px; flex-shrink: 1; flex-wrap: wrap">';
            accountsNames.forEach((name) => {
                const temp = `
                    <div style="display: flex; width: min-content; align-items: center; padding: 2px 10px; background-color: var(--main-bg-color); border-radius: 100px">
                        <div style="margin-right: 4px; border-radius: 50%; background-color: ${color}; width: 12px; height: 12px; border: 1px solid ${this.isThemeDark ? '#2c2c36' : '#FFFFFF'}"></div>
                        <span>
                            #${name}
                        </span>
                    </div>
                `;
                result += temp;
            });
            return `${result}</div>`;
        },
        pickColor(color) {
            this.newActiveAccountColor = color;
        },
        async copyAccountId() {
            await copyTextWithPermissions(this.accountId, this.$store.dispatch, 'Account ID');
        },
        async goToAccountSettings() {
            if (this.isEditing) {
                return;
            }
            this.hidePopover();
            await this.$router.push('/accounts/permissions-settings').catch(() => { /* navigation error */ });
        },
        async goToPlacementApiKeysManagement() {
            if (this.isEditing) {
                return;
            }
            this.hidePopover();
            await this.$router.push('/accounts/placement-api-keys-management').catch(() => { /* navigation error */ });
        },
        discardChanges() {
            this.accountName = this.account.name;
            this.accountDescription = this.account.description;
            this.isEditing = false;
        },
        async saveChanges() {
            try {
                this.accountName = this.accountName.trim();
                this.accountDescription = this.accountDescription ? this.accountDescription.trim() : '';
                const isNameValid = this.nameRegexp.test(this.accountName);
                const isDescriptionValid = this.descriptionRegexp.test(this.accountDescription);
                if (!isNameValid || !isDescriptionValid) {
                    this.errors.accountName = !isNameValid;
                    this.errors.accountDescription = !isDescriptionValid;
                    return;
                }
                this.$store.commit(SET_LOADING_ON(undefined));
                let req: IUpdateAccountPayload;
                if (this.newActiveAccountColor) {
                    req = {
                        id: this.accountId,
                        name: this.accountName,
                        description: this.accountDescription,
                        color: this.newActiveAccountColor,
                    };
                } else {
                    req = {
                        id: this.accountId,
                        name: this.accountName,
                        description: this.accountDescription,
                    };
                }
                await AccountsApi.update(new UpdateAccountPayload(req));
                await this.$store.dispatch('Accounts/updateAccountsList');
                this.isEditing = false;
                this.accountName = this.account.name;
                this.accountDescription = this.account.description;
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during saving account data, please try again later');
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        async goToInstitutionManagementPage() {
            await this.$router.push('/accounts/institutional-management').catch(() => { /* navigation error */ });
        },
    },
    mounted() {
        this.isEditing = false;
        this.accountName = this.account.name;
        this.accountDescription = this.account.description;
    },
});

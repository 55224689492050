import ApiError from 'Entities/ApiError';
import AccountTransferLimit from 'Entities/privatePresenter/AccountTransferLimit';
import AccountTransferLimitRequest from 'Entities/privatePresenter/AccountTransferLimitRequest';
import Transfer from 'Entities/privatePresenter/Transfer';
import TransferRequest from 'Entities/privatePresenter/TransferRequest';
import TransfersRequest from 'Entities/privatePresenter/TransfersRequest';
import UserTransferAddress from 'Entities/privatePresenter/UserTransferAddress';
import UserTransferAddressesParams from 'Entities/privatePresenter/UserTransferAddressesParams';
import BankRequisitesRequestData from 'Entities/walletExecutor/BankRequisitesRequestData';
import BankRequisitesResponseData from 'Entities/walletExecutor/BankRequisitesResponseData';
import CancelDepositRequestData from 'Entities/walletExecutor/CancelDepositRequestData';
import CreateBankRequisitesRequestData from 'Entities/walletExecutor/CreateBankRequisitesRequestData';
import CreateDepositRequestData from 'Entities/walletExecutor/CreateDepositRequestData';
import CreateDepositResponseData from 'Entities/walletExecutor/CreateDepositResponseData';
import DeleteRequisitesRequestData from 'Entities/walletExecutor/DeleteRequisitesRequestData';
import EmptyResult from 'Entities/walletExecutor/EmptyResult';
import ExternalWithdrawalRequestData from 'Entities/walletExecutor/ExternalWithdrawalRequestData';
import FeesResponse from 'Entities/walletExecutor/FeesResponse';
import MultiTransferRequestData from 'Entities/walletExecutor/MultiTransferRequestData';
import ServiceStatus from 'Entities/walletExecutor/ServiceStatus';
import TransferFeeRequestData from 'Entities/walletExecutor/TransferFeeRequestData';
import TransferRequestData from 'Entities/walletExecutor/TransferRequestData';
import TransferResponseData from 'Entities/walletExecutor/TransferResponseData';
import UserBankRequisitesRequestData from 'Entities/walletExecutor/UserBankRequisitesRequestData';
import WalletAddressRequestData from 'Entities/walletExecutor/WalletAddressRequestData';
import WalletAddressResponseData from 'Entities/walletExecutor/WalletAddressResponseData';
import WithdrawalFeeRequestData from 'Entities/walletExecutor/WithdrawalFeeRequestData';
import WithdrawalRequestData from 'Entities/walletExecutor/WithdrawalRequestData';
import WithdrawalResponseData from 'Entities/walletExecutor/WithdrawalResponseData';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class WalletsApi {
    static async privateGetAccountTransferLimit(accounttransferlimitrequest: AccountTransferLimitRequest, withHeaders?: boolean): Promise<{ data: AccountTransferLimit; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...accounttransferlimitrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetAccountTransferLimit', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getAccountTransferLimit`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(accounttransferlimitrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new AccountTransferLimit(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Wallets/privateGetAccountTransferLimit', { response: res, data: result, fields: [JSON.stringify({ accounttransferlimitrequest })] });
    }

    static async privateGetTransfer(transferrequest: TransferRequest, withHeaders?: boolean): Promise<{ data: Transfer; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...transferrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetTransfer', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getTransfer`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(transferrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new Transfer(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Wallets/privateGetTransfer', { response: res, data: result, fields: [JSON.stringify({ transferrequest })] });
    }

    static async privateGetTransfers(transfersrequest: TransfersRequest, withHeaders?: boolean): Promise<{ data: Transfer[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...transfersrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetTransfers', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getTransfers`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(transfersrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new Transfer(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Wallets/privateGetTransfers', { response: res, data: result, fields: [JSON.stringify({ transfersrequest })] });
    }

    static async privateGetUserTransferAddresses(usertransferaddressesparams: UserTransferAddressesParams, withHeaders?: boolean): Promise<{ data: UserTransferAddress[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...usertransferaddressesparams.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetUserTransferAddresses', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getUserTransferAddresses`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(usertransferaddressesparams.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new UserTransferAddress(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Wallets/privateGetUserTransferAddresses', { response: res, data: result, fields: [JSON.stringify({ usertransferaddressesparams })] });
    }

    static async cancelDeposit(canceldepositrequestdata: CancelDepositRequestData, withHeaders?: boolean): Promise<{ data: number; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...canceldepositrequestdata.validate());
        if (haveError.length > 0) {
            throw new ApiError('cancelDeposit', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/wallet/deposit/cancel`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(canceldepositrequestdata.serialize()),
        });
        if (res.status === 204) {
            return { data: res.status };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Wallets/cancelDeposit', { response: res, data: result, fields: [JSON.stringify({ canceldepositrequestdata })] });
    }

    static async createDeposit(createdepositrequestdata: CreateDepositRequestData, withHeaders?: boolean): Promise<{ data: CreateDepositResponseData; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...createdepositrequestdata.validate());
        if (haveError.length > 0) {
            throw new ApiError('createDeposit', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/wallet/deposit/create`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(createdepositrequestdata.serialize()),
        });
        if (res.status === 201) {
            return { data: new CreateDepositResponseData(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Wallets/createDeposit', { response: res, data: result, fields: [JSON.stringify({ createdepositrequestdata })] });
    }

    static async deleteUserBankRequisites(deleterequisitesrequestdata: DeleteRequisitesRequestData, withHeaders?: boolean): Promise<{ data: number; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...deleterequisitesrequestdata.validate());
        if (haveError.length > 0) {
            throw new ApiError('deleteUserBankRequisites', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/wallet/requisites/delete`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(deleterequisitesrequestdata.serialize()),
        });
        if (res.status === 204) {
            return { data: res.status };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Wallets/deleteUserBankRequisites', { response: res, data: result, fields: [JSON.stringify({ deleterequisitesrequestdata })] });
    }

    static async extCancelDeposit(canceldepositrequestdata: CancelDepositRequestData, withHeaders?: boolean): Promise<{ data: EmptyResult; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...canceldepositrequestdata.validate());
        if (haveError.length > 0) {
            throw new ApiError('extCancelDeposit', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/e/wallet/deposit/cancel`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(canceldepositrequestdata.serialize()),
        });
        if (res.status === 204) {
            return { data: new EmptyResult(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Wallets/extCancelDeposit', { response: res, data: result, fields: [JSON.stringify({ canceldepositrequestdata })] });
    }

    static async extCreateDeposit(createdepositrequestdata: CreateDepositRequestData, withHeaders?: boolean): Promise<{ data: CreateDepositResponseData; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...createdepositrequestdata.validate());
        if (haveError.length > 0) {
            throw new ApiError('extCreateDeposit', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/e/wallet/deposit/create`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(createdepositrequestdata.serialize()),
        });
        if (res.status === 201) {
            return { data: new CreateDepositResponseData(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Wallets/extCreateDeposit', { response: res, data: result, fields: [JSON.stringify({ createdepositrequestdata })] });
    }

    static async extDeleteUserBankRequisites(deleterequisitesrequestdata: DeleteRequisitesRequestData, withHeaders?: boolean): Promise<{ data: Record<string, object> | EmptyResult; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...deleterequisitesrequestdata.validate());
        if (haveError.length > 0) {
            throw new ApiError('extDeleteUserBankRequisites', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/e/wallet/requisites/delete`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(deleterequisitesrequestdata.serialize()),
        });
        if (res.status === 200) {
            return { data: await res.json(), headers: withHeaders ? res.headers : undefined };
        }
        if (res.status === 204) {
            return { data: new EmptyResult(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Wallets/extDeleteUserBankRequisites', { response: res, data: result, fields: [JSON.stringify({ deleterequisitesrequestdata })] });
    }

    static async extGenerateAddress(walletaddressrequestdata: WalletAddressRequestData, withHeaders?: boolean): Promise<{ data: WalletAddressResponseData; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...walletaddressrequestdata.validate());
        if (haveError.length > 0) {
            throw new ApiError('extGenerateAddress', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/e/wallet/getAddress`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(walletaddressrequestdata.serialize()),
        });
        if (res.status === 200) {
            return { data: new WalletAddressResponseData(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        if (res.status === 201) {
            return { data: new WalletAddressResponseData(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Wallets/extGenerateAddress', { response: res, data: result, fields: [JSON.stringify({ walletaddressrequestdata })] });
    }

    static async extGetDepositFees(createdepositrequestdata: CreateDepositRequestData, withHeaders?: boolean): Promise<{ data: FeesResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...createdepositrequestdata.validate());
        if (haveError.length > 0) {
            throw new ApiError('extGetDepositFees', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/e/wallet/getDepositFees`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(createdepositrequestdata.serialize()),
        });
        if (res.status === 200) {
            return { data: new FeesResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Wallets/extGetDepositFees', { response: res, data: result, fields: [JSON.stringify({ createdepositrequestdata })] });
    }

    static async extGetTransferFees(transferfeerequestdata: TransferFeeRequestData, withHeaders?: boolean): Promise<{ data: FeesResponse | EmptyResult; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...transferfeerequestdata.validate());
        if (haveError.length > 0) {
            throw new ApiError('extGetTransferFees', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/e/wallet/getTransferFees`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(transferfeerequestdata.serialize()),
        });
        if (res.status === 200) {
            return { data: new FeesResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        if (res.status === 204) {
            return { data: new EmptyResult(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Wallets/extGetTransferFees', { response: res, data: result, fields: [JSON.stringify({ transferfeerequestdata })] });
    }

    static async extGetUserBankRequisites(bankrequisitesrequestdata: BankRequisitesRequestData, withHeaders?: boolean): Promise<{ data: BankRequisitesResponseData; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...bankrequisitesrequestdata.validate());
        if (haveError.length > 0) {
            throw new ApiError('extGetUserBankRequisites', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/e/wallet/requisites/get`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(bankrequisitesrequestdata.serialize()),
        });
        if (res.status === 200) {
            return { data: new BankRequisitesResponseData(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Wallets/extGetUserBankRequisites', { response: res, data: result, fields: [JSON.stringify({ bankrequisitesrequestdata })] });
    }

    static async extGetWithdrawalFees(withdrawalfeerequestdata: WithdrawalFeeRequestData, withHeaders?: boolean): Promise<{ data: FeesResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...withdrawalfeerequestdata.validate());
        if (haveError.length > 0) {
            throw new ApiError('extGetWithdrawalFees', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/e/wallet/getWithdrawalFees`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(withdrawalfeerequestdata.serialize()),
        });
        if (res.status === 200) {
            return { data: new FeesResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Wallets/extGetWithdrawalFees', { response: res, data: result, fields: [JSON.stringify({ withdrawalfeerequestdata })] });
    }

    static async extListUserBankRequisites(userbankrequisitesrequestdata: UserBankRequisitesRequestData, withHeaders?: boolean): Promise<{ data: BankRequisitesResponseData[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...userbankrequisitesrequestdata.validate());
        if (haveError.length > 0) {
            throw new ApiError('extListUserBankRequisites', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/e/wallet/requisites/list`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(userbankrequisitesrequestdata.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new BankRequisitesResponseData(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Wallets/extListUserBankRequisites', { response: res, data: result, fields: [JSON.stringify({ userbankrequisitesrequestdata })] });
    }

    static async extSaveUserBankRequisites(createbankrequisitesrequestdata: CreateBankRequisitesRequestData, withHeaders?: boolean): Promise<{ data: BankRequisitesResponseData; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...createbankrequisitesrequestdata.validate());
        if (haveError.length > 0) {
            throw new ApiError('extSaveUserBankRequisites', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/e/wallet/requisites/save`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(createbankrequisitesrequestdata.serialize()),
        });
        if (res.status === 200) {
            return { data: new BankRequisitesResponseData(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        if (res.status === 201) {
            return { data: new BankRequisitesResponseData(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Wallets/extSaveUserBankRequisites', { response: res, data: result, fields: [JSON.stringify({ createbankrequisitesrequestdata })] });
    }

    static async extTransfer(transferrequestdata: TransferRequestData, withHeaders?: boolean): Promise<{ data: TransferResponseData; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...transferrequestdata.validate());
        if (haveError.length > 0) {
            throw new ApiError('extTransfer', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/e/wallet/transfer`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(transferrequestdata.serialize()),
        });
        if (res.status === 201) {
            return { data: new TransferResponseData(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Wallets/extTransfer', { response: res, data: result, fields: [JSON.stringify({ transferrequestdata })] });
    }

    static async extWithdraw(externalwithdrawalrequestdata: ExternalWithdrawalRequestData, withHeaders?: boolean): Promise<{ data: WithdrawalResponseData; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...externalwithdrawalrequestdata.validate());
        if (haveError.length > 0) {
            throw new ApiError('extWithdraw', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/e/wallet/withdraw`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(externalwithdrawalrequestdata.serialize()),
        });
        if (res.status === 201) {
            return { data: new WithdrawalResponseData(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Wallets/extWithdraw', { response: res, data: result, fields: [JSON.stringify({ externalwithdrawalrequestdata })] });
    }

    static async generateAddress(walletaddressrequestdata: WalletAddressRequestData, withHeaders?: boolean): Promise<{ data: WalletAddressResponseData; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...walletaddressrequestdata.validate());
        if (haveError.length > 0) {
            throw new ApiError('generateAddress', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/wallet/generateAddress`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(walletaddressrequestdata.serialize()),
        });
        if (res.status === 201) {
            return { data: new WalletAddressResponseData(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Wallets/generateAddress', { response: res, data: result, fields: [JSON.stringify({ walletaddressrequestdata })] });
    }

    static async getAddress(walletaddressrequestdata: WalletAddressRequestData, withHeaders?: boolean): Promise<{ data: WalletAddressResponseData | EmptyResult; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...walletaddressrequestdata.validate());
        if (haveError.length > 0) {
            throw new ApiError('getAddress', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/wallet/getAddress`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(walletaddressrequestdata.serialize()),
        });
        if (res.status === 200) {
            return { data: new WalletAddressResponseData(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        if (res.status === 201) {
            return { data: new WalletAddressResponseData(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        if (res.status === 204) {
            return { data: new EmptyResult(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Wallets/getAddress', { response: res, data: result, fields: [JSON.stringify({ walletaddressrequestdata })] });
    }

    static async getDepositFees(createdepositrequestdata: CreateDepositRequestData, withHeaders?: boolean): Promise<{ data: FeesResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...createdepositrequestdata.validate());
        if (haveError.length > 0) {
            throw new ApiError('getDepositFees', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/wallet/getDepositFees`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(createdepositrequestdata.serialize()),
        });
        if (res.status === 200) {
            return { data: new FeesResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Wallets/getDepositFees', { response: res, data: result, fields: [JSON.stringify({ createdepositrequestdata })] });
    }

    static async getTransferFees(transferfeerequestdata: TransferFeeRequestData, withHeaders?: boolean): Promise<{ data: FeesResponse | EmptyResult; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...transferfeerequestdata.validate());
        if (haveError.length > 0) {
            throw new ApiError('getTransferFees', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/wallet/getTransferFees`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(transferfeerequestdata.serialize()),
        });
        if (res.status === 200) {
            return { data: new FeesResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        if (res.status === 204) {
            return { data: new EmptyResult(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Wallets/getTransferFees', { response: res, data: result, fields: [JSON.stringify({ transferfeerequestdata })] });
    }

    static async getUserBankRequisites(bankrequisitesrequestdata: BankRequisitesRequestData, withHeaders?: boolean): Promise<{ data: BankRequisitesResponseData; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...bankrequisitesrequestdata.validate());
        if (haveError.length > 0) {
            throw new ApiError('getUserBankRequisites', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/wallet/requisites/get`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(bankrequisitesrequestdata.serialize()),
        });
        if (res.status === 200) {
            return { data: new BankRequisitesResponseData(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Wallets/getUserBankRequisites', { response: res, data: result, fields: [JSON.stringify({ bankrequisitesrequestdata })] });
    }

    static async getWithdrawalFees(withdrawalfeerequestdata: WithdrawalFeeRequestData, withHeaders?: boolean): Promise<{ data: FeesResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...withdrawalfeerequestdata.validate());
        if (haveError.length > 0) {
            throw new ApiError('getWithdrawalFees', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/wallet/getWithdrawalFees`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(withdrawalfeerequestdata.serialize()),
        });
        if (res.status === 200) {
            return { data: new FeesResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Wallets/getWithdrawalFees', { response: res, data: result, fields: [JSON.stringify({ withdrawalfeerequestdata })] });
    }

    static async listUserBankRequisites(userbankrequisitesrequestdata: UserBankRequisitesRequestData, withHeaders?: boolean): Promise<{ data: BankRequisitesResponseData[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...userbankrequisitesrequestdata.validate());
        if (haveError.length > 0) {
            throw new ApiError('listUserBankRequisites', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/wallet/requisites/list`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(userbankrequisitesrequestdata.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new BankRequisitesResponseData(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Wallets/listUserBankRequisites', { response: res, data: result, fields: [JSON.stringify({ userbankrequisitesrequestdata })] });
    }

    static async saveUserBankRequisites(createbankrequisitesrequestdata: CreateBankRequisitesRequestData, withHeaders?: boolean): Promise<{ data: BankRequisitesResponseData; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...createbankrequisitesrequestdata.validate());
        if (haveError.length > 0) {
            throw new ApiError('saveUserBankRequisites', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/wallet/requisites/save`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(createbankrequisitesrequestdata.serialize()),
        });
        if (res.status === 200) {
            return { data: new BankRequisitesResponseData(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        if (res.status === 201) {
            return { data: new BankRequisitesResponseData(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Wallets/saveUserBankRequisites', { response: res, data: result, fields: [JSON.stringify({ createbankrequisitesrequestdata })] });
    }

    static async transfer(transferrequestdata: TransferRequestData, withHeaders?: boolean): Promise<{ data: TransferResponseData; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...transferrequestdata.validate());
        if (haveError.length > 0) {
            throw new ApiError('transfer', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/wallet/transfer`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(transferrequestdata.serialize()),
        });
        if (res.status === 201) {
            return { data: new TransferResponseData(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Wallets/transfer', { response: res, data: result, fields: [JSON.stringify({ transferrequestdata })] });
    }

    static async transferMulti(multitransferrequestdata: MultiTransferRequestData, withHeaders?: boolean): Promise<{ data: TransferResponseData[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...multitransferrequestdata.validate());
        if (haveError.length > 0) {
            throw new ApiError('transferMulti', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/wallet/transfer/multiple`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(multitransferrequestdata.serialize()),
        });
        if (res.status === 201) {
            return { data: (await res.json()).map((r: any) => new TransferResponseData(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Wallets/transferMulti', { response: res, data: result, fields: [JSON.stringify({ multitransferrequestdata })] });
    }

    static async withdraw(withdrawalrequestdata: WithdrawalRequestData, withHeaders?: boolean): Promise<{ data: WithdrawalResponseData; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...withdrawalrequestdata.validate());
        if (haveError.length > 0) {
            throw new ApiError('withdraw', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/wallet/withdraw`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(withdrawalrequestdata.serialize()),
        });
        if (res.status === 201) {
            return { data: new WithdrawalResponseData(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Wallets/withdraw', { response: res, data: result, fields: [JSON.stringify({ withdrawalrequestdata })] });
    }
}

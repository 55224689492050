import { AuthEvent } from 'Models/authEvents';
import UserApi from 'Apis/User';
import LogsPayload from 'Entities/userLoginHistory/LogsPayload';

const state = {
    list: [],
    itemsPerPage: 13,
    nextToken: null,
    isNowUpdating: false,
};

const getters = {
    list: (state) => state.list,

    itemsPerPage: (state) => state.itemsPerPage,
    nextToken: (state) => state.nextToken,

    isNowUpdating: (state) => state.isNowUpdating,

    hasMore: (state, getters) => getters.nextToken !== null && getters.nextToken !== '',

    lastLogin: (state, getters) => (getters.list.length > 0 ? getters.list[0] : null),
    lastLoginDate: (state, getters) => (getters.lastLogin ? getters.lastLogin.published : null),
    lastLoginIpAddress: (state, getters) => (getters.lastLogin ? getters.lastLogin.client?.ipAddress : null),
};

const mutations = {
    PUSH_ITEMS(state, items) {
        state.list = [...items];
    },
    SET_NEXT_TOKEN(state, token) {
        state.nextToken = token;
    },
    SET_UPDATING_STATUS(state, isNowUpdating) {
        state.isNowUpdating = isNowUpdating;
    },
};

const actions = {
    setNextToken({ commit }, token) {
        commit('SET_NEXT_TOKEN', token);
    },
    setUpdatingStatus({ commit }, isNowUpdating) {
        commit('SET_UPDATING_STATUS', isNowUpdating);
    },
    pushItems({ commit }, items) {
        const result: AuthEvent[] = [];

        items.forEach((event) => {
            try {
                result.push(new AuthEvent(event));
            } catch {
                // code crushed because of bad data of event variable
            }
        });

        commit('PUSH_ITEMS', result);
    },
    async getAllItems({ commit }) {
        const { data: { events } } = await UserApi.getAuthEvents(new LogsPayload({
            limit: 1000,
            nextToken: '',
            sessionId: '',
            sortOrder: '',
        }));
        commit('PUSH_ITEMS', events);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

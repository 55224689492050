// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Routes } from 'Config/routes';

const Terminal = () => import('Components/Terminal/Terminal.vue');
const Portfolios = () => import('Components/Portfolios/Portfolios.vue');
const Wallets = () => import('Components/Wallets/Wallets.vue');
const Workspace = () => import('Components/Workspace/Workspace.vue');
const Defi = () => import('Components/Defi/Defi.vue');
const Statement = () => import('Components/Statement/Statement.vue');
const Earn = () => import('Components/Earn/Earn.vue');
const Profile = () => import('Components/Profile/Profile.vue');
const ProfileChangePassword = () => import('Components/Profile/Profile.ChangePassword.vue');
const ProfileSetTotpMFA = () => import('Components/Profile/Profile.MFA_totp.vue');
const ProfileManageKeys = () => import('Components/Profile/Profile.ManageKeys.vue');
const ProfileViewKey = () => import('Components/Profile/Profile.ViewKey.vue');
const AccountsPermissionsSettings = () => import('Components/Account/PermissionsSettings.vue');
const SignIn = () => import('Components/Auth/SignIn.vue');
const SignUp = () => import('Components/Auth/SignUp.vue');
const SignUpSubUser = () => import('Components/Auth/SignUp.SubUser.vue');
const ConfirmSignIn = () => import('Components/Auth/SignIn.Confirm.vue');
const ConfirmSignUp = () => import('Components/Auth/SignUp.Confirm.vue');
const RestorePassword = () => import('Components/Auth/RestorePassword.vue');
const MFA_TOTP = () => import('Components/Auth/MFA_totp.vue');
const MFA_EMAIL_TOTP = () => import('Components/Auth/MFA_email-totp.vue');
const CompleteWithdraw = () => import('Modules/Payment/Confirmations/CompleteWithdraw.vue');
const CompleteFiatWithdrawal = () => import('Modules/Payment/Confirmations/CompleteFiatWithdrawal.vue');
const CompleteDeposit = () => import('Modules/Payment/Confirmations/CompleteDeposit.vue');
const CompleteFiatDeposit = () => import('Modules/Payment/Confirmations/CompleteFiatDeposit.vue');
const CompleteFiatDepositHistory = () => import('Modules/Payment/Confirmations/CompleteFiatDepositHistory.vue');
const Whitelisting = () => import('Modules/Payment/Confirmations/Whitelisting.vue');
const RequisitesWhitelisting = () => import('Modules/Payment/Confirmations/RequisitesWhitelisting.vue');
const CompleteTransfer = () => import('Modules/Transfer/Confirmations/CompleteTransfer.vue');
const CompleteDepositHistory = () => import('Modules/Payment/Confirmations/ConfirmDepositHistory.vue');
const ManageLiquidity = () => import('Components/Defi/ManageLiquidity.vue');
const KYC = () => import('Components/common/KYC.vue');
const UiCheck = () => import('Components/UiCheck/UiCheck.vue');
const Subscription = () => import('Components/Earn/Subscription.vue');
const ClosePosition = () => import('Components/Earn/ClosePosition.vue');
const GroupsList = () => import('Components/Profile/Profile.GroupsList.vue');
const GroupManage = () => import('Components/Profile/Profile.GroupManage.vue');
const InstitutionalManagement = () => import('Components/Account/InstitutionalManagement.vue');
const AddressesManagement = () => import('Components/Profile/Profile.AddressesManage.vue');
const ProfileSubscriptions = () => import('Components/Profile/Profile.Subscriptions.vue');
const ProfilePurchase = () => import('Components/Profile/Profile.Purchase.vue');
const OTC = () => import('Components/OTC/OTC.vue');
const PlacementApiKeysManagement = () => import('Components/Account/PlacementApiKeysManagement.vue');
const ViewNewPlacementApiKey = () => import('Components/Account/ViewNewPlacementApiKey.vue');
const SubUsersList = () => import('Components/Profile/Profile.SubUsersList.vue');
const SubUsersEdit = () => import('Components/Profile/Profile.SubUsersEdit.vue');

const routes = [
    {
        path: Routes.Terminal,
        name: 'Terminal',
        component: Terminal,
        meta: {
            showHeader: true,
            showLeftMenu: true,
            isInNavigation: true,
            iconName: 'terminal',
        },
    },
    {
        path: Routes.Portfolios,
        name: 'Portfolio',
        component: Portfolios,
        meta: {
            showHeader: true,
            showLeftMenu: true,
            isInNavigation: true,
            iconName: 'portfolio',
        },
    },
    {
        path: Routes.Wallets,
        name: 'Wallets',
        component: Wallets,
        meta: {
            showHeader: true,
            showLeftMenu: true,
            isInNavigation: true,
            iconName: 'wallets',
        },
    },
    {
        path: Routes.Defi,
        name: 'DeFi',
        component: Defi,
        meta: {
            showHeader: true,
            showLeftMenu: true,
            isInNavigation: true,
            iconName: 'defi',
        },
    },
    {
        path: Routes.Workspace,
        name: 'Workspaces',
        component: Workspace,
        meta: {
            showHeader: true,
            showLeftMenu: true,
            isInNavigation: true,
            iconName: 'workspace',
        },
    },
    {
        path: Routes.Statement,
        name: 'Statement',
        component: Statement,
        meta: {
            showHeader: true,
            showLeftMenu: true,
            isInNavigation: true,
            iconName: 'statement',
        },
    },
    {
        path: Routes.Earn,
        name: 'Earn',
        component: Earn,
        meta: {
            showHeader: true,
            showLeftMenu: true,
            isInNavigation: true,
            iconName: 'earn',
        },
    },
    {
        path: Routes.OTC,
        name: 'OTC',
        component: OTC,
        meta: {
            showHeader: true,
            showLeftMenu: true,
            isInNavigation: true,
            iconName: 'otc',
        },
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: {
            showHeader: true,
        },
    },
    {
        path: '/profile/manageApiKeys',
        name: 'Manage API Keys',
        component: ProfileManageKeys,
        meta: {
            showHeader: true,
        },
    },
    {
        path: '/profile/viewKey',
        name: 'View API Key',
        component: ProfileViewKey,
        meta: {
            showHeader: true,
        },
    },
    {
        path: '/profile/changepassword',
        name: 'Change Password',
        component: ProfileChangePassword,
        meta: {
            showHeader: true,
        },
    },
    {
        path: '/profile/settotpmfa',
        name: 'Set TOTP MFA',
        component: ProfileSetTotpMFA,
        meta: {
            showHeader: true,
        },
    },
    {
        path: '/accounts/permissions-settings',
        name: 'Account Permissions Settings',
        component: AccountsPermissionsSettings,
        meta: {
            showHeader: true,
            showLeftMenu: true,
        },
    },
    {
        path: '/accounts/placement-api-keys-management',
        name: 'Placement Api Keys Management',
        component: PlacementApiKeysManagement,
        meta: {
            showHeader: true,
            showLeftMenu: true,
        },
    },
    {
        path: '/accounts/placement-api-key-view',
        name: 'Placement Api Keys Management',
        component: ViewNewPlacementApiKey,
        meta: {
            showHeader: true,
            showLeftMenu: true,
        },
    },
    {
        path: '/signin',
        name: 'Sign in',
        component: SignIn,
        meta: {
            is_auth: true,
        },
    },
    {
        path: '/signup',
        name: 'Sign up',
        component: SignUp,
        meta: {
            is_auth: true,
        },
    },
    {
        path: '/corporate-signup',
        name: 'Corporate Sign Up',
        component: SignUpSubUser,
        meta: {
            is_auth: true,
        },
    },
    {
        path: '/signin-confirm',
        name: 'Confirm sign in',
        component: ConfirmSignIn,
        meta: {
            is_auth: true,
        },
    },
    {
        path: '/signup-confirm',
        name: 'Confirm sign up',
        component: ConfirmSignUp,
        meta: {
            is_auth: true,
        },
    },
    {
        path: '/restorepassword',
        name: 'Restore password',
        component: RestorePassword,
        meta: {
            is_auth: true,
        },
    },
    {
        path: '/mfa/totp',
        name: 'TOTP authentication',
        component: MFA_TOTP,
        meta: {
            showHeader: true,
        },
    },
    {
        path: '/Wallets/Confirmations/CompleteWithdraw',
        name: 'Complete Withdraw',
        component: CompleteWithdraw,
        meta: {
            showHeader: true,
        },
    },
    {
        path: '/Wallets/Confirmations/CompleteFiatWithdrawal',
        name: 'Complete Fiat Withdrawal',
        component: CompleteFiatWithdrawal,
        meta: {
            showHeader: true,
        },
    },
    {
        path: '/Wallets/Confirmations/CompleteDeposit',
        name: 'Complete Deposit',
        component: CompleteDeposit,
        meta: {
            showHeader: true,
        },
    },
    {
        path: '/Wallets/Confirmations/CompleteFiatDeposit',
        name: 'Complete Fiat Deposit',
        component: CompleteFiatDeposit,
        meta: {
            showHeader: true,
        },
    },
    {
        path: '/Wallets/Confirmations/CompleteFiatDepositHistory',
        name: 'Complete Fiat Deposit History',
        component: CompleteFiatDepositHistory,
        meta: {
            showHeader: true,
        },
    },
    {
        path: '/Wallets/Confirmations/Whitelisting',
        name: 'Whitelisting',
        component: Whitelisting,
        meta: {
            showHeader: true,
        },
    },
    {
        path: '/Wallets/Confirmations/requisites-whitelisting',
        name: 'RequisitesWhitelisting',
        component: RequisitesWhitelisting,
        meta: {
            showHeader: true,
        },
    },
    {
        path: '/Wallets/Confirmations/CompleteTransfer',
        name: 'Complete Transfer',
        component: CompleteTransfer,
        meta: {
            showHeader: true,
        },
    },
    {
        path: '/Wallets/Confirmations/CompleteDepositHistory',
        name: 'Complete Deposit History',
        component: CompleteDepositHistory,
        meta: {
            showHeader: true,
        },
    },
    {
        path: '/Defi/ManageLiquidity',
        name: 'Manage Liquidity',
        component: ManageLiquidity,
        meta: {
            showHeader: true,
        },
    },
    {
        path: '/mfa/email-totp',
        name: 'Email and TOTP authentication',
        component: MFA_EMAIL_TOTP,
        meta: {
            showHeader: true,
        },
    },
    {
        path: '/KYC',
        name: 'KYC',
        component: KYC,
        meta: {
            showHeader: true,
        },
    },
    {
        path: '/uicheck_private',
        name: 'Check ui private',
        component: UiCheck,
    },
    {
        path: '/subscription',
        name: 'Subscription',
        component: Subscription,
        meta: {
            showHeader: true,
        },
    },
    {
        path: '/close-position',
        name: 'ClosePosition',
        component: ClosePosition,
        meta: {
            showHeader: true,
        },
    },
    {
        path: '/profile/groups-list',
        name: 'GroupsList',
        component: GroupsList,
        meta: {
            showHeader: true,
            showLeftMenu: true,
        },
    },
    {
        path: '/profile/subusers-list',
        name: 'SubUsersList',
        component: SubUsersList,
        meta: {
            showHeader: true,
            showLeftMenu: true,
        },
    },
    {
        path: '/profile/subusers-edit',
        name: 'SubUsersEdit',
        component: SubUsersEdit,
        meta: {
            showHeader: true,
            showLeftMenu: true,
        },
    },
    {
        path: '/profile/group-manage',
        name: 'GroupManage',
        component: GroupManage,
        meta: {
            showHeader: true,
            showLeftMenu: true,
        },
    },
    {
        path: '/accounts/institutional-management',
        name: 'InstitutionalManagement',
        component: InstitutionalManagement,
        meta: {
            showHeader: true,
            showLeftMenu: true,
        },
    },
    {
        path: '/profile/addresses-management',
        name: 'AddressesManagement',
        component: AddressesManagement,
        meta: {
            showHeader: true,
            showLeftMenu: true,
        },
    },
    {
        path: '/profile/subscriptions',
        name: 'ProfileSubscriptions',
        component: ProfileSubscriptions,
        meta: {
            showHeader: true,
            showLeftMenu: false,
        },
    },
    {
        path: '/profile/purchase',
        name: 'ProfilePurchase',
        component: ProfilePurchase,
        meta: {
            showHeader: true,
            showLeftMenu: false,
        },
    },
    {
        path: '*',
        redirect: '/signin',
    },
];

export default routes.map((route) => Object.assign(route, {
    meta: route.meta ? route.meta : {},
}));

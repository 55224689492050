import { Action } from 'vuex';
import Centrifuge from 'centrifuge';

import PrivateDataApi from 'Apis/PrivateData';
import endpoints from 'Const/endpoints';
import StreamChannelsRequest from 'Entities/privatePresenter/StreamChannelsRequest';
import StreamChannelsResponse from 'Entities/privatePresenter/StreamChannelsResponse';
import PrivateChannel from 'Entities/privatePresenter/PrivateChannel';
import { ITransfer } from 'Entities/privatePresenter/Transfer';
import { IDexTransaction } from 'Entities/privatePresenter/DexTransaction';
import { NecessarySocketNamespaces, UnnecessarySocketNamespaces } from 'Const/socket';
import { actionCreator, mutationCreator } from 'Store/utils';
import { updateNotifications } from 'Store/v2/Notifications';
import {
    getAssetsAllowance,
    getLiquidityAllowance,
    getPoolPositions,
    UPDATE_POOL_POSITION,
} from 'Store/v2/DefiLiquidityPools';
import { IDexPosition } from 'Entities/privatePresenter/DexPosition';
import { IFuturesPosition } from 'Entities/privatePresenter/FuturesPosition';
import {
    updateOrders,
    updatePosition,
    updateQuickBalancePosition,
    updateTablePosition,
    updateTrade,
    updateOrdersTradesBySocket,
} from 'Store/v2/Futures';
import { IFuturesOrderPresenter } from 'Entities/privatePresenter/FuturesOrderPresenter';
import { IFuturesTrade } from 'Entities/privatePresenter/FuturesTrade';
import { IBalance } from 'Entities/privatePresenter/Balance';
import { updateBalance } from 'Store/v2/Balances';
import { IStakingPosition } from 'Entities/privatePresenter/StakingPosition';
import { updateStakingPosition } from 'Store/v2/Earn';
import { needUpdateAddresses } from 'Store/v2/UiActions';
import { ISpotOrderPresenter } from 'Entities/privatePresenter/SpotOrderPresenter';
import { updateModalOrders, updateModalPosition } from 'Store/v2/TakeProfitStopLossModal';
import { ISpotTrade } from 'Entities/privatePresenter/SpotTrade';
import router from '@/router';
import { IBorrowing } from 'Entities/privatePresenter/Borrowing';
import { ITotalBalance } from 'Entities/privatePresenter/TotalBalance';
import { updateTotalBalancesBySocket } from 'Store/v2/Margin';

import { checkCurrencyApprove, SET_DEX_TRANSACTION } from './Defi';

interface ISubscribe {
    channel: (UnnecessarySocketNamespaces | NecessarySocketNamespaces);
    callback: (data: any) => void;
}

const state = {
    socketClient: undefined as string | undefined,
    streamChannels: undefined as Map<string, PrivateChannel> | undefined,
    centrifuge: undefined as Centrifuge | undefined,
    subscribers: {} as {[key in (UnnecessarySocketNamespaces | NecessarySocketNamespaces)]: ((data: any) => void)[]},
    channels: [] as PrivateChannel[],
    changeAccountListenerId: undefined as undefined | number,
    activeAccountSubscriptions: [] as Centrifuge.Subscription[],
    activeAccountIdSubscription: undefined as undefined | string,
    resubscriptions: {
        resubscriptionsCounter: 0,
        resubscriptionTimeoutId: null as null | NodeJS.Timeout,
        MAXIMUM_SUBSCRIPTIONS_WITHOUT_TIMEOUT: 2,
        MAXIMUM_RESUBSCRIPTIONS: 5,
        TIMEOUT_VALUE: 5000,
    },
};

export type PrivateSocketDataState = typeof state;

export enum PrivateSocketDataGetters {}

// type GettersReturn<G extends { [key in PrivateSocketDataGetters]: (...args: any) => any }> = { [key in keyof G]: ReturnType<G[PrivateSocketDataGetters]> };
// interface Getters {}

const getters = {};

export enum PrivateSocketDataMutations {
    SET_STREAM_CHANNELS = 'SET_STREAM_CHANNELS',
    SET_SOCKET_CLIENT = 'SET_SOCKET_CLIENT',
    SET_CENTRIFUGE = 'SET_CENTRIFUGE',
    SUBSCRIBE = 'SUBSCRIBE',
    UNSUBSCRIBE = 'UNSUBSCRIBE',
    SET_CHANNELS = 'SET_CHANNELS',
    SET_CHANGE_ACCOUNT_LISTENER_ID = 'SET_CHANGE_ACCOUNT_LISTENER_ID',
    SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS',
    SET_ACTIVE_ACCOUNT_ID_SUBSCRIPTION = 'SET_ACTIVE_ACCOUNT_ID_SUBSCRIPTION',
}

export const SET_STREAM_CHANNELS = mutationCreator<StreamChannelsResponse>('PrivateSocketData', PrivateSocketDataMutations.SET_STREAM_CHANNELS);
export const SET_SOCKET_CLIENT = mutationCreator<string>('PrivateSocketData', PrivateSocketDataMutations.SET_SOCKET_CLIENT);
export const SET_CENTRIFUGE = mutationCreator<Centrifuge>('PrivateSocketData', PrivateSocketDataMutations.SET_CENTRIFUGE);
export const SUBSCRIBE = mutationCreator<ISubscribe>('PrivateSocketData', PrivateSocketDataMutations.SUBSCRIBE);
export const UNSUBSCRIBE = mutationCreator<ISubscribe>('PrivateSocketData', PrivateSocketDataMutations.UNSUBSCRIBE);
export const SET_CHANNELS = mutationCreator<PrivateChannel[]>('PrivateSocketData', PrivateSocketDataMutations.SET_CHANNELS);
export const SET_CHANGE_ACCOUNT_LISTENER_ID = mutationCreator<undefined | number>('PrivateSocketData', PrivateSocketDataMutations.SET_CHANGE_ACCOUNT_LISTENER_ID);
export const SET_SUBSCRIPTIONS = mutationCreator<Centrifuge.Subscription[]>('PrivateSocketData', PrivateSocketDataMutations.SET_SUBSCRIPTIONS);
export const SET_ACTIVE_ACCOUNT_ID_SUBSCRIPTION = mutationCreator<string>('PrivateSocketData', PrivateSocketDataMutations.SET_ACTIVE_ACCOUNT_ID_SUBSCRIPTION);

const mutations: Record<PrivateSocketDataMutations, (state: PrivateSocketDataState, ...args: any) => void> = {
    SET_STREAM_CHANNELS(state, { payload: streamChannels }: ReturnType<typeof SET_STREAM_CHANNELS>) {
        const { channels } = streamChannels;
        const map = new Map();
        channels.forEach((c) => map.set(c.channel, c));
        state.streamChannels = map;
    },
    SET_SOCKET_CLIENT(state, { payload: client }: ReturnType<typeof SET_SOCKET_CLIENT>) {
        state.socketClient = client;
    },
    SET_CENTRIFUGE(state, { payload: centrifuge }: ReturnType<typeof SET_CENTRIFUGE>) {
        state.centrifuge = centrifuge;
    },
    SUBSCRIBE(state, { payload: { channel, callback } }: ReturnType<typeof SUBSCRIBE>) {
        const { subscribers } = state;
        if (!subscribers[channel]) {
            subscribers[channel] = [callback];
        } else {
            subscribers[channel].push(callback);
        }
    },
    UNSUBSCRIBE(state, { payload: { channel, callback } }: ReturnType<typeof UNSUBSCRIBE>) {
        const { subscribers } = state;
        let indexToRemove: null | number = null;
        subscribers[channel].forEach((cb, ind) => {
            if (cb === callback) {
                indexToRemove = ind;
            }
        });
        if (indexToRemove !== null) {
            subscribers[channel].splice(indexToRemove, 1);
        }
    },
    SET_CHANNELS(state, { payload: channels }: ReturnType<typeof SET_CHANNELS>) {
        state.channels = channels;
    },
    SET_CHANGE_ACCOUNT_LISTENER_ID(state, { payload: listenerId }: ReturnType<typeof SET_CHANGE_ACCOUNT_LISTENER_ID>) {
        state.changeAccountListenerId = listenerId;
    },
    SET_SUBSCRIPTIONS(state, { payload: subscriptions }: ReturnType<typeof SET_SUBSCRIPTIONS>) {
        state.activeAccountSubscriptions = subscriptions;
    },
    SET_ACTIVE_ACCOUNT_ID_SUBSCRIPTION(state, { payload: id }: ReturnType<typeof SET_ACTIVE_ACCOUNT_ID_SUBSCRIPTION>) {
        state.activeAccountIdSubscription = id;
    },
};

export enum PrivateSocketDataActions {
    init = 'init',
    onConnectionEstablished = 'onConnectionEstablished',
    resubscribeWithTimeout = 'resubscribeWithTimeout',
    subscribeOnActiveAccountChannels = 'subscribeOnActiveAccountChannels',
    subscribeOnNecessaryChannels = 'subscribeOnNecessaryChannels',
}

export const init = actionCreator<undefined>('PrivateSocketData', PrivateSocketDataActions.init);
export const onConnectionEstablished = actionCreator<string>('PrivateSocketData', PrivateSocketDataActions.onConnectionEstablished);
export const resubscribeWithTimeout = actionCreator<undefined>('PrivateSocketData', PrivateSocketDataActions.resubscribeWithTimeout);
export const subscribeOnActiveAccountChannels = actionCreator<PrivateChannel[]>('PrivateSocketData', PrivateSocketDataActions.subscribeOnActiveAccountChannels);
export const subscribeOnNecessaryChannels = actionCreator<PrivateChannel[]>('PrivateSocketData', PrivateSocketDataActions.subscribeOnNecessaryChannels);

const actions: Record<PrivateSocketDataActions, Action<PrivateSocketDataState, any>> = {
    async init({ commit, dispatch, state }) {
        if (state.centrifuge) {
            state.centrifuge.disconnect();
        }
        if (state.changeAccountListenerId) {
            await dispatch('VuexEventListener/removeActionListener', {
                type: 'Accounts/setActiveAccount',
                id: state.changeAccountListenerId,
            }, { root: true });
        }

        const centrifuge = new Centrifuge(endpoints.privateDataSource, {
            onRefresh: async (ctx, cb) => {
                try {
                    const { data: token } = await PrivateDataApi.privateRefreshStreamToken();
                    cb({ status: 200, data: { token: token.token } });
                } catch (error) {
                    cb({ status: 500, data: { token: 'failed' } });
                    // TODO: send Sentry error
                }
            },
            onPrivateSubscribe: async (ctx, cb) => {
                const channels = state.streamChannels;
                if (!channels) {
                    return null;
                }
                const subscribeChannels = ctx.data.channels;
                const data = subscribeChannels.map((sc) => {
                    const privateData = channels.get(sc)!;
                    return privateData.serialize();
                });
                cb({ status: 200, data: { channels: data } });
            },
        });
        centrifuge.on('connect', ({ client }) => {
            dispatch(onConnectionEstablished(client, true));
        });
        centrifuge.on('error', async () => {
            if (state.centrifuge) {
                await dispatch(resubscribeWithTimeout(undefined, true));
            }
        });
        const { data: token } = await PrivateDataApi.privateGetStreamToken();
        centrifuge.setToken(token.token);
        centrifuge.connect();
        commit(SET_CENTRIFUGE(centrifuge, true));
    },
    async onConnectionEstablished({ commit, dispatch }, { payload: client }: ReturnType<typeof onConnectionEstablished>) {
        commit(SET_SOCKET_CLIENT(client, true));
        const { data: availableChannels } = await PrivateDataApi.privateGetStreamChannels(new StreamChannelsRequest({ client }));
        commit(SET_STREAM_CHANNELS(availableChannels, true));
        const { channels } = availableChannels;
        commit(SET_CHANNELS(channels, true));
        await dispatch(subscribeOnNecessaryChannels(channels, true));
        await dispatch(subscribeOnActiveAccountChannels(channels, true));
    },
    async resubscribeWithTimeout({ state, dispatch }) {
        console.log('private socket resubscribe because of error');
        if (state.resubscriptions.resubscriptionsCounter > state.resubscriptions.MAXIMUM_RESUBSCRIPTIONS) {
            console.log('private socket logout');
            try {
                await dispatch('Auth/logout', undefined, { root: true });
                await router.push('/signin').catch(() => { /* navigation error */ });
            } catch {
                document.location.reload();
            }
            return;
        }
        if (state.resubscriptions.resubscriptionsCounter < state.resubscriptions.MAXIMUM_SUBSCRIPTIONS_WITHOUT_TIMEOUT) {
            state.resubscriptions.resubscriptionsCounter += 1;
            await dispatch(init(undefined, true));
        } else if (state.resubscriptions.resubscriptionTimeoutId === null) {
            state.resubscriptions.resubscriptionTimeoutId = setTimeout(async () => {
                state.resubscriptions.resubscriptionsCounter += 1;
                state.resubscriptions.resubscriptionTimeoutId = null;
                await dispatch(init(undefined, true));
            }, (state.resubscriptions.resubscriptionsCounter - state.resubscriptions.MAXIMUM_SUBSCRIPTIONS_WITHOUT_TIMEOUT) * state.resubscriptions.TIMEOUT_VALUE);
        }
    },
    async subscribeOnActiveAccountChannels({ state, commit, dispatch, rootState, rootGetters }, { payload: channels }: ReturnType<typeof subscribeOnActiveAccountChannels>) {
        commit(SET_ACTIVE_ACCOUNT_ID_SUBSCRIPTION(rootState.Accounts.activeAccountID, true));
        if (state.changeAccountListenerId === undefined) {
            const listenerId: number = await dispatch('VuexEventListener/addActionListener', {
                type: 'Accounts/setActiveAccount',
                callback: async () => {
                    if (rootState.Accounts.activeAccountID !== state.activeAccountIdSubscription) {
                        await dispatch(subscribeOnActiveAccountChannels(state.channels, true));
                    }
                },
            }, { root: true });
            commit(SET_CHANGE_ACCOUNT_LISTENER_ID(listenerId, true));
        }

        const { centrifuge, subscribers } = state;
        if (!centrifuge) {
            return;
        }

        // unsubscribe previous account channels
        state.activeAccountSubscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });

        // subscribe current account channels
        const subscriptions: Centrifuge.Subscription[] = [];
        channels.forEach(({ channel }) => {
            const [namespace, id] = channel.replace('$', '').split(':') as [NecessarySocketNamespaces | UnnecessarySocketNamespaces, string];
            if (id === rootState.Accounts.activeAccountID) {
                switch (namespace) {
                    case UnnecessarySocketNamespaces.transfers: {
                        const subscription = centrifuge.subscribe(channel, ({ data: transfer }: { data: Partial<ITransfer> }) => {
                            if (transfer.type === 'deposit' && transfer.status === 'confirmed') {
                                dispatch(needUpdateAddresses(undefined), { root: true });
                            }
                            const accountId = rootGetters['Accounts/activeAccountID'];
                            if (id === accountId) {
                                try {
                                    if (subscribers[UnnecessarySocketNamespaces.transfers]) {
                                        subscribers[UnnecessarySocketNamespaces.transfers].forEach((cb) => {
                                            cb(transfer);
                                        });
                                    }
                                } catch {
                                    // code crushed because of no such field in an object
                                }
                            }
                        }).on('error', async () => {
                            await dispatch(resubscribeWithTimeout(undefined, true));
                        });
                        subscriptions.push(subscription);
                        break;
                    }
                    case UnnecessarySocketNamespaces.defiPositions: {
                        const subscription = centrifuge.subscribe(channel, ({ data: position }: { data: IDexPosition }) => {
                            const accountId = rootGetters['Accounts/activeAccountID'];
                            if (id === accountId) {
                                commit(UPDATE_POOL_POSITION(position), { root: true });
                            }
                        }).on('error', async () => {
                            await dispatch(resubscribeWithTimeout(undefined, true));
                        });
                        subscriptions.push(subscription);
                        break;
                    }
                    case UnnecessarySocketNamespaces.defi: {
                        const subscription = centrifuge.subscribe(channel, ({ data: transaction }: { data: IDexTransaction }) => {
                            const accountId = rootGetters['Accounts/activeAccountID'];
                            if (id === accountId) {
                                commit(SET_DEX_TRANSACTION(transaction), { root: true });
                                try {
                                    dispatch(getAssetsAllowance(undefined), { root: true });
                                    dispatch(checkCurrencyApprove(undefined), { root: true });
                                    dispatch(getPoolPositions(undefined), { root: true });
                                    dispatch(getLiquidityAllowance(100), { root: true });
                                } catch {
                                    // code crushed because of network error
                                }
                            }
                        }).on('error', async () => {
                            await dispatch(resubscribeWithTimeout(undefined, true));
                        });
                        subscriptions.push(subscription);
                        break;
                    }
                    case UnnecessarySocketNamespaces.futuresPositions: {
                        const subscription = centrifuge.subscribe(channel, ({ data: position }: { data: IFuturesPosition }) => {
                            dispatch(updatePosition(position), { root: true });
                            dispatch(updateTablePosition(position), { root: true });
                            dispatch(updateQuickBalancePosition(position), { root: true });
                            dispatch(updateModalPosition(position), { root: true });
                        }).on('error', async () => {
                            await dispatch(resubscribeWithTimeout(undefined, true));
                        });
                        subscriptions.push(subscription);
                        break;
                    }
                    case UnnecessarySocketNamespaces.futuresOrders: {
                        const subscription = centrifuge.subscribe(channel, ({ data: order }: { data: IFuturesOrderPresenter }) => {
                            const accountId = rootGetters['Accounts/activeAccountID'];
                            if (id === accountId) {
                                dispatch(updateOrders(order), { root: true });
                            }
                            dispatch(updateModalOrders(order), { root: true });
                        }).on('error', async () => {
                            await dispatch(resubscribeWithTimeout(undefined, true));
                        });
                        subscriptions.push(subscription);
                        break;
                    }
                    case UnnecessarySocketNamespaces.futuresTrades: {
                        const subscription = centrifuge.subscribe(channel, ({ data: trade }: { data: IFuturesTrade }) => {
                            const accountId = rootGetters['Accounts/activeAccountID'];
                            if (id === accountId) {
                                dispatch(updateTrade(trade), { root: true });
                            }
                            dispatch(updateOrdersTradesBySocket(trade), { root: true });
                        }).on('error', async () => {
                            await dispatch(resubscribeWithTimeout(undefined, true));
                        });
                        subscriptions.push(subscription);
                        break;
                    }
                    case UnnecessarySocketNamespaces.stakingPositions: {
                        const subscription = centrifuge.subscribe(channel, ({ data: position }: { data: IStakingPosition }) => {
                            dispatch(updateStakingPosition(position), { root: true });
                        }).on('error', async () => {
                            await dispatch(resubscribeWithTimeout(undefined, true));
                        });
                        subscriptions.push(subscription);
                        break;
                    }
                    case UnnecessarySocketNamespaces.orders: {
                        const subscription = centrifuge.subscribe(channel, ({ data: order }: { data: ISpotOrderPresenter }) => {
                            const accountId = rootGetters['Accounts/activeAccountID'];
                            if (id === accountId) {
                                dispatch('Orders/History/updateOrdersBySocket', order, { root: true });
                            }
                        }).on('error', async () => {
                            await dispatch(resubscribeWithTimeout(undefined, true));
                        });
                        subscriptions.push(subscription);
                        break;
                    }
                    case UnnecessarySocketNamespaces.trades: {
                        const subscription = centrifuge.subscribe(channel, ({ data: trade }: { data: ISpotTrade }) => {
                            dispatch('Orders/History/updateTradesBySocket', trade, { root: true });
                        }).on('error', async () => {
                            await dispatch(resubscribeWithTimeout(undefined, true));
                        });
                        subscriptions.push(subscription);
                        break;
                    }
                    case UnnecessarySocketNamespaces.marginBorrowings: {
                        const subscription = centrifuge.subscribe(channel, ({ data: borrowing }: { data: IBorrowing }) => {
                            dispatch('Orders/History/updateBorrowingBySocket', borrowing, { root: true });
                        }).on('error', async () => {
                            await dispatch(resubscribeWithTimeout(undefined, true));
                        });
                        subscriptions.push(subscription);
                        break;
                    }
                    case UnnecessarySocketNamespaces.totalBalances: {
                        const subscription = centrifuge.subscribe(channel, ({ data: balance }: { data: ITotalBalance }) => {
                            const accountId = rootGetters['Accounts/activeAccountID'];
                            if (id === accountId) {
                                dispatch(updateTotalBalancesBySocket(balance), { root: true });
                            }
                        }).on('error', async () => {
                            await dispatch(resubscribeWithTimeout(undefined, true));
                        });
                        subscriptions.push(subscription);
                        break;
                    }
                }
            }
        });
        commit(SET_SUBSCRIPTIONS(subscriptions, true));
    },
    async subscribeOnNecessaryChannels({ state, dispatch }, { payload: channels }: ReturnType<typeof subscribeOnActiveAccountChannels>) {
        const { centrifuge } = state;
        if (!centrifuge) {
            return;
        }

        channels.forEach(({ channel }) => {
            const [namespace] = channel.replace('$', '').split(':') as [NecessarySocketNamespaces | UnnecessarySocketNamespaces, string];
            switch (namespace) {
                case NecessarySocketNamespaces.notifications: {
                    centrifuge.subscribe(channel, ({ data: notification }: { data: string }) => {
                        dispatch(updateNotifications(notification), { root: true });
                    }).on('error', async () => {
                        await dispatch(resubscribeWithTimeout(undefined, true));
                    });
                    break;
                }
                case NecessarySocketNamespaces.balances: {
                    centrifuge.subscribe(channel, ({ data: balance }: { data: IBalance }) => {
                        dispatch(updateBalance(balance), { root: true });
                    }).on('error', async () => {
                        await dispatch(resubscribeWithTimeout(undefined, true));
                    });
                    break;
                }
                case NecessarySocketNamespaces.pnlUpdates: {
                    centrifuge.subscribe(channel, ({ data: pnl }) => {
                        dispatch('Portfolio/updateUnrealizedPNL', JSON.parse(pnl), { root: true });
                    }).on('error', async () => {
                        await dispatch(resubscribeWithTimeout(undefined, true));
                    });
                    break;
                }
            }
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};


import Vue from 'vue';
import 'chart.js/auto';
import { Bar } from 'vue-chartjs';
import 'chartjs-adapter-date-fns';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

export default Vue.extend<any, any, any, any>({
    components: { Bar },
    props: {
        chartData: {
            type: Object,
            required: true,
        },
        tooltipTitleCallback: {
            type: Function,
            default: null,
        },
        tooltipBeforeLabelCallback: {
            type: Function,
            default: null,
        },
        tooltipLabelCallback: {
            type: Function,
            default: null,
        },
        tooltipLabelColorCallback: {
            type: Function,
            default: null,
        },
        tooltipFooterCallback: {
            type: Function,
            default: null,
        },
        tooltipTextColorCallback: {
            type: Function,
            default: null,
        },
        yAxeLabelCallback: {
            type: Function,
            default: null,
        },
        minX: {
            type: Number,
            default: undefined,
        },
        maxX: {
            type: Number,
            default: undefined,
        },
    },
    computed: {
        darkTheme() {
            return this.$store.getters.isThemeDark;
        },
        fontColor() {
            return this.darkTheme ? 'rgba(255, 255, 255, 0.5)' : 'rgba(35, 36, 77, 0.5)';
        },
        gridLinesColor() {
            return this.darkTheme ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)';
        },
        tooltipCallbacks() {
            const result: any = {};

            if (this.tooltipTitleCallback) { result.title = this.tooltipTitleCallback; }
            if (this.tooltipLabelCallback) { result.label = this.tooltipLabelCallback; }
            if (this.tooltipBeforeLabelCallback) { result.beforeLabel = this.tooltipBeforeLabelCallback; }
            if (this.tooltipLabelColorCallback) { result.labelColor = this.tooltipLabelColorCallback; }
            if (this.tooltipFooterCallback) { result.footer = this.tooltipFooterCallback; }
            if (this.tooltipTextColorCallback) { result.labelTextColor = this.tooltipTextColorCallback; }

            return result;
        },
        data() {
            let { datasets } = this.chartData;
            const { labels } = this.chartData;

            datasets = datasets.map((dataset) => (dataset.datalabels
                ? dataset
                : {
                    ...dataset,
                    datalabels: {
                        labels: {
                            title: null,
                        },
                    },
                }));

            return {
                labels,
                datasets,
            };
        },
        options() {
            return {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        ticks: {
                            beginAtZero: true,
                            font: {
                                size: 13,
                                family: 'Nunito Sans, sans-serif',
                                weight: 500,
                            },
                            color: this.fontColor,
                            padding: 5,
                            callback: this.yAxeLabelCallback
                                ? this.yAxeLabelCallback
                                : (value) => value,
                        },
                        grid: {
                            drawBorder: false,
                            color: this.$store.getters.isThemeDark
                                ? 'rgba(255, 255, 255, 0.05)'
                                : 'rgba(0, 0, 0, 0.05)',
                            zeroLineColor: this.$store.getters.isThemeDark
                                ? 'rgba(255, 255, 255, 0.05)'
                                : 'rgba(0, 0, 0, 0.05)',
                            zeroLineBorderDashOffset: 1,
                        },
                    },
                    x: {
                        type: 'time',
                        time: {
                            unit: 'day',
                            stepSize: 2,
                        },
                        ticks: {
                            major: {
                                enabled: false,
                                fontSize: 11,
                                fontStyle: 'bold',
                            },
                            maxRotation: 0,
                            minRotation: 0,
                            fontSize: 10,
                            fontColor: this.fontColor,
                            lineHeight: 3,
                            fontFamily: 'Nunito Sans, sans-serif',
                            callback: this.xAxeLabelCallback ? this.xAxeLabelCallback : (value) => value,
                            autoSkip: true,
                            maxTicksLimit: 10,
                        },
                        grid: {
                            display: false,
                        },
                    },
                },
                hover: {
                    mode: 'x',
                    intersect: false,
                },
                plugins: {
                    datalabels: {
                        display: false,
                    },
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        mode: 'index',
                        intersect: false,
                        titleFontFamily: 'Nunito Sans, sans-serif',
                        bodyFontFamily: 'Nunito Sans, sans-serif',
                        titleFontSize: 13,
                        bodyFontSize: 13,
                        footerFontSize: 13,
                        callbacks: this.tooltipCallbacks,
                    },
                },
            };
        },
        chart() {
            return this.$data._chart;
        },
    },
    methods: {
        updateChart() {
            this.chart.update();
        },
        hideDataset(index) {
            if (this.chart && this.chart.data.datasets.length > index) {
                const meta = this.chart.data.datasets[index]._meta;

                Object.keys(meta).forEach((field) => {
                    meta[field].hidden = true;
                });

                this.updateChart();
            } else {
                throw 'No available dataset';
            }
        },
        showDataset(index) {
            if (this.chart && this.chart.data.datasets.length > index) {
                const meta = this.chart.data.datasets[index]._meta;

                Object.keys(meta).forEach((field) => {
                    meta[field].hidden = null;
                });

                this.updateChart();
            } else {
                throw 'No available dataset';
            }
        },
    },
});

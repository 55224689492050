
import Vue from 'vue';
import { mapGetters } from 'vuex';

import Icon from 'UI/Icon.vue';

interface Props {
    text?: string;
    hasPositionRelative?: boolean;
    isUser?: boolean;
}

interface Methods {
    reload: () => void;
}

interface Computed {
    isThemeDark: boolean;
}

type NoData = Record<string, never>;

export default Vue.extend<NoData, Methods, Computed, Props>({
    components: { Icon },
    props: {
        text: {
            type: String,
            default: 'No data to display.',
        },
        hasPositionRelative: {
            type: Boolean,
            default: false,
        },
        isUser: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            isThemeDark: 'isThemeDark',
        }),
    },
    methods: {
        reload() {
            window.location.reload();
        },
    },
});

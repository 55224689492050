import router from '@/router';
import { AccountManager } from 'Models/accounts';
import { ISSUE_MFA_ACTIONS, MFA_ENROLL_FACTOR_TYPES } from 'Config/auth';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import AccountsApi from 'Apis/Accounts';
import ShareAccountPayload, { IShareAccountPayload } from 'Entities/accountManagement/ShareAccountPayload';
import ApiError from 'Entities/ApiError';
import BaseAccountNamingPayload from 'Lib/entities/accountManagement/BaseAccountNamingPayload';
import UpdateAccountPayload from 'Lib/entities/accountManagement/UpdateAccountPayload';
import BaseAccountPayload from 'Lib/entities/accountManagement/BaseAccountPayload';
import RevokeAccountPayload from 'Lib/entities/accountManagement/RevokeAccountPayload';
import AccountRequestsRequest from 'Lib/entities/privatePresenter/AccountRequestsRequest';
import AdmitAccountRequestPayload from 'Lib/entities/accountManagement/AdmitAccountRequestPayload';
import BaseAccountRequestPayload from 'Lib/entities/accountManagement/BaseAccountRequestPayload';

const state = {
    isManagersUpdating: false,

    accountsManagers: {},

    userManagementRequests: [],
};

const getters = {
    isManagersUpdating: (state) => state.isManagersUpdating,

    accountsManagers: (state) => state.accountsManagers,
    activeAccountManagers: (state, getters, rootState, rootGetters) => getters.accountsManagers[rootGetters['Accounts/activeAccountID']],

    userManagementRequests: (state) => state.userManagementRequests,
    userManagementRequestsCount: (state, getters) => getters.userManagementRequests.length,

    incomingUserManagementRequests: (state, getters, rootState, rootGetters) => getters.userManagementRequests.filter(({ recipient }) => recipient === rootGetters['Auth/userGuid']),
    incomingUserManagementRequestsCount: (state, getters) => getters.incomingUserManagementRequests.length,

    outgoingUserManagementRequests: (state, getters, rootState, rootGetters) => getters.userManagementRequests.filter(({ issuer }) => issuer === rootGetters['Auth/userGuid']),
    activeAccountOutgoingUserManagementRequests: (state, getters, rootState, rootGetters) => getters.outgoingUserManagementRequests.filter(
        ({ accountId }) => accountId === rootGetters['Accounts/activeAccountID'],
    ),
};

const mutations = {
    SET_MANAGERS_UPDATING_STATUS(state, isManagersUpdating) {
        state.isManagersUpdating = isManagersUpdating;
    },

    SET_ACCOUNT_MANAGERS(state, { id, managers }) {
        state.accountsManagers = {
            ...state.accountsManagers,
            [id]: managers,
        };
    },
    REMOVE_ACCOUNT_MANAGER(state, { accountId, userGuid }) {
        const managerIndex = state.accountsManagers[accountId].findIndex(({ author: { id } }) => id === userGuid);

        if (~managerIndex) {
            state.accountsManagers[accountId].splice(managerIndex, 1);
        }
    },

    SET_USER_MANAGEMENT_REQUESTS(state, requests) {
        state.userManagementRequests = requests;
    },
    REMOVE_USER_MANAGEMENT_REQUEST(state, givenId) {
        const requestIndex = state.userManagementRequests.findIndex(({ id }) => id === givenId);

        if (~requestIndex) {
            state.userManagementRequests.splice(requestIndex, 1);
        }
    },
    ADD_USER_MANAGEMENT_REQUEST(state, request) {
        state.userManagementRequests.unshift(request);
    },
    UPDATE_USER_MANAGEMENT_REQUEST_GUID(state, { oldGuid, newGuid }) {
        const request = state.userManagementRequests.find(({ id }) => id === oldGuid);

        if (request) {
            request.id = newGuid;
        }
    },
    UPDATE_USER_MANAGEMENT_REQUEST_POLICIES(state, { guid: givenGuid, policies }) {
        const request = state.userManagementRequests.find(({ guid }) => guid === givenGuid);

        if (request) {
            request.policies = policies;
        }
    },
};

const actions = {
    createAccount({ dispatch }, newAccountData) {
        return new Promise<void>((resolve, reject) => {
            AccountsApi.create(new BaseAccountNamingPayload(newAccountData)).then(() => {
                /**
                 * @TODO: need to replace updating all accounts list to adding a new account without make any requests (but balance request)
                */
                dispatch('Accounts/updateAccountsList', undefined, { root: true });
                resolve();
            }).catch(reject);
        });
    },
    changeAccountName({ dispatch }, { id, name }) {
        return new Promise((resolve, reject) => {
            AccountsApi.update(new UpdateAccountPayload({
                id,
                name,
            })).then(({ data }) => {
                const { name } = data;

                dispatch('Accounts/setAccountName', { id, name }, { root: true });
                resolve(data);
            }).catch(reject);
        });
    },
    changeAccountDescription({ dispatch }, { id, description }) {
        return new Promise((resolve, reject) => {
            AccountsApi.update(new UpdateAccountPayload({
                id,
                description,
            })).then(({ data }) => {
                const { description } = data;

                dispatch('Accounts/setAccountDescription', { id, description }, { root: true });
                resolve(data);
            }).catch(reject);
        });
    },

    setAccountManagers({ commit }, { id, managers }) {
        commit('SET_ACCOUNT_MANAGERS', {
            id,
            managers: managers ? managers.map((manager) => new AccountManager(manager)) : [],
        });
    },
    removeAccountManager({ commit }, data) {
        commit('REMOVE_ACCOUNT_MANAGER', data);
    },
    updateAccountManagers({ rootGetters, dispatch, commit }, { id }) {
        const accountsIds = rootGetters['Accounts/accounts'].map(({ id }) => id);
        if (!accountsIds.includes(id)) {
            return;
        }
        return new Promise((resolve, reject) => {
            commit('SET_MANAGERS_UPDATING_STATUS', true);

            AccountsApi.getManagers(new BaseAccountPayload({
                id,
            })).then(({ data: managers }) => {
                dispatch('setAccountManagers', { id, managers });
                resolve(managers);
            }).catch(reject).finally(() => {
                commit('SET_MANAGERS_UPDATING_STATUS', false);
            });
        });
    },

    async shareAccountAccess({ rootGetters, dispatch, commit }, { recipient, policies }) {
        const startChallengePath = (router as any).history.current.path;

        const totpToken = await dispatch('Auth/getMFAToken', { action: ISSUE_MFA_ACTIONS.SHARE_ACCOUNT_ACCESS }, { root: true });
        await router.replace(startChallengePath).catch(() => { /* navigation error */ });

        const activeAccountId = rootGetters['Accounts/activeAccountID'];

        commit(SET_LOADING_ON(undefined), { root: true });
        try {
            let payload: IShareAccountPayload;
            if (recipient.indexOf('@') === -1) {
                payload = {
                    account: {
                        id: activeAccountId,
                    },
                    recipient,
                    policies,
                    totp: totpToken,
                };
            } else {
                payload = {
                    account: {
                        id: activeAccountId,
                    },
                    recipientEmail: recipient,
                    policies,
                    totp: totpToken,
                };
            }
            const { data } = await AccountsApi.share(new ShareAccountPayload(payload));
            await dispatch('addUserManagementRequest', {
                accountId: activeAccountId,
                id: data.id,
                issuer: data.issuer,
                policies,
                recipient: data.recipient,
                role: '',
                status: data.status,
                issuerData: {
                    id: rootGetters['Auth/userGuid'],
                    firstName: rootGetters['Auth/profileFirstName'],
                    lastName: rootGetters['Auth/profileLastName'],
                },
            });
            await dispatch('Notificator/showSuccessNotification', 'Access sharing request is on the way to recipient', { root: true });
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during sharing the account access', { root: true });
            }
        } finally {
            commit(SET_LOADING_OFF(undefined), { root: true });
        }
    },
    revokeAccountAccess({ rootGetters, dispatch, commit }, { guid }) {
        return new Promise<void>((resolve, reject) => {
            const activeAccountId = rootGetters['Accounts/activeAccountID'];
            dispatch('Auth/getMFAToken', { type: MFA_ENROLL_FACTOR_TYPES.TOTP }, { root: true }).then((totp) => {
                commit(SET_LOADING_ON(undefined), { root: true });
                router.push('/accounts/permissions-settings').then(() => {
                    AccountsApi.revoke(new RevokeAccountPayload({
                        account: {
                            id: activeAccountId,
                        },
                        recipient: guid,
                        totp,
                    })).then(() => {
                        dispatch('removeAccountManager', {
                            accountId: activeAccountId,
                            userGuid: guid,
                        });

                        resolve();
                    }).catch(reject).finally(() => {
                        commit(SET_LOADING_OFF(undefined), { root: true });
                    });
                }).catch(() => { /* navigation error */ });
            });
        });
    },

    setUserManagementRequests({ commit }, requests) {
        commit('SET_USER_MANAGEMENT_REQUESTS', requests);
    },
    addUserManagementRequest({ commit }, request) {
        commit('ADD_USER_MANAGEMENT_REQUEST', request);
    },
    removeUserManagementRequest({ commit }, guid) {
        commit('REMOVE_USER_MANAGEMENT_REQUEST', guid);
    },
    updateUserManagementRequestGuid({ commit }, data) {
        commit('UPDATE_USER_MANAGEMENT_REQUEST_GUID', data);
    },
    updateUserManagementRequestPolicies({ commit }, data) {
        commit('UPDATE_USER_MANAGEMENT_REQUEST_POLICIES', data);
    },
    updateUserManagementRequest({ rootGetters, dispatch }) {
        if (!rootGetters['Auth/isEmailVerified']) {
            return;
        }
        return new Promise((resolve, reject) => {
            AccountsApi.privateGetAccountRequests(new AccountRequestsRequest({
                status: 'processing',
            })).then(({ data }) => {
                if (data.length > 0) {
                    dispatch('setUserManagementRequests', data);
                    resolve(data);
                } else {
                    resolve([]);
                }
            }).catch(reject);
        });
    },

    admitAccountRequestAccess({ dispatch }, { guid, name, description }) {
        return new Promise((resolve, reject) => {
            AccountsApi.requestAdmit(new AdmitAccountRequestPayload({
                request: {
                    id: guid,
                },
                account: {
                    name,
                    description: description || '',
                },
            })).then(({ data }) => {
                dispatch('removeUserManagementRequest', guid);
                dispatch('Accounts/updateAccountsList', undefined, { root: true });
                resolve(data);
            }).catch(reject);
        });
    },
    rejectAccountRequestAccess({ dispatch }, { guid }) {
        return new Promise((resolve, reject) => {
            AccountsApi.requestReject(new BaseAccountRequestPayload({
                id: guid,
            })).then(({ data }) => {
                dispatch('removeUserManagementRequest', guid);
                resolve(data);
            }).catch(reject);
        });
    },
    cancelAccountRequestAccess({ dispatch }, { guid }) {
        return new Promise((resolve, reject) => {
            AccountsApi.requestCancel(new BaseAccountRequestPayload({
                id: guid,
            })).then(({ data }) => {
                dispatch('removeUserManagementRequest', guid);
                resolve(data);
            }).catch(reject);
        });
    },

    changeAccountAccessPermissions({ rootGetters, dispatch, commit }, {
        accountId, policies, recipientData, isRequest,
    }) {
        return new Promise((resolve, reject) => {
            const startChallengePath = (router as any).history.current.path;

            dispatch(
                'Auth/getMFAToken',
                {
                    action: ISSUE_MFA_ACTIONS.CHANGE_ACCOUNT_ACCESS_PERMISSION,
                },
                { root: true },
            ).then((totpToken) => {
                router.replace(startChallengePath).catch(() => { /* navigation error */ });

                const activeAccountId = accountId;
                commit(SET_LOADING_ON(undefined), { root: true });
                AccountsApi.share(new ShareAccountPayload({
                    account: {
                        id: accountId,
                    },
                    recipient: recipientData.id,
                    policies,
                    totp: totpToken,
                })).then(({ data }) => {
                    if (!isRequest) {
                        dispatch('removeAccountManager', {
                            accountId: activeAccountId,
                            userGuid: recipientData.id,
                        });

                        dispatch('addUserManagementRequest', {
                            accountId: activeAccountId,
                            id: data.id,
                            issuer: data.issuer,
                            policies,
                            recipient: data.recipient,
                            role: '',
                            status: data.status,
                            issuerData: {
                                id: rootGetters['Auth/userGuid'],
                                firstName: rootGetters['Auth/profileFirstName'],
                                lastName: rootGetters['Auth/profileLastName'],
                            },
                            recipientData,
                        });
                    }

                    resolve(data);
                }).catch(reject).finally(() => {
                    commit(SET_LOADING_OFF(undefined), { root: true });
                });
            });
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

import authClient from 'Plugins/authClient';
import {
    USER_GROUPS, MFA_ENROLL_FACTOR_TYPES, MFA_FACTOR_TYPES, ISSUE_MFA_ACTIONS,
} from 'Config/auth';
import ApiError from 'Entities/ApiError';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import UserApi from 'Apis/User';
import AuthenticationApi from 'Apis/Authentication';
import UserPayload from 'Entities/oktaAuthentication/UserPayload';
import SendVerificationCodePayload from 'Entities/oktaAuthentication/SendVerificationCodePayload';
import PasswordPayload from 'Entities/oktaAuthentication/PasswordPayload';
import SetPasswordPayload from 'Entities/oktaAuthentication/SetPasswordPayload';
import PrivatePasswordPayload from 'Entities/oktaAuthentication/PrivatePasswordPayload';
import BaseFactorPayload from 'Entities/oktaAuthentication/BaseFactorPayload';
import VerifyFactorPayload from 'Entities/oktaAuthentication/VerifyFactorPayload';
import IssueFactorPayload from 'Entities/oktaAuthentication/IssueFactorPayload';
import DisableMFAPayload from 'Entities/oktaAuthentication/DisableMFAPayload';
import VerifyCodePayload from 'Entities/oktaAuthentication/VerifyCodePayload';

import router from '../../router';

import History from './Auth/History';

window.authClient = authClient;

enum ERROR_CODES {
    E0000004 = 'E0000004',
    E0000068 = 'E0000068',
    E0000069 = 'E0000069',
    E0000011 = 'E0000011',
}

export function getErrorMessage(error: any) {
    let errorMessage = '';
    let needLogOut = false;
    switch (error.errorCode) {
        case ERROR_CODES.E0000004: {
            errorMessage = 'Invalid login or password';
            break;
        }
        case ERROR_CODES.E0000068: {
            errorMessage = 'Invalid confirmation code';
            break;
        }
        case ERROR_CODES.E0000069: {
            errorMessage = 'Your account has been blocked. Try again in one minute';
            break;
        }
        case ERROR_CODES.E0000011: {
            needLogOut = true;
            errorMessage = 'Your token has been expired. Try to Sign In one more time';
            break;
        }
        default: {
            errorMessage = 'Error during authorisation. Try again later';
            break;
        }
    }
    if (typeof error === 'string' && error.indexOf('LOCKED_OUT') !== -1) {
        errorMessage = 'Your account has been blocked. Try again in one minute';
    }
    return { errorMessage, needLogOut };
}

const state = {
    isInitialized: false,
    isVerificationInProgress: false,
    lastRoute: '',

    currentAuth: null,
    currentFactor: null,

    session: null,

    profile: null,
    currentEmail: null,

    accessToken: null,

    userInfo: null,

    /**
     * @type {Object} getMFATokenChallengeHandlers
     *
     * @property {String} mfaType
     * @property {String} action
     * @property {Function} resolve
     * @property {Function} reject
     */
    getMFATokenChallengeHandlers: null,

    clientInfo: null,
};

const getters = {
    isInitialized: (state) => state.isInitialized,

    currentAuth: (state) => state.currentAuth,

    currentFactor: (state) => state.currentFactor,
    isMfaFactorActive: (state, getters) => getters.currentFactor !== null,

    currentSession: (state) => state.session,
    currentSessionId: (state, getters) => (getters.currentSession ? getters.currentSession.id : null),

    profile: (state) => state.profile,
    profileEmail: (state, getters) => (getters.profile !== null ? getters.profile.email : null),
    profileName: (state, getters) => (getters.profile !== null ? getters.profile.name : null),
    profileFirstName: (state, getters) => (getters.profile !== null ? getters.profile.given_name : null),
    profileLastName: (state, getters) => (getters.profile !== null ? getters.profile.family_name : null),

    currentEmail: (state) => state.currentEmail,

    clientInfo: (state) => state.clientInfo,

    accessToken: (state) => state.accessToken,

    isLogged: (state, getters) => getters.accessToken !== null && getters.accessToken !== undefined,

    userGroups: (state, getters) => (getters.isLogged ? getters.accessToken.claims.groups : []),
    isMfaEnabled: (state, getters) => getters.userGroups.indexOf(USER_GROUPS.MFA_VERIFIED) !== -1,
    isMFADisabled: (state, getters) => !getters.isMfaEnabled,
    isEmailVerified: (state, getters) => getters.userGroups.indexOf(USER_GROUPS.EMAIL_VERIFIED) !== -1,

    userInfo: (state) => state.userInfo,
    userGuid: (state, getters) => (getters.userInfo ? getters.userInfo.id : ''),

    getMFATokenChallengeHandlers: (state) => state.getMFATokenChallengeHandlers,
    isGetMFATokenChallengeActive: (state) => !!state.getMFATokenChallengeHandlers,
    isGetMFATokenChallengeActiveMfaType: (state, getters) => getters.getMFATokenChallengeHandlers.mfaType,
    activeGetMFATokenChallengeAction: (state, getters) => getters.getMFATokenChallengeHandlers.action,
};

const mutations = {
    SET_IS_INITIALIZED(state, status) {
        state.isInitialized = status;
    },

    SET_CLIENT_INFO(state, clientInfo) {
        state.clientInfo = clientInfo;
    },

    SET_CURRENT_AUTH(state, auth) {
        state.currentAuth = auth;
    },
    SET_CURRENT_FACTOR(state, factor) {
        state.currentFactor = factor;
    },
    SET_SESSION_DATA(state, sessionData) {
        state.session = sessionData;
    },
    SET_PROFILE(state, profile) {
        state.profile = profile;
    },
    SET_IS_LOGGED(state, isLogged) {
        state.isLogged = isLogged;
    },
    SET_ACCESS_TOKEN(state, token) {
        state.accessToken = token;
    },
    ADD_GROUP(state, group) {
        state.accessToken.claims.groups.push(group);
    },
    REMOVE_GROUP(state, group) {
        state.accessToken.claims.groups.splice(state.accessToken.claims.groups.indexOf(group), 1);
    },
    SET_CURRENT_EMAIL(state, email) {
        state.currentEmail = email;
    },

    SET_USER_INFO(state, userInfo) {
        state.userInfo = userInfo;
    },

    SET_GET_MFA_TOKEN_CHALLENGE_HANDLERS(state, handlers) {
        state.getMFATokenChallengeHandlers = handlers;
    },
};

const actions = {
    setIsInitialized({ commit }, status) {
        commit('SET_IS_INITIALIZED', status);
    },
    setCurrentFactor({ commit }, factor) {
        commit('SET_CURRENT_FACTOR', factor);
    },
    setSessionData({ commit }, sessionData) {
        commit('SET_SESSION_DATA', sessionData);
    },
    setProfile({ commit }, profile) {
        commit('SET_PROFILE', profile);
    },
    async updateSessionData({ dispatch }) {
        return dispatch('setSessionData', await authClient.session.get());
    },
    async updateProfileData({ dispatch }) {
        dispatch('setProfile', await authClient.getUser());
    },
    setAccessToken({ commit }, token) {
        AUTH_TOKEN = token.accessToken;
        commit('SET_ACCESS_TOKEN', token);
    },
    removeGroup({ commit }, group) {
        commit('REMOVE_GROUP', group);
    },
    addGroup({ commit }, group) {
        commit('ADD_GROUP', group);
    },

    setTokens({ dispatch }, tokens) {
        authClient.tokenManager.setTokens(tokens);

        dispatch('setAccessToken', tokens.accessToken);
    },
    async updateTokens({ dispatch }) {
        try {
            console.log('trying to update okta tokens');
            await authClient.tokenManager.renew('accessToken');
            dispatch('setTokens', await authClient.tokenManager.getTokens());
        } catch (error) {
            console.log('failed to update okta tokens - logout');
            try {
                await dispatch('logout');
                await router.push('/signin').catch(() => { /* navigation error */ });
            } catch {
                document.location.reload();
            }
        }
    },
    setCurrentEmail({ commit }, email) {
        commit('SET_CURRENT_EMAIL', email);
    },

    async loadUser({ dispatch, commit, getters }) {
        try {
            authClient.authStateManager.subscribe(async () => {
                const isLogged = await authClient.isAuthenticated();
                commit('SET_IS_LOGGED', isLogged);
                if (isLogged) {
                    dispatch('updateSessionData');
                }
            });

            authClient.tokenManager.on('error', async () => {
                try {
                    console.log('okta token manager error');
                    await dispatch('logout');
                    await router.push('/signin').catch(() => { /* navigation error */ });
                } catch {
                    document.location.reload();
                }
            });

            authClient.tokenManager.on('expired', () => {
                console.log('okta token expired');
                dispatch('updateTokens');
            });

            const isLoggedIn = await authClient.isAuthenticated();

            if (isLoggedIn) {
                await dispatch('updateTokens');

                dispatch('updateProfileData');
                if (getters.isEmailVerified) {
                    dispatch('updateCurrentUserInfo');
                }
                dispatch('Accounts/Management/updateUserManagementRequest', undefined, { root: true });
            }
        } finally {
            dispatch('setIsInitialized', true);
        }
    },

    setCurrentUserInfo({ commit }, userInfo) {
        commit('SET_USER_INFO', userInfo);
    },
    async updateCurrentUserInfo({ dispatch }) {
        try {
            const { data } = await UserApi.getCurrentUser();
            return dispatch('setCurrentUserInfo', data);
        } catch {
            // api error
        }
    },

    async signup({ dispatch }, {
        email, password, firstName, lastName, recaptchaToken, inviteCode, accessType,
    }) {
        try {
            const { data } = await AuthenticationApi.registerUser(new UserPayload({
                email,
                password,
                firstName,
                lastName: accessType === 'Corporate' ? undefined : lastName,
                recaptchaToken,
                inviteCode,
                type: accessType,
            }));

            dispatch('setCurrentEmail', email);

            return data;
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong', { root: true });
            }
            return Promise.reject(error);
        }
    },
    async resendConfirmRegistrationCode(_, { email }) {
        try {
            const { data } = await AuthenticationApi.sendVerificationCode(new SendVerificationCodePayload({
                email,
            }));
            return data;
        } catch (err) {
            return Promise.reject(err);
        }
    },
    async confirmRegistration({ getters }, { code: passCode }) {
        try {
            return AuthenticationApi.verifyCode(new VerifyCodePayload({
                email: getters.currentEmail,
                passCode,
            }));
        } catch (err) {
            return Promise.reject(err);
        }
    },
    async login({ dispatch }, { email, password }) {
        try {
            dispatch('setCurrentEmail', email);
            const authResult = await authClient.signInWithCredentials({
                username: email,
                password,
            });

            return await dispatch('setCurrentAuth', authResult);
        } catch (err) {
            return Promise.reject(err);
        }
    },
    async confirmLogin({ getters, dispatch }, { answer }) {
        try {
            const verifier: any = {};

            if (getters.currentAuth.status === 'MFA_CHALLENGE') {
                verifier.passCode = answer;
            } else if (!~MFA_FACTOR_TYPES.TOTP.indexOf(getters.currentFactor.factorType)) {
                verifier.answer = answer;
            } else if (!~MFA_FACTOR_TYPES.EMAIL.indexOf(getters.currentFactor.factorType)) {
                verifier.passCode = answer;
            } else {
                throw `unsupported factor type ${getters.currentFactor.factorType}`;
            }

            const authResult = await getters.currentFactor.verify(verifier);
            return await dispatch('setCurrentAuth', authResult);
        } catch (err) {
            return Promise.reject(err);
        }
    },
    setCurrentAuth({ getters, commit, dispatch }, auth) {
        return new Promise<any>((resolve, reject) => {
            commit('SET_CURRENT_AUTH', auth);

            if (auth.status === 'MFA_REQUIRED') {
                dispatch('setCurrentFactor', auth.factors[0]);
                resolve({
                    isMfaChallengeFinished: false,
                });
            } else if (auth.status === 'MFA_CHALLENGE') {
                dispatch('setCurrentFactor', auth);
                resolve({
                    isMfaChallengeFinished: false,
                });
            } else if (auth.status === 'SUCCESS') {
                authClient.token.getWithoutPrompt({
                    sessionToken: auth.sessionToken,
                    prompt: 'none',
                }).then(({ tokens }) => {
                    dispatch('setTokens', tokens);
                    if (getters.isEmailVerified) {
                        dispatch('Accounts/Management/updateUserManagementRequest', undefined, { root: true });
                    }
                    dispatch('updateProfileData').then(async () => {
                        resolve({});

                        if (getters.isEmailVerified) {
                            await dispatch('downloadMainApiData', undefined, { root: true });
                        }
                    }).catch(async () => {
                        if (getters.isEmailVerified) {
                            await dispatch('downloadMainApiData', undefined, { root: true });
                        }
                    });
                    if (getters.isEmailVerified) {
                        dispatch('updateCurrentUserInfo');
                    }
                }).catch(reject);
            } else {
                reject(`unsupported auth status: ${auth.status}`);
            }
        });
    },
    async logout({ dispatch }) {
        try {
            // TODO: Из-за этого метода происходит баг. Надо разобраться для чего он нужен
            // await authClient.revokeAccessToken();
            await authClient.closeSession();

            await dispatch('setProfile', null);

            await dispatch('setAccessToken', null);

            return null;
        } catch (err) {
            return Promise.reject(err);
        }
    },
    async forgotPassword({ dispatch }, { email }) {
        try {
            await AuthenticationApi.forgotPassword(new PasswordPayload({
                email,
            }));

            dispatch('setCurrentEmail', email);

            return null;
        } catch (err) {
            return Promise.reject(err);
        }
    },
    async forgotPasswordSubmit(_, { email, code: passCode, password: newPassword }) {
        try {
            await AuthenticationApi.setPassword(new SetPasswordPayload({
                email,
                passCode,
                newPassword,
            }));

            return null;
        } catch (err) {
            return Promise.reject(err);
        }
    },
    async changePassword({ getters }, { oldPassword, newPassword }) {
        try {
            const { data } = await AuthenticationApi.changePassword(new PrivatePasswordPayload({
                oldPassword,
                newPassword,
                sessionId: getters.currentSessionId,
            }));
            return data;
        } catch (err) {
            return Promise.reject(err);
        }
    },
    async enrollMfa(_, { mfaType }) {
        try {
            const { data: { secret } } = await AuthenticationApi.enrollFactor(new BaseFactorPayload({
                mfaType,
            }));

            return secret;
        } catch (err) {
            return Promise.reject(err);
        }
    },
    async activateMfa({ getters, dispatch, commit }, { mfaType, passCode }) {
        try {
            commit(SET_LOADING_ON(undefined), { root: true });

            const { data } = await AuthenticationApi.activateFactor(new VerifyFactorPayload({
                mfaType,
                passCode,
                sessionId: getters.currentSessionId,
            }));

            await dispatch('updateTokens');

            return data;
        } catch (error) {
            return Promise.reject(error);
        } finally {
            commit(SET_LOADING_OFF(undefined), { root: true });
        }
    },
    async issueMfa({ getters, commit, dispatch }, { mfaType, action, emailTemplateData }) {
        try {
            commit(SET_LOADING_ON(undefined), { root: true });
            const { data } = await AuthenticationApi.issueFactor(new IssueFactorPayload({
                mfaType,
                action,
                sessionId: getters.currentSessionId,
                emailTemplateData,
            }));
            await dispatch('Notificator/showSuccessNotification', 'Code has been successfully sent to your email', { root: true });
            return data;
        } catch (err) {
            return Promise.reject(err);
        } finally {
            commit(SET_LOADING_OFF(undefined), { root: true });
        }
    },
    async verifyMfa({ getters }, { mfaType, passCode }) {
        try {
            const { data } = await AuthenticationApi.verifyFactor(new VerifyFactorPayload({
                mfaType,
                passCode,
                sessionId: getters.currentSessionId,
            }));
            return data;
        } catch (err) {
            return Promise.reject(err);
        }
    },
    async disableMfa({ getters }, { mfaType, passCode, emailPassCode }) {
        try {
            const { data } = await AuthenticationApi.disableFactor(new DisableMFAPayload({
                mfaType,
                passCode,
                emailPassCode,
                sessionId: getters.currentSessionId,
            }));
            return data;
        } catch (err) {
            return Promise.reject(err);
        }
    },

    async disableMfaChallenge({ dispatch, commit }, { mfaType }) {
        try {
            state.isVerificationInProgress = true;
            const { emailCode, totpCode } = await dispatch('getMFAToken', {
                type: MFA_ENROLL_FACTOR_TYPES.EMAIL_TOTP,
                action: ISSUE_MFA_ACTIONS.MFA_DISABLE,
                previousRoute: '/profile',
            });
            state.isVerificationInProgress = false;
            try {
                commit(SET_LOADING_ON(undefined), { root: true });
                await dispatch('disableMfa', {
                    mfaType,
                    passCode: totpCode,
                    emailPassCode: emailCode,
                });
                return await dispatch('updateTokens');
            } catch (error) {
                commit(SET_LOADING_OFF(undefined), { root: true });
                if (error instanceof ApiError) {
                    await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during disabling Google Auth, please try again later');
                }
                await dispatch('Notificator/showSuccessNotification', 'New code was sent to your email', { root: true });
                await dispatch('disableMfaChallenge', {
                    mfaType: MFA_ENROLL_FACTOR_TYPES.TOTP,
                    returnToStart: true,
                });
            } finally {
                commit(SET_LOADING_OFF(undefined), { root: true });
            }
        } catch (err) {
            return Promise.reject(err);
        }
    },

    getMFAToken({ getters, commit, dispatch }, { callback, type, action, previousRoute, emailTemplateData } = {} as any) {
        const mfaType = type || MFA_ENROLL_FACTOR_TYPES.TOTP;
        if (previousRoute) {
            state.lastRoute = previousRoute;
        }

        return new Promise<any>((resolve, reject) => {
            commit('SET_GET_MFA_TOKEN_CHALLENGE_HANDLERS', {
                mfaType,
                action,
                resolve: (...params) => {
                    if (callback) {
                        callback(...params);
                    }
                    resolve(...params as [any]);
                    dispatch('clearGetMFATokenChallengeHandlers');
                },
                reject: (...params) => {
                    reject(...params);
                    dispatch('clearGetMFATokenChallengeHandlers');
                },
            });

            if (mfaType === MFA_ENROLL_FACTOR_TYPES.TOTP) {
                router.replace('/mfa/totp').catch(() => { /* navigation error */ });
            } else if (mfaType === MFA_ENROLL_FACTOR_TYPES.EMAIL) {
                dispatch('issueMfa', {
                    mfaType: MFA_ENROLL_FACTOR_TYPES.EMAIL,
                    action,
                    emailTemplateData,
                }).then(() => {
                    router.replace('/mfa/email').catch(() => { /* navigation error */ });
                }).catch((error) => {
                    reject(error);
                    dispatch('clearGetMFATokenChallengeHandlers');
                });
            } else if (mfaType === MFA_ENROLL_FACTOR_TYPES.EMAIL_TOTP) {
                dispatch('issueMfa', {
                    mfaType: MFA_ENROLL_FACTOR_TYPES.EMAIL,
                    action,
                    emailTemplateData,
                }).then(() => {
                    router.replace('/mfa/email-totp').catch(() => { /* navigation error */ });
                }).catch((error) => {
                    reject(error);
                    dispatch('clearGetMFATokenChallengeHandlers');
                });
            } else if (getters.isMFADisabled) {
                reject({
                    error: 'MFADisabled',
                    message: 'MFA disabled',
                });
            } else {
                reject({
                    error: 'UNSUPPORTED_MFA_TYPE',
                    message: `Unsupported mfa type: ${mfaType}`,
                });
            }
        });
    },
    resolveGetMFATokenChallenge({ getters, commit }, token) {
        try {
            commit(SET_LOADING_ON(undefined), { root: true });
            getters.getMFATokenChallengeHandlers.resolve(token);
        } finally {
            commit(SET_LOADING_OFF(undefined), { root: true });
        }
    },
    rejectGetMFATokenChallenge({ getters }) {
        getters.getMFATokenChallengeHandlers.reject();
    },
    clearGetMFATokenChallengeHandlers({ commit }) {
        commit('SET_GET_MFA_TOKEN_CHALLENGE_HANDLERS', null);
    },

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,

    modules: {
        History,
    },
};

import SubuserAccount, { ISubuserAccount } from './SubuserAccount';

// This file was autogenerated. Please do not change.
export interface ISubuserResponse {
    accounts?: ISubuserAccount[];
    email?: string;
    id?: string;
    status?: ('InvitationSent' | 'KycRequired' | 'Active' | 'Suspended' | 'Cancelled');
}

export default class SubuserResponse {
    readonly _accounts: SubuserAccount[] | undefined;

    /** Description: Assigned accounts */
    get accounts(): SubuserAccount[] | undefined {
        return this._accounts;
    }

    readonly _email: string | undefined;

    /** Description: Email of a subuser */
    get email(): string | undefined {
        return this._email;
    }

    readonly _id: string | undefined;

    /** Description: Id of a subuser, if present */
    get id(): string | undefined {
        return this._id;
    }

    readonly _status: ('InvitationSent' | 'KycRequired' | 'Active' | 'Suspended' | 'Cancelled') | undefined;

    /** Description: InvitationStatus */
    get status(): ('InvitationSent' | 'KycRequired' | 'Active' | 'Suspended' | 'Cancelled') | undefined {
        return this._status;
    }

    constructor(props: ISubuserResponse) {
        if (props.accounts) {
            this._accounts = props.accounts.map((p) => new SubuserAccount(p));
        }
        if (typeof props.email === 'string') {
            this._email = props.email.trim();
        }
        if (typeof props.id === 'string') {
            this._id = props.id.trim();
        }
        if (props.status) {
            this._status = props.status;
        }
    }

    serialize(): ISubuserResponse {
        const data: ISubuserResponse = {
        };
        if (typeof this._accounts !== 'undefined') {
            data.accounts = this._accounts.map((p) => p.serialize());
        }
        if (typeof this._email !== 'undefined') {
            data.email = this._email;
        }
        if (typeof this._id !== 'undefined') {
            data.id = this._id;
        }
        if (typeof this._status !== 'undefined') {
            data.status = this._status;
        }
        return data;
    }

    toJSON(): ISubuserResponse {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            email: !this._email ? true : typeof this._email === 'string',
            id: !this._id ? true : typeof this._id === 'string',
            accounts: !this._accounts ? true : this._accounts.reduce<boolean>((result, p) => result && p.validate().length === 0, true),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}

// This file was autogenerated. Please do not change.
export interface IUserTransferAddressesParams {
    accountId: string;
    blockchainName?: string;
    includeTotal?: boolean;
    page?: number;
    perPage?: number;
    status?: ('PENDING_COMPLIANCE_APPROVAL' | 'PENDING_USER_APPROVAL' | 'BLOCKED' | 'APPROVED');
}

export default class UserTransferAddressesParams {
    readonly _accountId: string;

    /**
     * Example: AYE6EZWFHZ7
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _blockchainName: string | undefined;

    /**
     * Example: Stellar
     */
    get blockchainName(): string | undefined {
        return this._blockchainName;
    }

    readonly _includeTotal: boolean | undefined;

    /**
     * Example: true
     */
    get includeTotal(): boolean | undefined {
        return this._includeTotal;
    }

    readonly _page: number | undefined;

    /**
     * Example: 1
     */
    get page(): number | undefined {
        return this._page;
    }

    readonly _perPage: number | undefined;

    /**
     * Example: 30
     */
    get perPage(): number | undefined {
        return this._perPage;
    }

    readonly _status: ('PENDING_COMPLIANCE_APPROVAL' | 'PENDING_USER_APPROVAL' | 'BLOCKED' | 'APPROVED') | undefined;

    /**
     * Example: PENDING_COMPLIANCE_APPROVAL
     */
    get status(): ('PENDING_COMPLIANCE_APPROVAL' | 'PENDING_USER_APPROVAL' | 'BLOCKED' | 'APPROVED') | undefined {
        return this._status;
    }

    constructor(props: IUserTransferAddressesParams) {
        this._accountId = props.accountId.trim();
        if (typeof props.blockchainName === 'string') {
            this._blockchainName = props.blockchainName.trim();
        }
        if (typeof props.includeTotal === 'boolean') {
            this._includeTotal = props.includeTotal;
        }
        if (typeof props.page === 'number') {
            this._page = props.page;
        }
        if (typeof props.perPage === 'number') {
            this._perPage = props.perPage;
        }
        if (props.status) {
            this._status = props.status;
        }
    }

    serialize(): IUserTransferAddressesParams {
        const data: IUserTransferAddressesParams = {
            accountId: this._accountId,
        };
        if (typeof this._blockchainName !== 'undefined') {
            data.blockchainName = this._blockchainName;
        }
        if (typeof this._includeTotal !== 'undefined') {
            data.includeTotal = this._includeTotal;
        }
        if (typeof this._page !== 'undefined') {
            data.page = this._page;
        }
        if (typeof this._perPage !== 'undefined') {
            data.perPage = this._perPage;
        }
        if (typeof this._status !== 'undefined') {
            data.status = this._status;
        }
        return data;
    }

    toJSON(): IUserTransferAddressesParams {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            blockchainName: !this._blockchainName ? true : typeof this._blockchainName === 'string',
            includeTotal: !this._includeTotal ? true : typeof this._includeTotal === 'boolean',
            page: !this._page ? true : typeof this._page === 'number',
            perPage: !this._perPage ? true : typeof this._perPage === 'number',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
